<template>
    <div class="home-main" :style="{height: screen_innerHeight + 'px'}">
        <WebNavbar />
        <!-- Desktop -->
        <template v-if="platform == 'desktop'">
            <main class="row m-0 h-100">
                <div class="col p-0 pw-20">
                    <div class="col-wrap">
                        <div class="row">
                            <div class="col-4 sports-menu pr-0">
                                <div class="scroll-area">
                                    <div class="d-flex flex-column align-items-center p-2">
                                        <div v-for="(item, index) of sports" :key="index" class="display-5 rounded-circle" :class="{'active': sport == item.id || (item.id == 'live' && live)}">
                                            <a href="javascript:;" :class="{'disabled': sport == item.id || (item.id == 'live' && live)}" :title="item.name" @click="changeSport(item.id)">
                                                <i :class="item.icon"></i>
                                            </a>
                                            <span class="badge" v-show="countEvents && !loading">
                                                {{ countEvents }}
                                            </span>
                                        </div><!-- /display-5 -->
                                    </div><!-- /d-flex -->
                                </div><!-- /scroll-area -->
                            </div><!-- /col-auto -->
                            <div class="col-8 no-border pl-2">
                                <div class="scroll-area pr-2" :class="{'overflow-hidden': loading || nothing_found}">
                                    <template v-if="loading && !problem">
                                        <div class="d-flex justify-content-center align-items-center h-100">
                                           <div v-html="lds_ring"></div>
                                        </div>
                                    </template>
                                    <template v-else-if="problem && !nothing_found">
                                        <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                            <div class="display-5">
                                                <i class="fas fa-exclamation-circle"></i>
                                            </div>
                                            <small style="max-width: 80%;">{{ message }}</small>
                                        </div>
                                    </template>
                                    <template v-else-if="nothing_found && !problem">
                                        <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                            <div class="display-5">
                                                <i class="fas fa-exclamation-circle"></i>
                                           </div>
                                           <small>Sem eventos</small>
                                        </div>
                                    </template>
                                    <template v-else-if="nothing_found && problem && message">
                                        <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                            <div class="display-5">
                                                <i class="fas fa-exclamation-circle"></i>
                                           </div>
                                           <small>{{ message }}</small>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <template v-if="favorites_leagues_exists">
                                            <h5 class="pt-2 pb-2 mb-0">Destaques</h5>
                                            <div class="list-itens">
                                                <a class="l-item d-block" href="javascript:;" v-for="(item, index) in favorites_leagues" :key="index" @click="search = item.league_full">
                                                    <div class="row justify-content-center align-items-center">
                                                        <div class="col-auto">
                                                            <div class="flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                        </div>
                                                        <div class="col pl-0 text-truncate">
                                                            <div class="d-flex justify-content-between">
                                                                <div class="text-truncate" style="max-width: 80%;">{{ item.league }}</div>
                                                                <div class="text-number">{{ item.total }}</div>
                                                            </div>
                                                        </div>
                                                    </div><!-- /row -->
                                                </a><!-- /l-item -->
                                            </div><!-- /list-itens -->
                                        </template>
                                        <template v-if="configs.popup_items.length">
                                           <!-- Banner lateral -->
                                            <div :class="{'mt-2': !favorites_leagues_exists}">
                                                <div class="w-100 h-100" v-for="(item, index) in configs.popup_items" :key="index">
                                                    <a :href="item.link ? item.link : 'javascript:;'">
                                                        <img class="img-fluid rounded" :src="isLocalHost ? `https://demo.betsnow.net/${item.image}` : item.image" alt="Imagem">
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                        <h5 class="pt-2 pb-2 mb-0">Campeonatos</h5>
                                        <div class="list-itens">
                                            <a class="l-item d-block" href="javascript:;" v-for="(item, index) in groupChampionships" :key="index" @click="search = item.country">
                                                <div class="row justify-content-center align-items-center">
                                                    <div class="col-auto">
                                                        <div class="flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                    </div>
                                                    <div class="col pl-0 text-truncate">
                                                        <div class="d-flex justify-content-between">
                                                            <div class="text-truncate" style="max-width: 80%;">{{ item.country }}</div>
                                                            <div class="text-number">{{ item.total }}</div>
                                                        </div>
                                                    </div>
                                                </div><!-- /row -->
                                            </a><!-- /l-item -->
                                        </div><!-- /list-itens -->
                                    </template>
                                </div><!-- /scroll-area -->
                            </div><!-- /col -->
                        </div><!-- /row -->
                    </div><!-- /col-wrap -->
                </div><!-- /col-left -->
                <div class="col-7 p-0 pw-60">
                    <div class="col-wrap" :class="{'overflow-hidden': loading || nothing_found}">
                        <template v-if="loading && !problem">
                            <div class="d-flex justify-content-center align-items-center h-100 pb-28">
                                <div v-html="lds_ring"></div>
                            </div>
                        </template>
                        <template v-else-if="problem && !nothing_found">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100 pb-28">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>{{ message }}</small>
                            </div>
                        </template>
                        <template v-else-if="nothing_found && !problem">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100 pb-28">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>Sem eventos</small>
                            </div>
                        </template>
                        <template v-else-if="nothing_found && problem && message">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100 pb-28">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>{{ message }}</small>
                                <div class="mt-2" v-if="!live">
                                    <button class="btn btn-sm bg-button text-style" role="button" @click="nextDay" :class="{'min-width-200px': platform == 'desktop'}">
                                        <i class="fas fa-calendar-day mr-2"></i>Próximo dia
                                    </button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div id="events-area" class="scroll-area p-2">
                                <div class="row">
                                    <div class="col-8 pr-2" :aria-effect="{'d-none': !favorites_leagues_exists && !hasHighlights}">
                                        <template v-if="configs.slider_exibir == 1 && configs.slider.length > 0">
                                            <div class="w-100">
                                                <VueSlickCarousel v-bind='{"dots": true, "arrows": true, "speed": 500, "focusOnSelect": true, "infinite": true, "autoplay": true, "centerMode": false, "lazyLoad": "ondemand"}'>
                                                    <div class="image-content rounded" v-for="(item, index) in configs.slider" :key="index" @click="getToLink(index)">
                                                        <img class="img-fluid" :src="isLocalHost ? `https://demo.betsnow.net/${item.image}` : item.image" :alt="index" border="0">
                                                    </div>
                                                </VueSlickCarousel>
                                            </div>
                                        </template>
                                    </div><!-- /col-8 -->
                                    <template v-if="hasHighlights">
                                        <div class="col-4 pl-0">
                                            <div class="top-betting rounded">
                                                <VueSlickCarousel v-bind='{"dots": true, "arrows": false, "speed": 500, "infinite": true, "autoplay": true}'>
                                                    <div class="w-100" v-for="(item, index) in highlights" :key="index">
                                                        <div class="card">
                                                            <div class="card-header">
                                                                <div class="row m-0">
                                                                    <div class="col-8 pl-0 pr-2 text-truncate">
                                                                        <div class="mr-2 flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                                        <span class="text-style text-truncate w-100">
                                                                            {{ item.liga | getLeague }}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-4 p-0 text-right">
                                                                        <i class="fa fa-clock mr-1 align-middle"></i>
                                                                        <span class="text-style">
                                                                            {{ item.horario | formatHour }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div><!-- /card-header -->
                                                            <div class="card-body mt-2">
                                                                <div class="card-text">
                                                                    <div class="row align-items-center justify-content-center">
                                                                        <div class="col text-truncate text-center">
                                                                            <div class="d-flex flex-column align-items-center justify-content-center">
                                                                                <div>
                                                                                    <img class="align-middle object-fit" v-lazy="`${image_server}/s/${imageShield(item.time_casa)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="48" height="48" border="0">
                                                                                </div>
                                                                                <div class="text-truncate w-100">{{ item.time_casa }}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-auto text-center">
                                                                            <img src="/images/versus_symbol.png">
                                                                        </div>
                                                                        <div class="col text-truncate text-center">
                                                                            <div class="d-flex flex-column align-items-center justify-content-center">
                                                                                <div>
                                                                                    <img class="align-middle object-fit" v-lazy="`${image_server}/s/${imageShield(item.time_fora)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="48" height="48" border="0">
                                                                                </div>
                                                                                <div class="text-truncate w-100">{{ item.time_fora }}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div><!-- /row -->
                                                                    <div class="row mt-3">
                                                                        <div class="col">
                                                                            <div class="bg-button-fore p-1 text-center rounded" @click="selectEvent(item.id, 1, false, false, false, false, false, false, item.odds.casa)" :class="{'active': selectedEvents(item.id, 1, false, false, false, false, false), 'disabled': live}" role="button">{{ item.odds.casa }}</div>
                                                                        </div>
                                                                        <div class="col pl-0 pr-0">
                                                                            <div class="bg-button-fore p-1 text-center rounded" @click="selectEvent(item.id, 2, false, false, false, false, false, false, item.odds.empate)" :class="{'active': selectedEvents(item.id, 2, false, false, false, false, false), 'disabled': live}" role="button">{{ item.odds.empate }}</div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <div class="bg-button-fore p-1 text-center rounded" @click="selectEvent(item.id, 3, false, false, false, false, false, false, item.odds.visitante)" :class="{'active': selectedEvents(item.id, 3, false, false, false, false, false), 'disabled': live}" role="button">{{ item.odds.visitante }}</div>
                                                                        </div>
                                                                    </div><!-- /row -->
                                                                </div><!-- /card-text -->
                                                            </div><!-- /card-body -->
                                                        </div><!-- /card -->
                                                    </div>
                                                </VueSlickCarousel>
                                            </div><!-- /top-betting -->
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="col-4 pl-0">
                                            <div class="top-betting rounded">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="d-flex h-100 flex-column align-items-center justify-content-center">
                                                            <div class="display-4">
                                                                <i class="fas fa-star-exclamation"></i>
                                                            </div>
                                                            <span>Sem eventos em destaque</span>
                                                        </div><!-- /d-flex -->
                                                    </div><!-- /card-body -->
                                                </div><!-- /card -->
                                            </div><!-- /top-betting -->
                                        </div>
                                    </template>
                                </div><!-- /row -->
                                <div class="row" :class="{'mt-2': favorites_leagues_exists && hasHighlights}">
                                    <div class="col-8 pr-2 no-border">
                                        <div class="p-1 p-bg rounded">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">
                                                        <template v-if="search">
                                                            <i class="fas fa-broom text-danger" role="button" @click="search = ''"></i>
                                                        </template>
                                                        <template v-else>
                                                            <i class="fas fa-search"></i>
                                                        </template>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control form-sm form-bg" v-model="search" autocomplete="off" spellcheck="false" placeholder="Pesquisar time, liga ou país..." aria-label="Pesquisar time, liga ou país..." aria-describedby="basic-addon1">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 pl-0">
                                        <div class="p-1 p-bg rounded">
                                            <div class="btn-group w-100" role="group" aria-label="Basic example">
                                                <button type="button" class="btn text-uppercase" v-for="(item, index) in configs.calendario.slice(0, 3)" :key="index" @click="date = item.date" :class="{'active': index == 0, 'active': date == item.date}">
                                                    {{ item.name.substring(0, 3) }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- /row -->
                                <template v-if="filteredListEvents.length">
                                    <collection-cluster class="events-main mt-2" v-bind="collection.desktop" :items="filteredListEvents" @scrollToTop="onScrollToTop" @scrollToBottom="onScrollToBottom" :style="{height: screen_innerHeight + 'px'}">
                                        <div class="event-item" slot="item" slot-scope="{ cell, item }" :key="cell.index">
                                            <template v-if="cell.index == 0">
                                                <div class="row no-pad">
                                                    <div class="col-12">
                                                        <div class="events-main-league rounded mb-2">
                                                            <div class="row no-pad align-items-center">
                                                                <div class="text-truncate" :class="item.ao_vivo ? 'col-12' : 'col-8'">
                                                                    <div class="mr-2 flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                                    <span class="text-uppercase text-truncate">
                                                                        {{ item.liga | changeLeagueName }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-4 text-right" :class="{'text-truncate': item.esporte != 1}" v-if="!item.ao_vivo">
                                                                    <template v-if="item.esporte == 1">
                                                                        <div class="dropdown">
                                                                            <button class="btn btn-sm btn-block dropdown-toggle" type="button" :id="`dropdownMenuButton-${cell.index}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                {{ item.mercado_id == 'principais' ? 'Principais' : item.mercado_id == 'ambas_equipes_marcam' ? 'Ambas marcam' : item.mercado_id == 'total_de_gols' ? 'Gols Acima/Abaixo' : 'Dupla possibilidade' }}
                                                                            </button>
                                                                            <div class="dropdown-menu" :aria-labelledby="`dropdownMenuButton-${cell.index}`">
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('principais', item.liga_id)" :class="{'active disabled': item.mercado_id == 'principais'}">Principais</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('ambas_equipes_marcam', item.liga_id)" :class="{'active disabled': item.mercado_id == 'ambas_equipes_marcam'}">Ambas marcam</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('total_de_gols', item.liga_id)" :class="{'active disabled': item.mercado_id == 'total_de_gols'}">Gols Acima/Abaixo</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('dupla_possibilidade', item.liga_id)" :class="{'active disabled': item.mercado_id == 'dupla_possibilidade'}">Dupla possibilidade</a>
                                                                            </div>
                                                                        </div><!-- /dropdown-->
                                                                    </template>
                                                                    <template v-else>
                                                                        <span class="text-uppercase text-truncate">{{ item.horario | formatDateDay }}</span>
                                                                    </template>
                                                                </div><!-- /col-4 -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else-if="item['liga_id'] != filteredListEvents[cell.index - 1]['liga_id']">
                                                <div class="row no-pad">
                                                    <div class="col-12">
                                                        <div class="events-main-league rounded mb-2">
                                                            <div class="row no-pad align-items-center">
                                                                <div class="text-truncate" :class="item.ao_vivo ? 'col-12' : 'col-8'">
                                                                    <div class="mr-2 flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                                    <span class="text-uppercase text-truncate">
                                                                        {{ item.liga | changeLeagueName}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-4 text-right" :class="{'text-truncate': item.esporte != 1}" v-if="!item.ao_vivo">
                                                                    <template v-if="item.esporte == 1">
                                                                        <div class="dropdown">
                                                                            <button class="btn btn-sm btn-block dropdown-toggle" type="button" :id="`dropdownMenuButton-${cell.index}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                {{ item.mercado_id == 'principais' ? 'Principais' : item.mercado_id == 'ambas_equipes_marcam' ? 'Ambas marcam' : item.mercado_id == 'total_de_gols' ? 'Gols Acima/Abaixo' : 'Dupla possibilidade' }}
                                                                            </button>
                                                                            <div class="dropdown-menu" :aria-labelledby="`dropdownMenuButton-${cell.index}`">
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('principais', item.liga_id)" :class="{'active disabled': item.mercado_id == 'principais'}">Principais</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('ambas_equipes_marcam', item.liga_id)" :class="{'active disabled': item.mercado_id == 'ambas_equipes_marcam'}">Ambas marcam</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('total_de_gols', item.liga_id)" :class="{'active disabled': item.mercado_id == 'total_de_gols'}">Gols Acima/Abaixo</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('dupla_possibilidade', item.liga_id)" :class="{'active disabled': item.mercado_id == 'dupla_possibilidade'}">Dupla possibilidade</a>
                                                                            </div>
                                                                        </div><!-- /dropdown-->
                                                                    </template>
                                                                    <template v-else>
                                                                        <span class="text-uppercase text-truncate">{{ item.horario | formatDateDay }}</span>
                                                                    </template>
                                                                </div><!-- /col-4 -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row no-pad align-items-center">
                                                        <div class="col-auto pr-2">
                                                            <div class="d-flex flex-column" :class="{'text-center': item.ao_vivo}">
                                                                <template v-if="item.ao_vivo">
                                                                    <span class="event-time">
                                                                        <span class="badge badge-danger live-blink">Live</span>
                                                                    </span>
                                                                    <span class="event-time">
                                                                        <template v-if="item.periodo == '1st Half' || item.periodo == '2nd Half'">
                                                                            {{ item.hora }}
                                                                        </template> 
                                                                        <template v-else>INT.</template>   
                                                                    </span>
                                                                </template>
                                                                <template v-else>
                                                                    <span class="event-time">{{ item.horario | formatDateDDMM }}</span>
                                                                    <span class="event-time" :class="{'diff': item.tempo_status == 'diff', 'diff-blink': item.tempo_status == 'blink'}">{{ item.hora }}</span>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div class="col pl-0 pr-2 text-truncate no-border">
                                                            <div class="d-flex flex-column">
                                                                <span class="event-team text-truncate">
                                                                    <img class="mr-2 align-middle object-fit" v-lazy="`${image_server}/s/${imageShield(item.time_casa)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="20" height="20" border="0" v-if="item.esporte == 1">
                                                                    <template v-if="item.ao_vivo">
                                                                        <span class="text-truncate ml-1">{{ item.time_casa }}</span>
                                                                        <span class="badge ml-2 float-right mt-1">
                                                                            {{ item.placar.split('-')[0] }}
                                                                        </span>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ item.time_casa }}
                                                                    </template>
                                                                </span>
                                                                <span class="event-team text-truncate">
                                                                    <img class="mr-2 align-middle object-fit" v-lazy="`${image_server}/s/${imageShield(item.time_fora)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="20" height="20" border="0" v-if="item.esporte == 1">
                                                                    <template v-if="item.ao_vivo">
                                                                        <span class="text-truncate ml-1">{{ item.time_fora }}</span>
                                                                        <span class="badge ml-2 float-right mt-1">
                                                                            {{ item.placar.split('-')[1] }}
                                                                        </span>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ item.time_fora }}
                                                                    </template>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col pl-0 pr-0">
                                                            <div class="btn-group w-100" role="group" aria-label="Basic example">
                                                                <template v-if="item.mercado_id == 'principais'">
                                                                    <button type="button" class="btn text-uppercase" @click="selectEvent(item.id, 1, item.ao_vivo, false, false, false, false, false, item.odds.casa)" :class="{'active': selectedEvents(item.id, 1, false, false, false, false, false)}" :ref="`${item.id}:1`" :disabled="item.odds.casa <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>1</div>
                                                                            <div class="odd">
                                                                                <template v-if="item.odds.casa <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                                                    <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{item.odds.casa}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <button type="button" class="btn text-uppercase" @click="selectEvent(item.id, 2, item.ao_vivo, false, false, false, false, false, item.odds.empate)" :class="{'active': selectedEvents(item.id, 2, false, false, false, false, false)}" :ref="`${item.id}:2`" :disabled="item.odds.empate <= 1 || item.hora >= configs.ao_vivo_tempo" v-if="item.esporte == 1">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>
                                                                                <i class="fal fa-times"></i>
                                                                            </div>
                                                                            <div class="odd">
                                                                                <template v-if="item.odds.empate <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                                                    <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{item.odds.empate}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <button type="button" class="btn text-uppercase" @click="selectEvent(item.id, 3, item.ao_vivo, false, false, false, false, false, item.odds.visitante)" :class="{'active': selectedEvents(item.id, 3, false, false, false, false, false)}" :ref="`${item.id}:3`" :disabled="item.odds.visitante <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>2</div>
                                                                            <div class="odd" :class="{'pr-75rem': item.esporte != 1 && item.esporte != 2}">
                                                                                <template v-if="item.odds.visitante <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                                                    <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{item.odds.visitante}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </template>
                                                                <template v-else-if="item.mercado_id == 'ambas_equipes_marcam'">
                                                                    <button class="btn text-uppercase" @click="selectEvent(item.id, 11, item.ao_vivo, false, false, false, false, false, item.odds.extra.ambas_equipes_marcam.sim)" :class="{'active': selectedEvents(item.id, 11, false, false, false, false, false)}" :disabled="item.odds.extra.ambas_equipes_marcam.sim <= 1">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>SIM</div>
                                                                            <div>
                                                                                <template v-if="item.odds.extra.ambas_equipes_marcam.sim <= 1">
                                                                                        <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                        {{item.odds.extra.ambas_equipes_marcam.sim}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <button class="btn text-uppercase" @click="selectEvent(item.id, 12, item.ao_vivo, false, false, false, false, false, item.odds.extra.ambas_equipes_marcam.nao)" :class="{'active': selectedEvents(item.id, 12, false, false, false, false, false)}" :disabled="item.odds.extra.ambas_equipes_marcam.nao <= 1">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>NÃO</div>
                                                                            <div>
                                                                                <template v-if="item.odds.extra.ambas_equipes_marcam.nao <= 1">
                                                                                    <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{item.odds.extra.ambas_equipes_marcam.nao}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </template>
                                                                <template v-else-if="item.mercado_id == 'total_de_gols'">
                                                                    <button class="btn text-uppercase" @click="selectEvent(item.id, 15, item.ao_vivo, false, false, false, false, false, item.odds.extra.total_de_gols.acima_2_5)" :class="{'active': selectedEvents(item.id, 15, false, false, false, false, false)}" :disabled="item.odds.extra.total_de_gols.acima_2_5 <= 1">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>+2.5</div>
                                                                            <div>
                                                                                <template v-if="item.odds.extra.total_de_gols.acima_2_5 <= 1">
                                                                                        <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                        {{item.odds.extra.total_de_gols.acima_2_5}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <button class="btn text-uppercase" @click="selectEvent(item.id, 184, item.ao_vivo, false, false, false, false, false, item.odds.extra.total_de_gols.abaixo_2_5)" :class="{'active': selectedEvents(item.id, 184, false, false, false, false, false)}" :disabled="item.odds.extra.total_de_gols.abaixo_2_5 <= 1">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>-2.5</div>
                                                                            <div>
                                                                                <template v-if="item.odds.extra.total_de_gols.abaixo_2_5 <= 1">
                                                                                    <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{item.odds.extra.total_de_gols.abaixo_2_5}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </template>
                                                                <template v-else>
                                                                    <button class="btn text-uppercase" @click="selectEvent(item.id, 4, item.ao_vivo, false, false, false, false, false, item.odds.extra.dupla_possibilidade.casa_ou_empate)" :class="{'active': selectedEvents(item.id, 4, false, false, false, false, false)}" :disabled="item.odds.extra.dupla_possibilidade.casa_ou_empate <= 1">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>1X</div>
                                                                            <div>
                                                                                <template v-if="item.odds.extra.dupla_possibilidade.casa_ou_empate <= 1">
                                                                                        <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                        {{item.odds.extra.dupla_possibilidade.casa_ou_empate}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <button class="btn text-uppercase" @click="selectEvent(item.id, 6, item.ao_vivo, false, false, false, false, false, item.odds.extra.dupla_possibilidade.casa_ou_fora)" :class="{'active': selectedEvents(item.id, 6, false, false, false, false, false)}" :disabled="item.odds.extra.dupla_possibilidade.casa_ou_fora <= 1">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>1 ou 2</div>
                                                                            <div>
                                                                                <template v-if="item.odds.extra.dupla_possibilidade.casa_ou_fora <= 1">
                                                                                    <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{item.odds.extra.dupla_possibilidade.casa_ou_fora}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <button class="btn text-uppercase" @click="selectEvent(item.id, 5, item.ao_vivo, false, false, false, false, false, item.odds.extra.dupla_possibilidade.fora_ou_empate)" :class="{'active': selectedEvents(item.id, 5, false, false, false, false, false)}" :disabled="item.odds.extra.dupla_possibilidade.fora_ou_empate <= 1">
                                                                        <div class="d-flex justify-content-between">
                                                                            <div>2X</div>
                                                                            <div>
                                                                                <template v-if="item.odds.extra.dupla_possibilidade.fora_ou_empate <= 1">
                                                                                    <i class="fas fa-lock"></i>
                                                                                </template>
                                                                                <template v-else>
                                                                                    {{item.odds.extra.dupla_possibilidade.fora_ou_empate}}
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto pl-2" v-if="item.esporte == 1 || item.esporte == 2">
                                                            <template v-if="item.ao_vivo">
                                                                <button class="btn btn-sm btn-outline" @click="moreOddsLive(item.id)" :class="{'active': selectedEventsMore(item.id)}" :disabled="item.hora >= configs.ao_vivo_tempo || item.odds.casa <= 1 && item.odds.empate <= 1 && item.odds.visitante <= 1">
                                                                    <div class="d-flex align-items-center" :class="item.hora >= configs.ao_vivo_tempo ? 'justify-content-center' : 'justify-content-between'">
                                                                        <template v-if="item.hora >= configs.ao_vivo_tempo">
                                                                            <i class="fas fa-lock"></i>
                                                                        </template>
                                                                        <template v-else>
                                                                            <i class="fal fa-plus"></i>
                                                                            <span>
                                                                                {{ item.total_odds }}
                                                                            </span>
                                                                        </template>
                                                                    </div>
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                <button class="btn btn-sm btn-outline" @click="moreOdds(item.id)" :class="{'active': selectedEventsMore(item.id)}">
                                                                    <div class="d-flex justify-content-between align-items-center">
                                                                        <i class="fal fa-plus"></i>
                                                                        <span>
                                                                            {{ item.total_odds }}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div><!-- /col-12 -->
                                            </div><!-- /row -->
                                        </div><!-- /event-item -->
                                    </collection-cluster>
                                </template>
                                <template v-else>
                                    <div class="d-flex flex-column justify-content-center align-items-center h-100 mt-3">
                                        <div class="display-5">
                                            <i class="fas fa-exclamation-circle"></i>
                                        </div>
                                        <template v-if="search">
                                            <small>Não encontramos nenhum resultado para <b>{{ search }}</b>.</small>
                                        </template>
                                        <template v-else>
                                            <small>Nenhum evento encontrado.</small>
                                        </template>
                                    </div>
                                </template>
                                <div class="footer rounded p-3" :class="{'d-none': search, 'p-absolute': nothing_found}">
                                    <div class="container">
                                        <div class="row align-items-center">
                                            <div class="col text-center">
                                                <a href="https://www.gamblingtherapy.org/pt-br/" target="_blank">
                                                    <img src="/images/GT_v2.svg" border="0" alt="GT">
                                                </a>
                                            </div>
                                            <div class="col-8 text-center">
                                                <img src="/images/logo.png" border="0" height="26" alt="logo">
                                            </div>
                                            <div class="col text-center">
                                                <div class="d-flex flex-row justify-content-end">
                                                    <img class="mr-3" src="/images/18.svg" border="0" alt="+18">
                                                    <span class="display-5 ml-3 arrow-top" @click="toTop" title="Subir" role="button">
                                                        <i class="fas fa-arrow-circle-up"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="w-100"></div>
                                            <div class="col-12 text-center">
                                                <div class="d-flex flex-row align-items-center justify-content-center">
                                                    <div>
                                                        <a href="/#rules">Regulamento</a>
                                                    </div>
                                                    <div class="ml-2 mr-2">&bull;</div>
                                                    <div>
                                                        <a href="/#betting-rules">Regras de aposta</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-100 mb-2 mt-2">
                                                <div class="d-flex flex-row align-items-center justify-content-center">
                                                    <div class="mr-3">Pague com</div>
                                                    <div>
                                                        <img src="/images/pix-bc-logo-4.png" height="24" border="0" alt="Pix">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center" v-html="footer"></div>
                                            <div class="w-100 mb-2"></div>
                                            <div class="col-12 text-center">
                                                <div class="d-flex flex-row align-items-center justify-content-center">
                                                    <div class="social-icons" role="button" @click="getService('whatsapp')">
                                                        <i class="fab fa-whatsapp"></i>
                                                    </div>
                                                    <div class="social-icons ml-3 mr-3" role="button" @click="getService('telegram')">
                                                        <i class="fab fa-telegram"></i>
                                                    </div>
                                                    <div class="social-icons mr-3" role="button" @click="getService('instagram')">
                                                        <i class="fab fa-instagram"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-100 mb-2 text-center"></div>
                                            <div class="col-12 text-center">
                                                Version {{ app_version }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- /scroll-area -->
                        </template>
                    </div><!-- /col-wrap -->
                </div><!-- /col-center -->
                <div class="col p-0 pw-20">
                    <div class="col-wrap">
                        <div class="scroll-area scroll-area-ticket" @scroll="scrollAreaTicket">
                            <div class="p-2 h-100">
                                <template v-if="selectedEventsCount() == 0">
                                    <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                        <div class="display-5">
                                            <i class="fas fa-exclamation-circle"></i>
                                        </div>
                                        <small>Bilhete vázio</small>
                                    </div>
                                </template>
                                <template v-else>
                                    <h5 class="pb-2 mb-0 text-center sticky" :class="{'pt-2': sticky}">
                                        <span>Bilhete</span>
                                        <span class="text-theme ml-2">{{ selectedEventsCount() }}</span>
                                    </h5>
                                    <div class="list-itens h-100">
                                        <template v-if="betbuilderItemsListCount">
                                            <div class="l-item d-block" v-for="(item, key, index) in betbuilderItemsList" :key="index">
                                                <div class="row">
                                                    <div class="col text-truncate small l-item-emphasis">
                                                        {{ item.league | changeLeagueName }}
                                                    </div><!-- /col -->
                                                    <div class="col-auto text-right small">
                                                        <i class="far fa-clock mr-2"></i>{{ item.schedule | formatDateDDMMHHMM }}
                                                    </div>
                                                    <div class="w-100"></div>
                                                    <div class="col text-truncate">
                                                        {{ item.team_home }}
                                                    </div>
                                                    <div class="col-auto text-right text-truncate">
                                                        <div class="text-right text-theme" role="button" @click="removeEvent(item.key)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </div>
                                                    </div>
                                                    <div class="w-100"></div>
                                                    <div class="col text-truncate">
                                                        {{ item.team_away}}
                                                    </div>
                                                </div>
                                                <div class="row" v-for="(palpite, i) in item.items" :key="i">
                                                    <div class="col text-theme small">{{ palpite.market }}</div>
                                                    <div class="col-auto small p-0" v-if="palpite.live">
                                                        <span class="badge live-blink">Live</span>
                                                    </div>
                                                    <div class="col-auto text-right text-truncate text-theme small">{{ palpite.odd }}</div>
                                                </div><!-- /row -->
                                            </div><!-- /l-item -->
                                        </template>
                                        <template v-if="hunchesListCount">
                                            <div class="l-item d-block" v-for="(item, key, index) in hunchesList" :key="index">
                                                <div class="row">
                                                    <div class="col text-truncate small l-item-emphasis">
                                                        {{ item.league | changeLeagueName }}
                                                    </div><!-- /col -->
                                                    <div class="col-auto text-right small">
                                                        <i class="far fa-clock mr-2"></i>{{ item.schedule | formatDateDDMMHHMM }}
                                                    </div>
                                                    <div class="w-100"></div>
                                                    <div class="col text-truncate">
                                                        {{ item.team_home }}
                                                    </div>
                                                    <div class="col-auto text-right text-truncate">
                                                        <div class="text-right text-theme" role="button" @click="removeEvent(item.id, key)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </div>
                                                    </div>
                                                    <div class="w-100"></div>
                                                    <div class="col text-truncate">
                                                        {{ item.team_away}}
                                                    </div>
                                                    <div class="col-auto text-right text-truncate">
                                                        <div class="text-right" role="button" @click="moreOdds(item.id)" :class="{'disabled text-muted': item.sport != '1'}">
                                                            <i class="fas fa-exchange-alt"></i>
                                                        </div>
                                                    </div>
                                                    <div class="w-100"></div>
                                                    <div class="col text-theme small">{{ item.market }}</div>
                                                    <div class="col-auto small p-0" v-if="item.live">
                                                        <span class="badge live-blink">Live</span>
                                                    </div>
                                                    <div class="col-auto text-right text-truncate text-theme small">{{ item.odd }}</div>
                                                </div><!-- /row -->
                                            </div><!-- /l-item -->
                                        </template>
                                    </div><!-- /list-itens -->
                                </template>
                            </div><!-- /p-2 -->
                        </div><!-- /scroll-area -->
                        <div class="bottom-shap p-2">
                            <div class="d-flex flex-column">
                                <div>
                                    <div class="row">
                                        <div class="col text-default text-style text-truncate">Total odds</div>
                                        <div class="col text-default text-style text-truncate text-right">{{total_quotation | formatCoin}}</div>
                                    </div>
                                </div>
                                <div class="mb-1 pb-1">
                                    <div class="row">
                                        <div class="col text-default text-style text-truncate">Possível prêmio</div>
                                        <div class="col text-default text-style text-truncate text-right">R$ {{bet.premium | formatCoin}}</div>
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i class="fas fa-user-circle"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control form-sm form-bg" v-model="bet.punter" :disabled="pipe == 4" autocomplete="off" spellcheck="false" placeholder="Apostador" aria-label="Apostador" aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i class="fas fa-usd-circle"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control form-sm form-bg" v-model="bet.money" autocomplete="off" spellcheck="false" placeholder="Valor" aria-label="Valor" aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <div class="btn-group w-100" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-bg-none" @click="bet.money = 5" :disabled="hasAccumulated()">5</button>
                                        <button type="button" class="btn btn-bg-none" @click="bet.money = 10" :disabled="hasAccumulated()">10</button>
                                        <button type="button" class="btn btn-bg-none" @click="bet.money = 20" :disabled="hasAccumulated()">20</button>
                                        <button type="button" class="btn btn-bg-none" @click="bet.money = 50" :disabled="hasAccumulated()">50</button>
                                        <button type="button" class="btn btn-bg-none" @click="bet.money = 100" :disabled="hasAccumulated()">100</button>
                                    </div>
                                </div>
                                <div>
                                    <div class="btn-group w-100" role="group" aria-label="Basic example" :class="{'disabled': selectedEventsCount() == 0}">
                                        <button type="button" class="btn" @click="clearBet">
                                            <i class="fas fa-undo"></i>
                                        </button>
                                        <button type="button" class="btn text-style" @click="confirmBet">
                                            <span>Apostar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div><!-- /bottom-shap -->
                    </div><!-- /col-wrap -->
                </div><!-- /col-right -->
            </main>
        </template>
        <!-- Mobile -->
        <template v-else>
            <template v-if="isLoad">
                <div class="d-flex justify-content-center align-items-center h-100">
                    <div v-html="lds_ring"></div>
                </div>
            </template>
            <template v-else>
                <main class="main-mobile">
                    <div class="sub-items-mobile">
                        <div class="nav-sport-mobile d-flex flex-row justify-content-between">
                            <div class="p-2" v-for="(item, index) of mainMenu" :key="index" :class="{'active': sport == item.id || (item.id == 'live' && live)}">
                                <a href="javascript:;" :class="{'disabled': sport == item.id || (item.id == 'live' && live)}" :title="item.name" @click="changeSport(item.id)">
                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                        <div class="mr-2">
                                            <i :class="item.icon"></i>
                                        </div>
                                        <div>{{ item.name }}</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <template v-if="configs.slider_exibir == 1 && configs.slider.length && !isLogged">
                            <div class="image-slickCarousel" :class="{'image-slickCarousel-hidden': !imageSlickCarousel}" :style="{height: imageSlickCarouselHeight + 'px', maxHeight: imageSlickCarouselHeight + 'px', width: '100%'}">
                                <VueSlickCarousel v-bind='{"dots": false, "arrows": false, "speed": 500, "focusOnSelect": true, "infinite": true, "autoplay": true, "centerMode": (configs.slider.length == 1 ? false : true), "centerPadding": (configs.slider.length == 1 ? "0px" : "20px"), "lazyLoad": "ondemand", "adaptiveHeight": true}'>
                                    <div v-for="(item, index) in configs.slider" :key="index" @click="getToLink(index)">
                                        <img @load="subItemsClientHeight" class="img-fluid" :src="item.image" :alt="index">
                                    </div>
                                </VueSlickCarousel>
                            </div>
                        </template>
                        <div class="row border-b">
                            <div class="col-8 pr-0">
                                <template v-if="showSearchBox">
                                    <div class="row h-100">
                                        <div class="col-12">
                                            <div class="input-group h-100">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text h-100" id="basic-addon1">
                                                        <template v-if="search">
                                                            <i class="fas fa-broom text-danger" role="button" @click="search = ''"></i>
                                                        </template>
                                                        <template v-else>
                                                            <i class="fas fa-search"></i>
                                                        </template>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control form-sm form-bg h-100" v-model="search" @focus="inputFocus" autocomplete="off" spellcheck="false" placeholder="Pesquisar time, liga ou país..." aria-label="Pesquisar time, liga ou país..." aria-describedby="basic-addon1">
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="nav-sport-mobile sports d-flex flex-row justify-content-between">
                                        <div class="p-2" v-for="(item, index) of sports" :key="index" :class="{'active disabled': sport == item.id || (item.id == 'live' && live)}" :id="`sport-${item.id}`">
                                            <a href="javascript:;" :class="{'disabled': sport == item.id || (item.id == 'live' && live)}" :title="item.name" @click="changeSport(item.id)">
                                                <div class="d-flex flex-column justify-content-center align-items-center">
                                                    <div>
                                                        <i :class="item.icon"></i>
                                                        <span class="badge" v-show="countEvents && !loading">
                                                            {{ countEvents }}
                                                        </span>
                                                    </div>
                                                    <div class="text-style">{{ item.name }}</div>
                                                </div>
                                            </a>
                                        </div>
                                    </div><!-- /nav-sport-mobile -->
                                </template>
                            </div>
                            <div class="col-4 border-left-1">
                                <div class="nav-sport-mobile sports d-flex flex-row justify-content-between">
                                    <div class="p-2" v-for="(item, index) of configs.calendario" :key="index" :class="{'active': date == item.date}">
                                        <a href="javascript:;" :class="{'disabled': sport == item.id || (item.id == 'live' && live)}" :title="item.name" @click="date = item.date">
                                            <div class="d-flex flex-column justify-content-center align-items-center">
                                                <div>
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                                <div class="text-style">{{ item.name.substring(0, 3) }}</div>
                                            </div>
                                        </a>
                                    </div>
                                </div><!-- /nav-sport-mobile -->
                            </div>
                        </div>
                    </div><!-- /sub-items -->
                    <div class="content-mobile" :style="{height: subItemsHeight + 'px'}">
                        <template v-if="loading && !problem">
                            <div class="d-flex justify-content-center align-items-center h-100">
                                <div v-html="lds_ring"></div>
                            </div>
                        </template>
                        <template v-else-if="problem && !nothing_found">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>{{ message }}</small>
                            </div>
                        </template>
                        <template v-else-if="nothing_found && !problem">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>Sem eventos</small>
                            </div>
                        </template>
                        <template v-else-if="nothing_found && problem && message">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>{{ message }}</small>
                                <div class="mt-2" v-if="!live">
                                    <button class="btn btn-sm bg-button text-style" role="button" @click="nextDay" :class="{'min-width-200px': platform == 'desktop'}">
                                        <i class="fas fa-calendar-day mr-2"></i>Próximo dia
                                    </button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="filteredListEvents.length">
                                <collection-cluster class="events-main" v-bind="collection.mobile" :items="filteredListEvents" @scroll="detectScroll" @scrollToTop="onScrollToTop" @scrollToBottom="onScrollToBottom" :style="{height: subItemsHeight + 'px'}">
                                    <div class="event-item" slot="item" slot-scope="{ cell, item }" :key="cell.index">
                                        <template v-if="cell.index == 0">
                                            <div class="row no-pad">
                                                <div class="col-12">
                                                    <div class="events-main-league mb-2">
                                                        <template v-if="item.ao_vivo">
                                                            <div class="row no-pad align-items-center">
                                                                <div class="col text-truncate">
                                                                    <div class="mr-2 flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                                    <span class="text-uppercase text-truncate">
                                                                        {{ item.liga | changeLeagueName }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-auto">
                                                                    <i class="fas fa-signal-stream live-blink text-danger"></i>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="row no-pad align-items-center">
                                                                <div class="col-8 text-truncate">
                                                                    <div class="mr-2 flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                                    <span class="text-uppercase text-truncate">
                                                                        {{ item.liga | changeLeagueName }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-4 text-right pl-0" :class="{'text-truncate': item.esporte != 1}">
                                                                    <template v-if="item.esporte == 1">
                                                                        <div class="dropdown">
                                                                            <button class="btn btn-sm btn-block dropdown-toggle text-truncate" type="button" :id="`dropdownMenuButton-${cell.index}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                {{ item.mercado_id == 'principais' ? 'Principais' : item.mercado_id == 'ambas_equipes_marcam' ? 'Ambas marcam' : item.mercado_id == 'total_de_gols' ? 'Gols Acima/Abaixo' : 'Dupla possibilidade' }}
                                                                            </button>
                                                                            <div class="dropdown-menu dropdown-menu-right" :aria-labelledby="`dropdownMenuButton-${cell.index}`">
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('principais', item.liga_id)" :class="{'active disabled': item.mercado_id == 'principais'}">Principais</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('ambas_equipes_marcam', item.liga_id)" :class="{'active disabled': item.mercado_id == 'ambas_equipes_marcam'}">Ambas marcam</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('total_de_gols', item.liga_id)" :class="{'active disabled': item.mercado_id == 'total_de_gols'}">Gols Acima/Abaixo</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('dupla_possibilidade', item.liga_id)" :class="{'active disabled': item.mercado_id == 'dupla_possibilidade'}">Dupla possibilidade</a>
                                                                            </div>
                                                                        </div><!-- /dropdown-->
                                                                    </template>
                                                                    <template v-else>
                                                                        <span class="text-uppercase text-truncate">{{ item.horario | formatDate }}</span>
                                                                    </template>
                                                                </div><!-- /col-4 -->
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else-if="item['liga_id'] != filteredListEvents[cell.index - 1]['liga_id']">
                                            <div class="row no-pad">
                                                <div class="col-12">
                                                    <div class="events-main-league mb-2">
                                                        <template v-if="item.ao_vivo">
                                                            <div class="row no-pad align-items-center">
                                                                <div class="col text-truncate">
                                                                    <div class="mr-2 flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                                    <span class="text-uppercase text-truncate">
                                                                        {{ item.liga | changeLeagueName }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-auto">
                                                                    <i class="fas fa-signal-stream live-blink text-danger"></i>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="row no-pad align-items-center">
                                                                <div class="col-8 text-truncate">
                                                                    <div class="mr-2 flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                                                    <span class="text-uppercase text-truncate">
                                                                        {{ item.liga | changeLeagueName}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-4 text-right pl-0" :class="{'text-truncate': item.esporte != 1}">
                                                                    <template v-if="item.esporte == 1">
                                                                        <div class="dropdown">
                                                                            <button class="btn btn-sm btn-block dropdown-toggle text-truncate" type="button" :id="`dropdownMenuButton-${cell.index}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                {{ item.mercado_id == 'principais' ? 'Principais' : item.mercado_id == 'ambas_equipes_marcam' ? 'Ambas marcam' : item.mercado_id == 'total_de_gols' ? 'Gols Acima/Abaixo' : 'Dupla possibilidade' }}
                                                                            </button>
                                                                            <div class="dropdown-menu dropdown-menu-right" :aria-labelledby="`dropdownMenuButton-${cell.index}`">
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('principais', item.liga_id)" :class="{'active disabled': item.mercado_id == 'principais'}">Principais</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('ambas_equipes_marcam', item.liga_id)" :class="{'active disabled': item.mercado_id == 'ambas_equipes_marcam'}">Ambas marcam</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('total_de_gols', item.liga_id)" :class="{'active disabled': item.mercado_id == 'total_de_gols'}">Gols Acima/Abaixo</a>
                                                                                <a class="dropdown-item" href="javascript:;" @click="changeMarket('dupla_possibilidade', item.liga_id)" :class="{'active disabled': item.mercado_id == 'dupla_possibilidade'}">Dupla possibilidade</a>
                                                                            </div>
                                                                        </div><!-- /dropdown-->
                                                                    </template>
                                                                    <template v-else>
                                                                        <span class="text-uppercase text-truncate">{{ item.horario | formatDate }}</span>
                                                                    </template>
                                                                </div><!-- /col-4 -->
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <div class="container">
                                            <div class="row m-0">
                                                <template v-if="feedStyle == 'nowrap'">
                                                    <div class="col-8 pl-0 pr-0">
                                                        <div class="d-flex flex-column justify-content-center">
                                                            <div>
                                                                <div class="d-flex flex-row justify-content-start align-items-center">
                                                                    <div class="mr-2" v-if="item.esporte == 1">
                                                                        <img class="align-middle object-fit" v-lazy="`${image_server}/s/${imageShield(item.time_casa)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="20" height="20" border="0">
                                                                    </div>
                                                                    <div class="event-team w-100 text-truncate">{{ item.time_casa }}</div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="d-flex flex-row justify-content-start align-items-center">
                                                                    <div class="mr-2" v-if="item.esporte == 1">
                                                                        <img class="align-middle object-fit" v-lazy="`${image_server}/s/${imageShield(item.time_fora)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="20" height="20" border="0">
                                                                    </div>
                                                                    <div class="event-team w-100 text-truncate">{{ item.time_fora }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 pr-0">
                                                        <div class="d-flex flex-column justify-content-end align-items-end">
                                                            <template v-if="item.ao_vivo">
                                                                <span class="event-time">
                                                                    <div class="d-flex justify-content-center align-items-center">
                                                                        <div class="pr-2">{{ item.placar.split('-')[0] }}</div>
                                                                        <div>
                                                                            <img class="versus-symbol-mobile" height="10" src="/images/versus_symbol.png" alt="X">
                                                                        </div>
                                                                        <div class="pl-2">{{ item.placar.split('-')[1] }}</div>
                                                                    </div>
                                                                </span>
                                                                <span class="event-time">
                                                                    <template v-if="item.periodo == '1st Half' || item.periodo == '2nd Half'">
                                                                        {{ item.hora }} : {{ item.periodo == '1st Half' ? '1T' : item.periodo == '2nd Half' ? '2T' : 'INT.' }}
                                                                    </template>
                                                                    <template v-else>INT.</template>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                <span class="event-time">{{ item.horario | formatDay }}</span>
                                                                <span class="event-time" :class="{'diff': item.tempo_status == 'diff', 'diff-blink': item.tempo_status == 'blink'}">{{ item.hora }}</span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="col text-truncate">
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <div class="img-shield">
                                                                <img class="align-middle object-fit" v-lazy="`${image_server}/s/${imageShield(item.time_casa)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="20" height="20" border="0">
                                                            </div>
                                                            <div class="event-team w-100 text-truncate text-center">{{ item.time_casa }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="d-flex flex-column align-items-center justify-content-center h-100">
                                                            <template v-if="item.ao_vivo">
                                                                <span class="event-time">
                                                                    <div class="d-flex justify-content-center">
                                                                        <div class="score pr-3">{{ item.placar.split('-')[0] }}</div>
                                                                        <div>
                                                                            <img class="versus" src="/images/versus_symbol.png" alt="X">
                                                                        </div>
                                                                        <div class="score pl-3">{{ item.placar.split('-')[1] }}</div>
                                                                    </div>
                                                                </span>
                                                                <span class="event-time">
                                                                    <template v-if="item.periodo == '1st Half' || item.periodo == '2nd Half'">
                                                                        {{ item.hora }} : {{ item.periodo == '1st Half' ? '1T' : item.periodo == '2nd Half' ? '2T' : 'INT.' }}
                                                                    </template>
                                                                    <template v-else>INT.</template>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                <span class="event-time">{{ item.horario | formatDay }}</span>
                                                                <span class="event-time" :class="{'diff': item.tempo_status == 'diff', 'diff-blink': item.tempo_status == 'blink'}">{{ item.hora }}</span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="col text-truncate">
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <div class="img-shield">
                                                                <img class="align-middle object-fit" v-lazy="`${image_server}/s/${imageShield(item.time_fora)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="20" height="20" border="0">
                                                            </div>
                                                            <div class="event-team w-100 text-truncate text-center">{{ item.time_fora }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div><!-- /row -->
                                        </div><!-- /container -->
                                        <div class="row mr-2">
                                            <div class="col ml-2 pr-0">
                                                <div class="btn-group w-100 mr-2 grid-gap" role="group" aria-label="Basic example">
                                                    <template v-if="item.mercado_id == 'principais'">
                                                        <button type="button" class="btn text-uppercase" @click="selectEvent(item.id, 1, item.ao_vivo, false, false, false, false, false, item.odds.casa)" :class="{'active': selectedEvents(item.id, 1, false, false, false, false, false)}" :ref="`${item.id}:1`" :disabled="item.odds.casa <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>1</div>
                                                                <div class="odd">
                                                                    <template v-if="item.odds.casa <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                                        <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{item.odds.casa}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <button type="button" class="btn text-uppercase" @click="selectEvent(item.id, 2, item.ao_vivo, false, false, false, false, false, item.odds.empate)" :class="{'active': selectedEvents(item.id, 2, false, false, false, false, false)}" :ref="`${item.id}:2`" :disabled="item.odds.empate <= 1 || item.hora >= configs.ao_vivo_tempo" v-if="item.esporte == 1">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>
                                                                    <i class="fal fa-times"></i>
                                                                </div>
                                                                <div class="odd">
                                                                    <template v-if="item.odds.empate <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                                        <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{item.odds.empate}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <button type="button" class="btn text-uppercase" @click="selectEvent(item.id, 3, item.ao_vivo, false, false, false, false, false, item.odds.visitante)" :class="{'active': selectedEvents(item.id, 3, false, false, false, false, false)}" :ref="`${item.id}:3`" :disabled="item.odds.visitante <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>2</div>
                                                                <div class="odd" :class="{'pr-75rem': item.esporte != 1 && item.esporte != 2}">
                                                                    <template v-if="item.odds.visitante <= 1 || item.hora >= configs.ao_vivo_tempo">
                                                                        <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{item.odds.visitante}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <template v-if="item.esporte == 1 || item.esporte == 2">
                                                            <template v-if="item.ao_vivo">
                                                                <button class="btn " @click="moreOddsLive(item.id)" :class="{'active': selectedEventsMore(item.id)}" :disabled="item.hora >= configs.ao_vivo_tempo || item.odds.casa <= 1 && item.odds.empate <= 1 && item.odds.visitante <= 1">
                                                                    <div class="d-flex align-items-center" :class="item.hora >= configs.ao_vivo_tempo ? 'justify-content-center' : 'justify-content-between'">
                                                                        <template v-if="item.hora >= configs.ao_vivo_tempo">
                                                                            <i class="fas fa-lock"></i>
                                                                        </template>
                                                                        <template v-else>
                                                                            <i class="fal fa-plus"></i>
                                                                            <span>
                                                                                {{ item.total_odds }}
                                                                            </span>
                                                                        </template>
                                                                    </div>
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                <button class="btn" @click="moreOdds(item.id)" :class="{'active': selectedEventsMore(item.id)}">
                                                                    <div class="d-flex justify-content-between align-items-center">
                                                                        <i class="fal fa-plus"></i>
                                                                        <span>
                                                                            {{ item.total_odds }}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            </template>
                                                        </template>
                                                    </template>
                                                    <template v-else-if="item.mercado_id == 'ambas_equipes_marcam'">
                                                        <button class="btn text-uppercase" @click="selectEvent(item.id, 11, item.ao_vivo, false, false, false, false, false, item.odds.extra.ambas_equipes_marcam.sim)" :class="{'active': selectedEvents(item.id, 11, false, false, false, false, false)}" :disabled="item.odds.extra.ambas_equipes_marcam.sim <= 1">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>SIM</div>
                                                                <div>
                                                                    <template v-if="item.odds.extra.ambas_equipes_marcam.sim <= 1">
                                                                            <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                            {{item.odds.extra.ambas_equipes_marcam.sim}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <button class="btn text-uppercase" @click="selectEvent(item.id, 12, item.ao_vivo, false, false, false, false, false, item.odds.extra.ambas_equipes_marcam.nao)" :class="{'active': selectedEvents(item.id, 12, false, false, false, false, false)}" :disabled="item.odds.extra.ambas_equipes_marcam.nao <= 1">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>NÃO</div>
                                                                <div>
                                                                    <template v-if="item.odds.extra.ambas_equipes_marcam.nao <= 1">
                                                                        <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{item.odds.extra.ambas_equipes_marcam.nao}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </template>
                                                    <template v-else-if="item.mercado_id == 'total_de_gols'">
                                                        <button class="btn text-uppercase" @click="selectEvent(item.id, 15, item.ao_vivo, false, false, false, false, false, item.odds.extra.total_de_gols.acima_2_5)" :class="{'active': selectedEvents(item.id, 15, false, false, false, false, false)}" :disabled="item.odds.extra.total_de_gols.acima_2_5 <= 1">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>+2.5</div>
                                                                <div>
                                                                    <template v-if="item.odds.extra.total_de_gols.acima_2_5 <= 1">
                                                                            <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                            {{item.odds.extra.total_de_gols.acima_2_5}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <button class="btn text-uppercase" @click="selectEvent(item.id, 184, item.ao_vivo, false, false, false, false, false, item.odds.extra.total_de_gols.abaixo_2_5)" :class="{'active': selectedEvents(item.id, 184, false, false, false, false, false)}" :disabled="item.odds.extra.total_de_gols.abaixo_2_5 <= 1">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>-2.5</div>
                                                                <div>
                                                                    <template v-if="item.odds.extra.total_de_gols.abaixo_2_5 <= 1">
                                                                        <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{item.odds.extra.total_de_gols.abaixo_2_5}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn text-uppercase" @click="selectEvent(item.id, 4, item.ao_vivo, false, false, false, false, false, item.odds.extra.dupla_possibilidade.casa_ou_empate)" :class="{'active': selectedEvents(item.id, 4, false, false, false, false, false)}" :disabled="item.odds.extra.dupla_possibilidade.casa_ou_empate <= 1">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>1X</div>
                                                                <div>
                                                                    <template v-if="item.odds.extra.dupla_possibilidade.casa_ou_empate <= 1">
                                                                            <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                            {{item.odds.extra.dupla_possibilidade.casa_ou_empate}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <button class="btn text-uppercase" @click="selectEvent(item.id, 6, item.ao_vivo, false, false, false, false, false, item.odds.extra.dupla_possibilidade.casa_ou_fora)" :class="{'active': selectedEvents(item.id, 6, false, false, false, false, false)}" :disabled="item.odds.extra.dupla_possibilidade.casa_ou_fora <= 1">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>1 ou 2</div>
                                                                <div>
                                                                    <template v-if="item.odds.extra.dupla_possibilidade.casa_ou_fora <= 1">
                                                                        <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{item.odds.extra.dupla_possibilidade.casa_ou_fora}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <button class="btn text-uppercase" @click="selectEvent(item.id, 5, item.ao_vivo, false, false, false, false, false, item.odds.extra.dupla_possibilidade.fora_ou_empate)" :class="{'active': selectedEvents(item.id, 5, false, false, false, false, false)}" :disabled="item.odds.extra.dupla_possibilidade.fora_ou_empate <= 1">
                                                            <div class="d-flex" :class="btnStyle == 'between' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                                                <div>2X</div>
                                                                <div>
                                                                    <template v-if="item.odds.extra.dupla_possibilidade.fora_ou_empate <= 1">
                                                                        <i class="fas fa-lock"></i>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{item.odds.extra.dupla_possibilidade.fora_ou_empate}}
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </template>
                                                </div>
                                            </div>
                                        </div><!-- /row -->
                                    </div><!-- /event-item -->
                                </collection-cluster>
                                <div class="footer p-3 footer-mobile" :class="{'d-none': search || scrollPosition == 'top' || hideFooter || showSearchBox}" v-if="!isLogged">
                                    <div class="container">
                                        <div class="row align-items-center">
                                            <div class="col pl-0">
                                                <a href="https://www.gamblingtherapy.org/pt-br/" target="_blank">
                                                    <img src="/images/GT_v2.svg" border="0" alt="GT">
                                                </a>
                                            </div>
                                            <div class="col-auto pl-0 pr-0">
                                                <button class="btn btn-sm btn-outline-danger small" @click="hideFooter = true" role="button">Fechar</button>
                                            </div>
                                            <div class="col pr-0">
                                                <div class="d-flex flex-row justify-content-end">
                                                    <img class="mr-3" src="/images/18.svg" border="0" alt="+18">
                                                    <span class="display-5 ml-3 arrow-top" @click="toTop" title="Subir" role="button">
                                                        <i class="fas fa-chevron-circle-up"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="w-100"></div>
                                            <div class="col-12 text-center">
                                                <img src="/images/logo.png" border="0" height="26" alt="logo">
                                            </div>
                                            <div class="w-100"></div>
                                            <div class="col-12 text-center" v-html="footer"></div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="d-flex flex-column justify-content-center align-items-center mt-3">
                                    <div class="display-5">
                                        <i class="fas fa-exclamation-circle"></i>
                                    </div>
                                    <template v-if="search">
                                        <small>Não encontramos nenhum resultado para <b>{{ search }}</b>.</small>
                                    </template>
                                    <template v-else>
                                        <small>Nenhum evento encontrado.</small>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </div><!-- /mobile-content -->
                    <div class="navbar-mobile-bottom">
                        <div class="row h-100">
                            <div class="col text-truncate" @click="toggleShowLeagues">
                                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                    <div>
                                        <i class="fas fa-trophy-alt"></i>
                                    </div>
                                    <div class="small text-style text-truncate">Ligas</div>
                                </div>
                            </div>
                            <div class="col text-truncate pl-0 pr-0" @click="$modal.show('ticket')">
                                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                    <div class="position-relative">
                                        <i class="fas fa-ballot-check"></i>
                                        <span class="badge" v-if="selectedEventsCount()">
                                            {{ selectedEventsCount() }}
                                        </span>
                                    </div>
                                    <div class="small text-style text-truncate">
                                        <template v-if="selectedEventsCount() == 0">Bilhete</template>
                                        <template v-else>R$ {{bet.premium | formatCoin}}</template>
                                    </div>
                                </div>
                            </div>
                            <div class="col text-truncate" @click="toggleSearchBox">
                                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                    <div>
                                        <template v-if="showSearchBox">
                                            <i class="fa fa-times"></i>
                                        </template>
                                        <template v-else>
                                            <i class="fa fa-search"></i>
                                        </template>
                                    </div>
                                    <div class="small text-style text-truncate">Buscar</div>
                                </div>
                            </div>
                        </div><!-- /row -->
                    </div><!-- /mobile-navbar-bottom -->
                </main>
            </template>
        </template>
        <!-- /aqui termina o site mobile -->
        <div class="sidebar" :class="{'sidebar-show': sidebar}">
            <div class="sidebar-backdrop" @click="sidebar = false"></div>
            <div class="sidebar-content p-3">
                <div class="sidebar-header">
                    <div class="mb-3 text-center">
                        <img class="align-top" :src="logo" height="30" alt="logo">
                    </div>
                    <div class="btn-group w-100" role="group" aria-label="Basic example">
                        <template v-if="isLogged">
                            <button type="button" class="btn w-50" @click="$router.push('/finance')">
                                <i class="fas fa-coins mr-2"></i>Caixa
                            </button>
                            <template v-if="isCordova">
                                <button type="button" class="btn w-50" @click="listPrinter">
                                    <i class="fad fa-print-search mr-2"></i>Impressora
                                </button>
                            </template>
                            <template v-else>
                                <button type="button" class="btn w-50" @click="logout">
                                    <i class="fas fa-sign-out-alt mr-2"></i>Sair
                                </button>
                            </template>
                        </template>
                        <template v-else>
                            <button type="button" class="btn w-50 text-truncate" @click="login">
                                <i class="fas fa-sign-in-alt mr-2"></i>Entrar
                            </button>
                            <button type="button" class="btn w-50 text-truncate" @click="register">
                                <i class="fas fa-user-plus mr-2"></i>Registre-se
                            </button>
                        </template>
                    </div>
                </div><!-- /sidebar-header -->
                <div class="list-group list-group-flush scroll">
                    <template v-if="showLeagues">
                        <template v-if="favorites_leagues_exists">
                            <h5 class="pt-2 pb-2 mb-0">Destaques</h5>
                            <div class="list-itens">
                                <a class="list-group-item list-group-item-action league" href="javascript:;" v-for="(item, index) in favorites_leagues" :key="index" @click="search = item.league_full" :class="{'disabled': search == item.league_full}">
                                    <div class="row justify-content-center align-items-center">
                                        <div class="col-auto pl-0">
                                            <div class="flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                        </div>
                                        <div class="col pl-0 text-truncate">
                                            <div class="d-flex justify-content-between">
                                                <div class="text-truncate" style="max-width: 80%;">{{ item.league }}</div>
                                                <div class="text-number">{{ item.total }}</div>
                                            </div>
                                        </div>
                                    </div><!-- /row -->
                                </a><!-- /l-item -->
                            </div><!-- /list-itens -->
                        </template>
                        <h5 class="pt-2 pb-2 mb-0">Campeonatos</h5>
                        <div class="list-itens">
                            <a class="list-group-item list-group-item-action league" href="javascript:;" v-for="(item, index) in groupChampionships" :key="index" @click="search = item.country" :class="{'disabled': search == item.country}">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-auto pl-0">
                                        <div class="flag" :class="`country-flag-24-2x is-${item.liga_cc}`"></div>
                                    </div>
                                    <div class="col pl-0 text-truncate">
                                        <div class="d-flex justify-content-between">
                                            <div class="text-truncate" style="max-width: 80%;">{{ item.country }}</div>
                                            <div class="text-number">{{ item.total }}</div>
                                        </div>
                                    </div>
                                </div><!-- /row -->
                            </a>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="!ios && !isCordova">
                            <button class="btn btn-sm btn-block btn-secondary" @click="download">
                                <i class="fab fa-android mr-2"></i>Baixar aplicativo
                            </button>
                        </template>
                        <template v-if="isLogged">
                            <router-link to="/table" class="list-group-item list-group-item-action">
                                <i class="fas fa-print mr-2"></i>Tabela
                            </router-link>
                            <router-link to="/bets" class="list-group-item list-group-item-action">
                                <i class="fas fa-receipt mr-2"></i>Apostas
                            </router-link>
                            <a href="javascript:;" @click="prebet = true" class="list-group-item list-group-item-action">
                                <i class="far fa-check-double mr-2"></i>Pré-bet
                            </a>
                        </template>
                        <a href="javascript:;" @click="refreshPage" class="list-group-item list-group-item-action">
                            <i class="fas fa-sync-alt mr-2"></i>Atualizar
                        </a>
                        <a href="javascript:;" @click="regras = true" class="list-group-item list-group-item-action">
                            <i class="fas fa-book-open mr-2"></i>Regulamento
                        </a>
                        <a href="javascript:;" @click="help" class="list-group-item list-group-item-action">
                            <i class="fas fa-shield-alt mr-2"></i>Regras de aposta 
                        </a>
                    </template>
                </div>
                <div class="sidebar-footer pb-3">
                    <div class="list-group list-group-flush">
                        <div href="javascript:;" class="list-group-item list-group-item-action text-muted">
                            <i class="fad fa-user-headset mr-2"></i>Atendimento 
                        </div>
                    </div>
                    <div class="d-flex flex-row align-items-center justify-content-center">
                        <div class="display-3" @click="getService('whatsapp')">
                            <i class="fab fa-whatsapp"></i>
                        </div>
                        <div class="display-3 ml-3 mr-3" @click="getService('telegram')">
                            <i class="fab fa-telegram"></i>
                        </div>
                        <div class="display-3 mr-3" @click="getService('instagram')">
                            <i class="fab fa-instagram"></i>
                        </div>
                    </div>
                    <button class="btn btn-sm btn-block btn-outline-warning mt-3" @click="ticket = true" role="button">
                        <i class="fas fa-ballot-check mr-2"></i>Conferir bilhete 
                    </button>
                </div>
            </div><!-- /sidebar-content -->
        </div><!-- /sidebar -->
        <modal name="odds" :width="platform == 'desktop' ? 650 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" @before-open="beforeOpen" @before-close="beforeClose" :classes="['modal-dark','modal-new']">
            <template v-if="isNotEmpty(modal)">
                <div class="modal-topbar">
                    <div class="row align-items-center">
                        <div class="col text-truncate">
                            <div class="mr-2 flag" :class="`country-flag-24-2x is-${modal.liga_cc}`"></div>
                            <span class="text-uppercase text-truncate">
                                {{ modal.liga | changeLeagueName}}
                            </span>
                        </div>
                        <div class="col-auto text-right">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <div @click="widgetField" class="mr-3" role="button" v-if="modal.transmissao_id && !widget_field_disabled && !isCordova">
                                    <img class="widget-field-icon" :class="{'widget-field-disabled': !widget_field}" src="/images/381594_field_football_icon.png" height="24" alt="Campinho">
                                </div>
                                <div @click="$modal.hide('odds')" role="button">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /modal-topbar -->
                <div class="modal-topheader" :class="{'basket': sport == 2}">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column align-items-center flex-basis-left" :class="{'mobile': platform == 'mobile'}">
                            <div>
                                <img class="mr-2 align-middle object-fit" :src="`${image_server}/s/${imageShield(modal.partida.time_casa.nome)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" :width="platform == 'desktop' ? 48 : 38" :height="platform == 'desktop' ? 48 : 38" border="0">
                            </div>
                            <div class="text-style text-truncate mt-2">{{modal.partida.time_casa.nome}}</div>
                        </div><!-- /d-flex  -->
                        <div class="d-flex flex-column align-items-center flex-basis-middle" :class="{'mobile': platform == 'mobile'}">
                            <template v-if="modal.ao_vivo">
                                <div class="min-width-200px">
                                    <template v-if="dbo[modal.id].info.periodo == '1st Half' || dbo[modal.id].info.periodo == '2nd Half'">
                                        <span>{{ dbo[modal.id].info.tempo }}</span>
                                        <span class="live-blink">'</span>
                                        <span class="pl-2 pr-2">:</span>
                                        <span>{{ dbo[modal.id].info.periodo == '1st Half' ? '1T' : dbo[modal.id].info.periodo == '2nd Half' ? '2T' : 'INT.' }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="text-uppercase">
                                            {{ platform == 'desktop' ? 'Intervalo' : 'Int.' }}
                                        </span>
                                    </template>
                                </div>
                                <div class="min-width-200px">
                                    <div class="d-flex justify-content-center">
                                        <div class="score pr-3">{{ dbo[modal.id].info.placar.split('-')[0] }}</div>
                                        <div>
                                            <img class="versus" src="/images/versus_symbol.png" alt="X">
                                        </div>
                                        <div class="score pl-3">{{ dbo[modal.id].info.placar.split('-')[1] }}</div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="min-width-200px">{{ modal.horario | formatDate }}</div>
                                <div class="min-width-200px">{{ modal.horario | formatHour }}</div>
                            </template>
                        </div><!-- /d-flex  -->
                        <div class="d-flex flex-column align-items-center flex-basis-right" :class="{'mobile': platform == 'mobile'}">
                            <div>
                                <img class="mr-2 align-middle object-fit" :src="`${image_server}/s/${imageShield(modal.partida.time_fora.nome)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" :width="platform == 'desktop' ? 48 : 38" :height="platform == 'desktop' ? 48 : 38" border="0">
                            </div>
                            <div class="text-style text-truncate mt-2">{{modal.partida.time_fora.nome}}</div>
                        </div><!-- /d-flex  -->
                    </div><!-- /d-flex  -->
                </div><!-- /modal-topheader-->
                <div class="modal-subnav" :class="{'disabled': has_odds_block || modal.esporte != 1}">
                    <div class="row align-items-center pl-3 pr-3">
                        <div class="col text-center" @click="prevSubNav" role="button" v-show="platform == 'desktop'">
                            <i class="fas fa-chevron-left"></i>
                        </div>
                        <div class="col" :class="{'w-100 p-0': platform == 'mobile'}">
                            <ul class="nav nav-justified">
                                <li class="nav-item" v-if="betBuilder && modal.esporte == 1 && selectedEventsNotBetBuilderCount(modal.id, modal.ao_vivo) == 0">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_id = 'bb';cat_name = ''" :class="{'active': cat_id == 'bb' && !cat_name && modal.esporte == 1}">
                                        <div class="d-flex flex-row align-items-center justify-content-between">
                                            <span class="mr-2">Criar aposta</span>
                                            <span class="badge">
                                                <template v-if="selectedEventsBetBuilderCount(modal.id) == 0">NOVO</template>
                                                <template v-else>{{ selectedEventsBetBuilderCount(modal.id) }}</template>
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_id = '90';cat_name = ''" :class="{'active': cat_id == '90' && !cat_name && modal.esporte == 1, 'disabled': selectedEventsBetBuilderCount(modal.id)}">Principal</a>
                                </li>
                                <li class="nav-item" v-if="!modal.ao_vivo && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_id = 'pt';cat_name = ''" :class="{'active': cat_id == 'pt' && !cat_name && modal.esporte == 1, 'disabled': selectedEventsBetBuilderCount(modal.id)}">1º tempo</a>
                                </li>
                                <li class="nav-item" v-if="!modal.ao_vivo && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_id = 'st';cat_name = ''" :class="{'active': cat_id == 'st' && !cat_name && modal.esporte == 1, 'disabled': selectedEventsBetBuilderCount(modal.id)}">2º tempo</a>
                                </li>
                                <li class="nav-item" v-if="has_odds_double_possibility && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_name = 'Dupla possibilidade'" :class="{'active': cat_name == 'Dupla possibilidade', 'disabled': selectedEventsBetBuilderCount(modal.id)}">Dupla possibilidade</a>
                                </li>
                                <li class="nav-item" v-if="has_odds_both_score && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_name = 'Ambas equipes marcam'" :class="{'active': cat_name == 'Ambas equipes marcam', 'disabled': selectedEventsBetBuilderCount(modal.id)}">Ambas equipes marcam</a>
                                </li>
                                <li class="nav-item" v-if="has_odds_total_goals_match && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_name = 'Partida / Total de gols'" :class="{'active': cat_name == 'Partida / Total de gols', 'disabled': selectedEventsBetBuilderCount(modal.id)}">Gols Acima/Abaixo</a>
                                </li>
                                <li class="nav-item" v-if="has_odds_handicap && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_name = 'Handicap'" :class="{'active': cat_name == 'Handicap', 'disabled': selectedEventsBetBuilderCount(modal.id)}">Handicap</a>
                                </li>
                                <li class="nav-item" v-if="has_odds_corners && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_name = 'Escanteios'" :class="{'active': cat_name == 'Escanteios', 'disabled': selectedEventsBetBuilderCount(modal.id)}">Escanteios</a>
                                </li>
                                <li class="nav-item" v-if="has_odds_players && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_name = 'Jogadores'" :class="{'active': cat_name == 'Jogadores', 'disabled': selectedEventsBetBuilderCount(modal.id)}">Jogadores</a>
                                </li>
                                <li class="nav-item" v-if="has_odds_cards && modal.esporte == 1">
                                    <a class="nav-link text-style" href="javascript:;" @mouseover="scrollToPosition" @click="cat_name = 'Cartões'" :class="{'active': cat_name == 'Cartões', 'disabled': selectedEventsBetBuilderCount(modal.id)}">Cartões</a>
                                </li>
                            </ul>
                        </div><!-- /col -->
                        <div class="col text-center" @click="nextSubNav" role="button" v-show="platform == 'desktop'">
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </div><!-- /row -->
                </div><!-- /modal-subnav -->
                <div class="modal-main">
                    <template v-if="oddsRender(modal, cat_id, cat_name).length == 0">
                        <div class="d-flex flex-column justify-content-center align-items-center h-100">
                            <div class="display-5">
                                <i class="fas fa-exclamation-circle"></i>
                            </div>
                            <small>Nenhuma opção disponível</small>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="modal.ao_vivo">
                            <div class="collection-cluster">
                                <template v-if="modal.transmissao_id && widget_field && !widget_field_disabled && !isCordova">
                                    <div class="iframe-container" :class="{'mobile': platform == 'mobile'}">
                                        <iframe id="widget-match" :src="`https://widget.betsnow.net/match/${modal.transmissao_id}?theme=extended`" @load="widgetMatch" frameborder="0" scrolling="no" allowtransparency="true" allow="autoplay" allowfullscreen></iframe>
                                    </div>
                                </template>
                                <div class="modal-main-item p-3" v-for="(item, i) in oddsRender(modal, cat_id, cat_name)" :key="i">
                                    <div class="row">
                                        <div class="col-12 text-style mb-1">
                                            <div class="d-flex flex-row justify-content-between">
                                                <div :class="{'mr-3': modal.esporte == 1}">
                                                    {{ item.cat_name == 'Principais' ? 'Vencedor' : item.cat_name }}
                                                </div>
                                                <div v-if="modal.esporte == 1">{{ item.cat_id }}</div>
                                            </div>
                                        </div>
                                        <div class="w-100"></div>
                                        <div class="col-12">
                                            <div class="row mt-2" v-for="(option, index) in item.options" :key="index">
                                                <div class="col-12">
                                                    <template v-if="isArray(option.odd)">
                                                        <div class="row-fluid" v-for="(opt, i) in option.odd" :class="{'mt-2': i > 0}" :key="i">
                                                            <!-- Jogadores-->
                                                            <template v-if="option.id == 98 || option.id == 99 || option.id == 236">
                                                                <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, opt.player, false, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, opt.player, false, false, false)}" :ref="`${modal.id}:${option.id}:${opt.player}`">
                                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                                        <div class="text-left mr-3" :data="opt">{{ opt.player }}</div>
                                                                        <div class="odd">{{ opt.odd }}</div>
                                                                    </div>
                                                                </button>
                                                            </template><!-- Escanteios -->
                                                            <template v-else-if="option.id == 100 || option.id == 101 || option.id == 102 || option.id == 237 || option.id == 238 || option.id == 239">
                                                                <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, opt.handicap, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, opt.handicap, false, false)}" :ref="`${modal.id}:${option.id}:${opt.handicap}`">
                                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                                        <div class="text-left mr-3">{{ `${option.id == 100 || option.id == 237 ? 'Mais de ' : option.id == 101 || option.id == 238 ? 'Menos de ' : 'Exatos '}` + opt.handicap }}</div>
                                                                        <div class="odd">{{ opt.odd }}</div>
                                                                    </div>
                                                                </button>
                                                            </template><!-- Cartões / Método do primeiro gol / Momento do primeiro gol -->
                                                            <template v-else-if="option.id == 123 || option.id == 124 || option.id == 125 || option.id == 126 || option.id == 127 || option.id == 128 || option.id == 240 || option.id == 241">
                                                                <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, opt.handicap, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, opt.handicap, false)}" :ref="`${modal.id}:${option.id}:${opt.handicap}`">
                                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                                        <div class="text-left mr-3">{{ opt.handicap }}</div>
                                                                        <div class="odd">{{ opt.odd }}</div>
                                                                    </div>
                                                                </button>
                                                            </template><!-- /OPÇÂO DE TOTAL DE PONTOS PARTIDA/CASA/FORA -->
                                                            <template v-else-if="option.id == 215 || option.id == 216 || option.id == 217 || option.id == 218 || option.id == 219 || option.id == 220">
                                                                <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, false, opt.handicap, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, false, opt.handicap)}" :ref="`${modal.id}:${option.id}:${opt.handicap}`">
                                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                                        <div class="text-left mr-3">{{ `${option.id == 215 || option.id == 217 || option.id == 219 ? 'Mais de ' : 'Menos de '}` + opt.handicap }}</div>
                                                                        <div class="odd">{{ opt.odd }}</div>
                                                                    </div>
                                                                </button>
                                                            </template>
                                                            <template v-else><!-- Placar -->
                                                                <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, opt.score, false, false, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, opt.score, false, false, false, false)}" :ref="`${modal.id}:${option.id}:${opt.score}`">
                                                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                                                        <div class="text-left mr-3">{{ opt.score }}</div>
                                                                        <div class="odd">{{ opt.odd }}</div>
                                                                    </div>
                                                                </button>
                                                            </template>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, false, false, option.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, false, false)}" :ref="`${modal.id}:${option.id}:live`">
                                                            <div class="d-flex flex-row justify-content-between align-items-center">
                                                                <div class="text-left mr-3">{{ option.option }}</div>
                                                                <div class="odd">{{ option.odd }}</div>
                                                            </div>
                                                        </button>
                                                    </template>
                                                </div><!-- /col -->
                                            </div><!-- /row -->
                                        </div><!-- /col-12 -->
                                    </div><!-- /row -->
                                </div><!-- /modal-main-item -->
                            </div><!-- /collection-cluster -->
                        </template>
                        <template v-else>
                            <!-- Criar aposta-->
                            <template v-if="betBuilderActive">
                                <div class="collection-cluster">
                                    <div class="alert alert-info mb-0 rounded-0" :class="{'alert-sticky': selectedEventsBetBuilderCount(modal.id)}" role="alert">
                                        <template v-if="selectedEventsBetBuilderCount(modal.id) == 0">
                                            <span class="small">Combine mercados do mesmo evento! Você também pode usar o "<strong>Criar aposta</strong>" para combinar com outras apostas também!</span>
                                        </template>
                                        <template v-else-if="selectedEventsBetBuilderCount(modal.id) < betBuilderMinSelected">
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <span class="small">Adicione pelo menos, <strong>{{ betBuilderMinSelected }}</strong> seleções do mesmo jogo para <strong>Criar aposta</strong>.</span>
                                                </div>
                                                <div class="col-auto">
                                                    <button class="btn btn-sm btn-danger btn-block" @click="betBuilderRemoveEvent(modal.id)">
                                                        <i class="fas fa-undo"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="row align-items-center">
                                                <div class="col">
                                                    <span class="small">Você pode fazer até <strong>{{ betBuilderMaxSelected }}</strong> seleções no mesmo jogo.</span>
                                                </div>
                                                <div class="col-auto">
                                                    <button class="btn btn-sm btn-danger btn-block" @click="betBuilderRemoveEvent(modal.id)">
                                                        <i class="fas fa-undo"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                    </div><!-- /alert -->
                                    <div class="modal-main-item p-3" v-for="(item, i) in oddsRender(modal, cat_id, cat_name)" :key="i">
                                        <div class="row">
                                            <div class="col-12 text-style mb-1">
                                                <div class="d-flex flex-row justify-content-between">
                                                    <div :class="{'mr-3': modal.esporte == 1}">
                                                        {{ item.cat_name == 'Principais' ? 'Vencedor' : item.cat_name }}
                                                    </div>
                                                    <div v-if="modal.esporte == 1">{{ item.cat_id }}</div>
                                                </div>
                                            </div>
                                            <div class="w-100"></div>
                                            <div class="col-12">
                                                <div class="row mt-2" v-for="(option, index) in item.options" :key="index">
                                                    <div class="col-12">
                                                        <template v-if="isArray(option.odd)">
                                                            <div class="row-fluid" v-for="(opt, i) in option.odd" :class="{'mt-2': i > 0}" :key="i">
                                                                <!-- Jogadores-->
                                                                <template v-if="option.id == 98 || option.id == 99 || option.id == 236">
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, opt.player, false, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, opt.player, false, false, false)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ opt.player }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template><!-- Escanteios -->
                                                                <template v-else-if="option.id == 100 || option.id == 101 || option.id == 102 || option.id == 237 || option.id == 238 || option.id == 239">
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, opt.handicap, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, opt.handicap, false, false)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ `${option.id == 100 || option.id == 237 ? 'Mais de ' : option.id == 101 || option.id == 238 ? 'Menos de ' : 'Exatos '}` + opt.handicap }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template><!-- Cartões / Método do primeiro gol -->
                                                                <template v-else-if="option.id == 123 || option.id == 124 || option.id == 125 || option.id == 126 || option.id == 127 || option.id == 128 || option.id == 240">
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, opt.handicap, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, opt.handicap, false)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ opt.handicap }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template><!-- /OPÇÂO DE TOTAL DE PONTOS PARTIDA/CASA/FORA -->
                                                                <template v-else-if="option.id == 215 || option.id == 216 || option.id == 217 || option.id == 218 || option.id == 219 || option.id == 220">
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, false, opt.handicap, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, false, opt.handicap)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ `${option.id == 215 || option.id == 217 || option.id == 219 ? 'Mais de ' : 'Menos de '}` + opt.handicap }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template>
                                                                <template v-else><!-- Placar -->
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, opt.score, false, false, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, opt.score, false, false, false, false)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ opt.score }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, false, false, option.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, false, false)}">
                                                                <div class="d-flex flex-row justify-content-between align-items-center">
                                                                    <div class="text-left mr-3">{{ option.option }}</div>
                                                                    <div class="odd">{{ option.odd }}</div>
                                                                </div>
                                                            </button>
                                                        </template>
                                                    </div><!-- /col -->
                                                </div><!-- /row -->
                                            </div><!-- /col-12 -->
                                        </div><!-- /row -->
                                    </div><!-- /modal-main-item -->
                                </div><!-- /collection-cluster -->
                            </template>
                            <template v-else>
                                <collection-cluster v-bind="collection.modal" :items="oddsRender(modal, cat_id, cat_name)">
                                    <div class="modal-main-item p-3" slot="item" slot-scope="{ cell, item }" :key="cell.index">
                                        <div class="row">
                                            <div class="col-12 text-style mb-1">
                                                <div class="d-flex flex-row justify-content-between">
                                                    <div :class="{'mr-3': modal.esporte == 1}">
                                                        {{ item.cat_name == 'Principais' ? 'Vencedor' : item.cat_name }}
                                                    </div>
                                                    <div v-if="modal.esporte == 1">{{ item.cat_id }}</div>
                                                </div>
                                            </div>
                                            <div class="w-100"></div>
                                            <div class="col-12">
                                                <div class="row mt-2" v-for="(option, index) in item.options" :key="index">
                                                    <div class="col-12">
                                                        <template v-if="isArray(option.odd)">
                                                            <div class="row-fluid" v-for="(opt, i) in option.odd" :class="{'mt-2': i > 0}" :key="i">
                                                                <!-- Jogadores-->
                                                                <template v-if="option.id == 98 || option.id == 99 || option.id == 236">
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, opt.player, false, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, opt.player, false, false, false)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ opt.player }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template><!-- Escanteios -->
                                                                <template v-else-if="option.id == 100 || option.id == 101 || option.id == 102 || option.id == 237 || option.id == 238 || option.id == 239">
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, opt.handicap, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, opt.handicap, false, false)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ `${option.id == 100 || option.id == 237 ? 'Mais de ' : option.id == 101 || option.id == 238 ? 'Menos de ' : 'Exatos '}` + opt.handicap }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template><!-- Cartões / Método do primeiro gol -->
                                                                <template v-else-if="option.id == 123 || option.id == 124 || option.id == 125 || option.id == 126 || option.id == 127 || option.id == 128 || option.id == 240">
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, opt.handicap, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, opt.handicap, false)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ opt.handicap }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template><!-- /OPÇÂO DE TOTAL DE PONTOS PARTIDA/CASA/FORA -->
                                                                <template v-else-if="option.id == 215 || option.id == 216 || option.id == 217 || option.id == 218 || option.id == 219 || option.id == 220">
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, false, opt.handicap, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, false, opt.handicap)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ `${option.id == 215 || option.id == 217 || option.id == 219 ? 'Mais de ' : 'Menos de '}` + opt.handicap }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template>
                                                                <template v-else><!-- Placar -->
                                                                    <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, opt.score, false, false, false, false, opt.odd)" :class="{'active': selectedEvents(modal.id, option.id, opt.score, false, false, false, false)}">
                                                                        <div class="d-flex flex-row justify-content-between align-items-center">
                                                                            <div class="text-left mr-3">{{ opt.score }}</div>
                                                                            <div class="odd">{{ opt.odd }}</div>
                                                                        </div>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <button class="btn btn-block btn-sm btn-theme" @click="selectEvent(modal.id, option.id, modal.ao_vivo, false, false, false, false, false, option.odd)" :class="{'active': selectedEvents(modal.id, option.id, false, false, false, false, false)}">
                                                                <div class="d-flex flex-row justify-content-between align-items-center">
                                                                    <div class="text-left mr-3">{{ option.option }}</div>
                                                                    <div class="odd">{{ option.odd }}</div>
                                                                </div>
                                                            </button>
                                                        </template>
                                                    </div><!-- /col -->
                                                </div><!-- /row -->
                                            </div><!-- /col-12 -->
                                        </div><!-- /row -->
                                    </div><!-- /modal-main-item -->
                                </collection-cluster>
                            </template>
                        </template>
                    </template>
                </div><!-- /modal-main-->
            </template>
            <template v-else>
                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                    <div class="display-5">
                        <i class="fas fa-exclamation-circle"></i>
                    </div>
                    <small>Nenhuma opção disponível</small>
                </div>
            </template>
        </modal><!-- /modal-odds -->
        <modal name="challenges" :width="platform == 'desktop' ? 650 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" :classes="['modal-dark','modal-new']">
            <template v-if="challenges.length == 0">
                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                    <div class="display-5">
                        <i class="fas fa-exclamation-circle"></i>
                    </div>
                    <small>Nenhum desafio disponível</small>
                </div>
            </template>
            <template v-else>
                <div class="modal-topbar">
                    <div class="row align-items-center">
                        <div class="col text-truncate text-uppercase">
                            <img class="align-middle mr-2" src="/images/2915192.png" height="24" alt="Desafios" border="0">
                            <span>Desafios</span>
                        </div>
                        <div class="col-auto text-right">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <div @click="$modal.hide('challenges')" role="button">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /modal-topbar -->
                <div class="modal-main modal-main-challenges">
                    <div class="collection-cluster">
                        <div class="modal-main-item" v-for="(desafio, k) in challenges" :key="k">
                            <div class="row event-header">
                                <div class="col ml-2 pt-2 text-left" :class="{'pb-2': platform == 'desktop'}">
                                    <span class="text-style text-theme">{{ desafio.titulo }}</span>
                                </div>
                                <div class="w-100" v-if="platform == 'mobile'"></div>
                                <div class="col mr-2 pb-2" :class="{'pt-2 text-right': platform == 'desktop', 'ml-2': platform == 'mobile'}">
                                    <span class="text-style">{{ desafio.horario | formatDateDayAndHour }}</span>
                                </div>
                            </div><!-- /row-header -->
                            <div class="row">
                                <div class="col pt-2 pb-2 text-center">
                                    <i class="fas fa-question-circle mr-2"></i>
                                    <span class="text-style">{{ desafio.descricao }}</span>
                                </div>
                            </div><!-- /row -->
                            <div class="row pl-2 pr-2 mb-2" :class="{'flex-column': platform == 'mobile'}" v-for="(opcoes, j) in separar(oddsStructureChallenge(desafio.odds), 2)" :key="j">
                                <div class="col" v-for="(item, l) in opcoes" :key="l" :class="{'mt-2': l > 0 && platform == 'mobile'}">
                                    <button class="btn btn-block btn-theme-color" @click="selectChallenge(desafio.id, item.id, item.odd)" :class="{'active': selectedEvents(desafio.id, item.id, false, false, false, false, false)}" :ref="[desafio.id, item.id].join(':')" :disabled="item.odd <= 1">
                                        <div class="d-flex" :class="platform == 'desktop' ? 'justify-content-between' : 'flex-column align-items-center justify-content-center'">
                                            <div>{{ item.option }}</div>
                                            <div>{{ item.odd | formatCoin }}</div>
                                        </div><!-- /d-flex -->
                                    </button>
                                </div><!-- /col -->
                            </div><!-- /row -->
                        </div><!-- /event-item -->
                    </div><!-- /collection-cluster -->
                </div><!-- /modal-main -->
            </template>
        </modal>
        <modal name="conclude-bet" :width="platform == 'desktop' ? 350 : screen_w" :height="platform == 'desktop' ? 345 : screen_h" :adaptive="true" @before-close="beforeClose" :classes="['modal-dark','modal-new']">
            <header class="container bg-black pt-2 pb-2">
                <div class="row">
                    <div class="col">
                        <div class="display-5 text-white">Aposta</div>
                    </div>
                    <div class="col-auto">
                        <div class="text-right text-white mt-1" role="button" @click="$modal.hide('conclude-bet')">
                            <i class="fal fa-times"></i>
                        </div><!-- /modal-close -->
                    </div>
                </div>
            </header>
            <div class="p-3 w-100">
                <div class="display-1 text-center text-success mb-3">
                    <i class="fas fa-check-circle"></i>
                    <div class="mt-2 display-5">
                        <span>O que deseja fazer?</span>
                    </div>
                </div>
                <button class="btn btn-block btn-secondary" @click="dialogPrint">Imprimir</button>
                <button class="btn btn-block btn-secondary" @click="dialogShare">Compartilhar</button>
            </div><!-- /p-3 -->
        </modal><!-- /conclude-bet -->
        <modal name="ticket" :width="screen_w" :height="screen_h" :adaptive="true" :classes="['modal-dark','modal-new']">
            <div class="row m-0">
                <div class="col-wrap">
                    <div class="scroll-area scroll-area-ticket" @scroll="scrollAreaTicket">
                        <div class="p-2 h-100">
                            <h5 class="pb-2 mb-0 sticky" :class="{'pt-2': sticky}">
                                <div class="row">
                                    <div class="col-auto" @click="$modal.hide('ticket')">
                                        <i class="fas fa-chevron-left mr-2"></i>Voltar
                                    </div>
                                    <div class="col text-right">
                                        <span>Bilhete</span>
                                        <span class="text-theme ml-2" v-if="selectedEventsCount()">{{ selectedEventsCount() }}</span>
                                    </div>
                                </div>
                            </h5>
                            <template v-if="selectedEventsCount() == 0">
                                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                    <div class="display-5">
                                        <i class="fas fa-exclamation-circle"></i>
                                    </div>
                                    <small>Bilhete vázio</small>
                                </div>
                            </template>
                            <template v-else>
                                <div class="list-itens h-100">
                                    <template v-if="betbuilderItemsListCount">
                                        <div class="l-item d-block" v-for="(item, key, index) in betbuilderItemsList" :key="index">
                                            <div class="row">
                                                <div class="col text-truncate small l-item-emphasis">
                                                    {{ item.league | changeLeagueName }}
                                                </div><!-- /col -->
                                                <div class="col-auto text-right small">
                                                    <i class="far fa-clock mr-2"></i>{{ item.schedule | formatDateDDMMHHMM }}
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="col text-truncate">
                                                    {{ item.team_home }}
                                                </div>
                                                <div class="col-auto text-right text-truncate">
                                                    <div class="text-right text-theme" role="button" @click="removeEvent(item.key)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </div>
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="col text-truncate">
                                                    {{ item.team_away}}
                                                </div>
                                                <div class="col-auto text-right text-truncate">
                                                    <div class="text-right" role="button" @click="moreOdds(item.id)" :class="{'disabled text-muted': item.sport != '1'}">
                                                        <i class="fas fa-exchange-alt"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-for="(palpite, i) in item.items" :key="i">
                                                <div class="col text-theme small">{{ palpite.market }}</div>
                                                <div class="col-auto small p-0" v-if="palpite.live">
                                                    <span class="badge live-blink">Live</span>
                                                </div>
                                                <div class="col-auto text-right text-truncate text-theme small">{{ palpite.odd }}</div>
                                            </div><!-- /row -->
                                        </div><!-- /l-item -->
                                    </template>
                                    <template v-if="hunchesListCount">
                                        <div class="l-item d-block" v-for="(item, key, index) in hunchesList" :key="index">
                                            <div class="row">
                                                <div class="col text-truncate small l-item-emphasis">
                                                    {{ item.league | changeLeagueName }}
                                                </div><!-- /col -->
                                                <div class="col-auto text-right small">
                                                    <i class="far fa-clock mr-2"></i>{{ item.schedule | formatDateDDMMHHMM }}
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="col text-truncate">
                                                    {{ item.team_home }}
                                                </div>
                                                <div class="col-auto text-right text-truncate">
                                                    <div class="text-right text-theme" role="button" @click="removeEvent(item.id, key)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </div>
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="col text-truncate">
                                                    {{ item.team_away}}
                                                </div>
                                                <div class="col-auto text-right text-truncate">
                                                    <div class="text-right" role="button" @click="moreOdds(item.id)" :class="{'disabled text-muted': item.sport != '1'}">
                                                        <i class="fas fa-exchange-alt"></i>
                                                    </div>
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="col text-theme small">{{ item.market }}</div>
                                                <div class="col-auto small p-0" v-if="item.live">
                                                    <span class="badge live-blink">Live</span>
                                                </div>
                                                <div class="col-auto text-right text-truncate text-theme small">{{ item.odd }}</div>
                                            </div><!-- /row -->
                                        </div><!-- /l-item -->
                                    </template>
                                </div><!-- /list-itens -->
                            </template>
                        </div><!-- /p-2 -->
                    </div><!-- /scroll-area -->
                    <div class="bottom-shap p-2">
                        <div class="d-flex flex-column">
                            <div>
                                <div class="row">
                                    <div class="col text-default text-style text-truncate">Total odds</div>
                                    <div class="col text-default text-style text-truncate text-right">{{total_quotation | formatCoin}}</div>
                                </div>
                            </div>
                            <div class="mb-1 pb-1">
                                <div class="row">
                                    <div class="col text-default text-style text-truncate">Possível prêmio</div>
                                    <div class="col text-default text-style text-truncate text-right">R$ {{bet.premium | formatCoin}}</div>
                                </div>
                            </div>
                            <div class="mb-1">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fas fa-user-circle"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control form-sm form-bg" v-model="bet.punter" :disabled="pipe == 4" @focus="showModalSetPunter" autocomplete="off" spellcheck="false" placeholder="Apostador" aria-label="Apostador" aria-describedby="basic-addon1">
                                </div>
                            </div>
                            <div class="mb-1">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fas fa-usd-circle"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control form-sm form-bg" v-model="bet.money" autocomplete="off" spellcheck="false" placeholder="Valor" aria-label="Valor" aria-describedby="basic-addon1">
                                </div>
                            </div>
                            <div class="mb-1" :class="{'d-none': platform == 'mobile'}">
                                <div class="btn-group w-100" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-bg-none" @click="bet.money = 5" :disabled="hasAccumulated()">5</button>
                                    <button type="button" class="btn btn-bg-none" @click="bet.money = 10" :disabled="hasAccumulated()">10</button>
                                    <button type="button" class="btn btn-bg-none" @click="bet.money = 20" :disabled="hasAccumulated()">20</button>
                                    <button type="button" class="btn btn-bg-none" @click="bet.money = 50" :disabled="hasAccumulated()">50</button>
                                    <button type="button" class="btn btn-bg-none" @click="bet.money = 100" :disabled="hasAccumulated()">100</button>
                                </div>
                            </div>
                            <div>
                                <div class="btn-group w-100" role="group" aria-label="Basic example" :class="{'disabled': selectedEventsCount() == 0}">
                                    <button type="button" class="btn" @click="clearBet">
                                        <i class="fas fa-undo"></i>
                                    </button>
                                    <button type="button" class="btn text-style" @click="confirmBet">
                                        <span>Apostar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div><!-- /bottom-shap -->
                </div><!-- /col-wrap -->
            </div>
        </modal>
        <modal name="set-punter" :width="screen_w" :height="screen_h" :adaptive="true" :classes="['modal-dark','modal-new']">
            <div class="row m-0">
                <div class="col-wrap">
                    <div class="scroll-area scroll-area-ticket max-height-70" @scroll="scrollAreaTicket">
                        <div class="p-2 h-100">
                            <h5 class="pb-2 mb-0 sticky" :class="{'pt-2': sticky}">
                                <div class="row">
                                    <div class="col-4" @click="$modal.hide('set-punter')">
                                        <i class="fas fa-long-arrow-left"></i>
                                    </div>
                                    <div class="col-8 text-right">
                                        <span>Apostadores</span>
                                    </div>
                                </div>
                            </h5>
                            <template v-if="bettors.length == 0">
                                <div class="d-flex flex-column justify-content-center align-items-center h-100">
                                    <div class="display-5">
                                        <i class="fas fa-exclamation-circle"></i>
                                    </div>
                                    <small>Nenhum apostador</small>
                                </div>
                            </template>
                            <template v-else>
                                <div class="list-itens h-100">
                                    <div class="l-item d-block" v-for="(name, index) in bettors" :key="index">
                                        <div class="row">
                                            <div class="col text-truncate l-item-emphasis" @click="bet.punter = name;$modal.hide('set-punter')">
                                                {{ name }}
                                            </div><!-- /col -->
                                            <div class="col-auto text-right text-truncate">
                                                <div class="text-right text-theme" role="button" @click="removeNameByList(index)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </div>
                                            </div>
                                        </div><!-- /row -->
                                    </div><!-- /l-item -->
                                </div><!-- /list-itens -->
                            </template>
                        </div><!-- /p-2 -->
                    </div><!-- /scroll-area -->
                    <div class="bottom-shap p-2">
                        <div class="d-flex flex-column">
                            <div class="mb-1">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fas fa-user-circle"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control form-sm form-bg" v-model="bet.punter" autocomplete="off" spellcheck="false" placeholder="Apostador" aria-label="Apostador" aria-describedby="basic-addon1">
                                </div>
                            </div>
                            <div>
                                <div class="btn-group w-100" role="group" aria-label="Basic example">
                                    <button type="button" class="btn" @click="clearListNames" :class="{'disabled': bettors.length == 0}">
                                        <i class="fas fa-undo"></i>
                                    </button>
                                    <button type="button" class="btn text-style" @click="$modal.hide('set-punter')">
                                        <span>Confirmar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div><!-- /bottom-shap -->
                </div><!-- /col-wrap -->
            </div>
        </modal><!-- /set-punter -->
        <modal name="modal-popup" :width="250" :height="400" :adaptive="true" @before-close="closePopup">
            <div class="modal-popup">
                <a class="text-white" href="javascript:;" role="button" @click="$modal.hide('modal-popup')">
                    <i class="fal fa-times"></i>
                </a>
                <div class="w-100 h-100" v-for="(item, index) in configs.popup_items" :key="index">
                    <a :href="item.link ? item.link : 'javascript:;'">
                        <img :src="item.image" alt="Imagem">
                    </a>
                </div>
            </div>
        </modal><!-- /modal-popup -->
        <modal name="view-bet" :width="platform == 'desktop' ? 650 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" @before-close="beforeClose" :classes="['modal-dark','modal-new']">
            <template v-if="template && betData">
                <div class="modal-topbar">
                    <div class="row align-items-center">
                        <div class="col text-truncate">
                            <i class="fas fa-hashtag align-middle"></i>
                            <span class="text-uppercase text-truncate">
                                {{ code }}
                            </span>
                        </div>
                        <div class="col-auto text-right">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <div @click="shareBet(betData.code, 0, betData.value, betData.premium, betData.punter)" class="mr-3" role="button" title="Compartilhar">
                                    <i class="fas fa-share"></i>
                                </div>
                                <div @click="$modal.hide('view-bet')" role="button">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /modal-topbar -->
                <div class="modal-view-bet" v-html="template"></div>
            </template>
        </modal><!-- /modal-view-bet -->
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :class="{'isLive': check_conclude_bet_is_live}"></loading>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import {api} from '../../api'
import io from 'socket.io-client'
import WebNavbar from './WebNavbar'
import VModal from 'vue-js-modal'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CollectionCluster from 'vue-collection-cluster'

import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'

import VueLazyload from 'vue-lazyload'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

let today = moment().tz('America/Recife').format('YYYY-MM-DD');

let socket = io('wss://live-stream.betsnow.net', {
    secure: true,
    autoConnect: false,
    rememberUpgrade: true,
    rejectUnauthorized: false,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    transports: ['websocket']
});

Vue.use(VModal)
Vue.use(SlimDialog)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: require('/public/images/1495.gif'),
  error: require('/public/images/shield_default.png'),
  attempt: 1
})

export default {
    data() {
        return {
            sports: [{
                id: 1,
                name: 'Futebol',
                icon: 'fas fa-futbol'
            },{
                id: 2,
                name: 'Basquete',
                icon: 'fas fa-basketball-ball'
            },{
                id: 3,
                name: 'Lutas',
                icon: 'fas fa-boxing-glove'
            },{
                id: 4,
                name: 'Hóquei',
                icon: 'fas fa-hockey-puck'
            },{
                id: 5,
                name: 'Voleibol',
                icon: 'fas fa-volleyball-ball'
            },{
                id: 6,
                name: 'Tênis',
                icon: 'fas fa-tennis-ball'
            },{
                id: 7,
                name: 'E-Sports',
                icon: 'fas fa-gamepad'
            },{
                id: 'challenge',
                name: 'Desafio',
                icon: 'fas fa-badge-dollar'
            },{
                id: 'live',
                name: 'Ao vivo',
                icon: 'fas fa-signal-stream'
            }],
            mainMenu: [{
                id: 1,
                name: 'Esportes',
                icon: 'fad fa-chart-network'
            },{
                id: 'live',
                name: 'Ao vivo',
                icon: 'fas fa-signal-stream'
            },{
                id: 'casino',
                name: 'Cassino',
                icon: 'fas fa-dice'
            },{
                id: 'store',
                name: 'Loja',
                icon: 'fas fa-shopping-cart'
            }],
            collection: {
                mobile: {
                    heightType: 'automatic', 
                    itemHeight: 152
                },
                desktop: {
                    heightType: 'automatic',
                    itemHeight: 109
                },
                modal: {
                    heightType: 'automatic',
                    itemHeight: 128
                }
            },
            pipe: 0,
            sport: 1,
            delay: 15,
            sticky: false,
            betBuilder: false,
            betBuilderOdds: {},
            betBuilderLive: false,
            betBuilderOptions: [],
            betBuilderMinSelected: 2,
            betBuilderMaxSelected: 4,
            betBuilderMinGamesSelected: 1,
            betBuilderMaxGamesSelected: 5,
            betBuilderActive: false,
            betbuilderItemsList: {},
            betbuilderItemsListCount: 0,
            showLeagues: false,
            btnStyle: 'between',
            progressbar_percent: 0,
            progressbar_interval: '',
            groupChampionships: [],
            check_conclude_bet_is_live: false,
            bets: [],
            betData: {},
            cashback: {
                nome: '',
                cel: ''
            },
            market_id: '',
            countEvents: 0,
            blockOnScroll: false,
            pre_bet_code: 0,
            templateRules: '',
            templateQRCodePix: '',
            templateCommissions: '',
            deposit_value: '',
            withdraw_money: '',
            withdraw_money_success: false,
            bets_nothing_found: false,
            search_bets_nothing_found: false,
            has_odds_block: false,
            has_odds_cards: false,
            has_odds_corners: false,
            has_odds_players: false,
            has_odds_handicap: false,
            has_odds_both_score: false,
            has_odds_first_half: true,
            has_odds_second_half: true,
            has_odds_total_goals_match: false,
            has_odds_double_possibility: false,
            events: [],
            configs: {
                popup_exibir: 'nao',
                popup_items: []
            },
            hideFooter: false,
            showSearchBox: false,
            hunches: {},
            hunchesList: {},
            hunchesListCount: 0,
            instagram: '',
            whatsapp: '',
            telegram: '',
            collectionClusterExists: false,
            widget_field: true,
            widget_field_disabled: false,
            search: '',
            message: '',
            search_bets: '',
            favorites: [],
            challenges: [],
            accumulated: [],
            accumulated_id: 0,
            new_search: false,
            inputPreBetCode: '',
            screen_w: window.screen.width,
            screen_h: window.screen.height,
            screen_adjust: window.innerWidth,
            screen_innerHeight: window.innerHeight,
            ios: /iPad|iPhone|iPod/.test(navigator.userAgent),
            bet: {
                money: '',
                punter: '',
                premium: 0,
                accept: false
            },
            scroll: {
                position: 0
            },
            dbo: {},
            code: '',
            cash: {},
            modal: [],
            feedStyle: 'nowrap',
            highlights: [],
            hasHighlights: false,
            dbPregameMarkets: {},
            subItemsHeight: 320,
            navBottomHeight: 106,
            subItemsHeightDefault: 99,
            imageSlickCarousel: true,
            imageSlickCarouselHeight: 100,
            modal_opened: false,
            carouselInterval: null,
            status: 1,
            cat_id: 90,
            cat_name: '',
            live: false,
            date: today,
            option_index: 0,
            total_quotation: 0,
            initial_date: today,
            final_date: today,
            isLoad: false,
            loading: false,
            problem: false,
            isLogged: false,
            isLoading: false,
            selectedAll: false,
            nothing_found: false,
            selectedPrintList: [],
            cashFinancialByName: {},
            liveOptionsStructure: {},
            bettors: [],
            username: '',
            password: '',
            template: '',
            scrollModalPosition: 0,
            scrollPosition: 'top',
            favorites_leagues: {},
            favorites_leagues_exists: false,
            user_name: 'Visitante',
            hash: window.location.hash,
            isLocalHost: window.location.hostname == 'localhost',
            current_time: moment().tz('America/Recife').format(),
            page_current: document.location.pathname.split('/').pop(),
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            site: window.location.protocol == 'https:' ? 'https://' + window.location.hostname : 'http://' + window.location.hostname
        }
    },
    components: {
        Loading,
        WebNavbar,
        VueSlickCarousel,
        CollectionCluster
    },
    methods: {
        toggleSearchBox() {
            this.showSearchBox = !this.showSearchBox;
        },
        widgetMatch(event) {
            const iframe = document.querySelector('iframe#widget-match');
            event.target.contentWindow.postMessage({
                theme: this.getStyleWidget(),
                itemWidth: (iframe.offsetWidth || 0),
                itemHeight: (iframe.offsetHeight || 0)
            }, '*');
        },
        widgetReceiveMessage(event) {
            try {
                if (event.origin == 'https://widget.betsnow.net') {
                    if (event.data.action == 'disabled') {
                        this.widget_field_disabled = true;
                    } else {
                        this.widget_field_disabled = false;
                    }
                }
            } catch(e) {
                this.widget_field_disabled = false;
            }
        },
        toggleShowLeagues() {
            if (this.countEvents == 0) {
                this.showAlert(
                    'Atenção!', 
                    'Nenhuma liga disponível!'
                );
            } else {
                this.showLeagues = !this.showLeagues;
            }
        },
        listPrinter() {
            window.location.href = 'betsnet://printer';
        },
        setPunter(punter) {
            try {

                const self = this;
                const bettors = window.localStorage.getItem('bettors');

                if (bettors) {

                    self.bettors = [];

                    const data = JSON.parse(bettors);

                    for (const name of data) {

                        if (!(self.bettors.includes(name) || self.bettors.includes(self.removeAccents(name)))) {
                            self.bettors.push(name);
                        }
                    }

                    if (!(self.bettors.includes(punter) || self.bettors.includes(self.removeAccents(punter)))) {
                        self.bettors.push(punter);
                    }
                } 
                
                if (!(self.bettors.includes(punter) || self.bettors.includes(self.removeAccents(punter)))) {
                    self.bettors.push(punter);
                }

                self.bettors = self.bettors.reduce((result, element) => {
                    var normalize = x => typeof x === 'string' ? self.removeAccents(x).toLowerCase() : x;

                    var normalizedElement = normalize(element);
                    if (result.every(otherElement => normalize(otherElement) !== normalizedElement))
                    result.push(element);

                    return result;
                }, []).sort((a, b) => {
                    return self.removeAccents(a).localeCompare(self.removeAccents(b))
                });
                
                window.localStorage.setItem('bettors', JSON.stringify(self.bettors));
            } catch(e) {
                window.localStorage.removeItem('bettors');
            }
        },
        recoverPunterList() {

            const self = this;
            const bettors = window.localStorage.getItem('bettors');
            const celCashback = window.localStorage.getItem('cashbackCel');
            const nameCashback = window.localStorage.getItem('cashbackName');

            if (celCashback) {
                self.cashback.cel = celCashback;
            }

            if (nameCashback) {
                self.cashback.nome = nameCashback;
            }

            if (bettors) {

                self.bettors = [];

                const data = JSON.parse(bettors);

                for(const name of data) {
                    self.bettors.push(name);
                }
            }
        },
        removeNameByList(index) {
            if (this.bettors.length) {
                if (this.bettors[index]) {
                    this.bettors.splice(index, 1);
                }
            }
        },
        clearListNames() {
            if (this.bettors.length == 0) {
                this.showAlert(
                    'Atenção!', 
                    'Sua lista de apostadores já está vázia!'
                );
            } else {
                this.$dialogs.confirm('Você deseja mesmo limpar a lista de apostadores?', {
                    title: 'Limpar a lista',
                    cancelLabel: 'Não',
                    okLabel: 'Sim',
                    size: 'sm'
                }).then(res => {
                    if (res.ok) {
                        this.bettors = [];
                    }
                });
            }
        },
        viewBet(id, bets) {

            const self = this;

            self.isLoading = true;

            api.get(`view-bet/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountView(id, bets, response.data.hunches);
                    break;
                    default:
                        self.showAlert(
                            'Falha!', 
                            response.data.message
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    self.showAlert(
                        'Falha!', 
                        self.network_erros[error.status]['message']
                    );
                } catch(e) {
                    self.showAlert(
                        'Falha!', 
                        self.network_erros[408]['message']
                    );
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        ticketByCode(code) {

            const self = this;

            self.code = code;
            self.sidebar = false;
            self.isLoading = true;

            api.get(`bet/anonymous/${code}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.viewBet(response.data.id, response.data.bets);
                    break;
                    case 'nothing_found':
                        self.showAlert(
                            'Aposta não encontrada!', 
                            'Não foi possível encontrar a aposta. Por favor, verifique o código e tente novamente!'
                        );
                        self.isLoading = false;
                    break;
                    default:
                        self.isLoading = false;
                        self.showAlert('Falha!', response.data.message);
                    break;
                }
            }).catch((error) => {
                try {
                    self.showAlert(
                        'Falha!', 
                        self.network_erros[error.status]['message']
                    );
                } catch(e) {
                    self.showAlert(
                        'Falha!', 
                        self.network_erros[408]['message']
                    );
                } finally {
                    self.isLoading = false;
                }
            });
        },
        mountView(id, bets, hunches) {
            try {
                let html = ``;

                const self = this;
                const bet = bets.find((item) => item.id == id);

                if (bet instanceof Object) {

                    let anteior_value = 0;
                    let anteior_value_team = 0; 
                    let hunches_order = self.orderByScheduleByBet(hunches);
                    let commission = (bet.premium / 100) * self.configs.comissao_aposta_premiada;

                    for (let key in hunches_order) {
                        let palpite = hunches_order[key];

                        if (anteior_value != palpite.league) {
                            html += `
                            <div class="league">
                                <div>${palpite.league}</div>
                            </div>
                            `;
                        }

                        if (palpite.betbuilder) {

                            html += `<div class="event">`;

                            if (anteior_value_team != palpite.id) {
                                html += `
                                    <div class="item">
                                        <div class="item-col">${palpite.team_home}</div>
                                        <div class="item-col">${self.dateTicket(palpite.schedule)}</div>
                                    </div>
                                    <div class="item">
                                        <div class="item-col">${palpite.team_away}</div>
                                        <div class="item-col">Pré-jogo</div>
                                    </div>
                                    <div class="item">
                                        <div class="item-col-3 font-bold">Modalidade</div>
                                        <div class="item-col-3 font-bold">Situação</div>
                                        <div class="item-col-3 font-bold">Odds</div>
                                    </div>
                                `;
                            }

                            html += `
                                <div class="item">
                                    <div class="item-col-3">Criar aposta</div>
                                    <div class="item-col-3 ${palpite.situation == 1 || palpite.status == 'g' ? 'hit' : palpite.situation == 2 || palpite.status == 'p' ? 'lose' : 'default'}">${palpite.status == 'g' ? 'Acertou' : palpite.status == 'p' ? 'Perdeu' : palpite.status == 'c' ? 'Cancelado' : self.findSituation(palpite.situation)}</div>
                                    <div class="item-col-3">R$&nbsp;${palpite.odd}</div>
                                </div>
                                <div class="item">
                                    ${palpite.situation == 7 ? `
                                        <div class="alert alert-warning mt-2 text-center">${self.findMarketSituation(palpite)}</div>
                                    ` : `
                                        <div class="item-market font-bold">${self.findMarketSituation(palpite)}</div>
                                    `}
                                </div>
                            `;

                            html += `</div>`;

                        } else {
                            html += `
                            <div class="event">
                                <div class="item">
                                    <div class="item-col">${palpite.team_home}</div>
                                    <div class="item-col">${self.dateTicket(palpite.schedule)}</div>
                                </div>
                                <div class="item">
                                    <div class="item-col">${palpite.team_away}</div>
                                    <div class="item-col">R$&nbsp;${palpite.odd}</div>
                                </div>
                                <div class="item">
                                    <div class="item-col-3 font-bold">Modalidade</div>
                                    <div class="item-col-3 font-bold">Situação</div>
                                    <div class="item-col-3 font-bold">Ao vivo</div>
                                </div>
                                <div class="item">
                                    <div class="item-col-3">${self.findModality(palpite.sport)}</div>
                                    <div class="item-col-3 ${palpite.situation == 1 || palpite.status == 'g' ? 'hit' : palpite.situation == 2 || palpite.status == 'p' ? 'lose' : 'default'}">${palpite.status == 'g' ? 'Acertou' : palpite.status == 'p' ? 'Perdeu' : palpite.status == 'c' ? 'Cancelado' : self.findSituation(palpite.situation)}</div>
                                    <div class="item-col-3">${palpite.live ? 'Sim' : 'Não'}</div>
                                </div>
                                <div class="item">
                                    ${palpite.situation == 7 ? `
                                        <div class="alert alert-warning mt-2 text-center">${self.findMarketSituation(palpite)}</div>
                                    ` : `
                                        <div class="item-market font-bold">${self.findMarketSituation(palpite)}</div>
                                    `}
                                </div>
                            </div>
                            `;
                        }

                        anteior_value = palpite.league;
                        anteior_value_team = palpite.id;
                    }

                    let template = `
                     <div class="ticket-virtual">
                        <div class="header">
                            <div class="item">
                                <div class="logo">
                                    <img src="${self.logo}" border="0" alt="logo" />
                                </div>
                            </div>
                            <div class="item text-right">
                                <div class="font-bold">Código</div>
                                <div>${bet.code}</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <h3 class="text-center ${bet.situation == 1 ? 'd-none' : 'd-block text-underline'}">
                                ${bet.give_money_back ? 'Aposta devolvida' : bet.situation == 2 ? 'Aposta premiada' : bet.situation == 3 ? 'Aposta perdida' : bet.situation == 4 ? 'Aposta cancelada' : 'Sem informações'}    
                            </h3>

                            ${bet.account_type == 'vendedor' ? `
                                <div class="wrap-item">
                                    <div>Vendedor</div>
                                    <div>${bet.salesman}</div>
                                </div>
                            ` : ''}
                            
                            <div class="wrap-item">
                                <div>Apostador</div>
                                <div>${bet.punter}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Valor apostado</div>
                                <div>R$&nbsp;${self.format_coin(bet.value)}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Possível prêmio</div>
                                <div>R$&nbsp;${self.format_coin(bet.premium)}</div>
                            </div>
                            ${self.configs.comissao_aposta_premiada != 0 ? `
                                <div class="wrap-item">
                                    <div>Vendedor paga</div>
                                    <div>R$&nbsp;${self.format_coin(bet.premium - commission)}</div>
                                </div>
                            ` : ''}
                            <div class="wrap-item">
                                <div>Horário da aposta</div>
                                <div>${self.convertDate(bet.date) + '&nbsp;-&nbsp;' + bet.hour}</div>
                            </div>
                            <h5 class="text-center">
                                Qtd. Palpites: ${bet.total_hunches}    
                            </h5>
                            <div class="wrap-content">
                                <div class="wrap-content-item">
                                    ${html}
                                </div>
                            </div>
                        </div>
                     </div>
                    `;

                    self.betData = bet;
                    self.template = template;
                    self.$modal.show('view-bet');
                } 
                else 
                {
                    self.showAlert('Atenção!', 'Aposta não encontrada!');
                }
            } 
            catch(e) 
            {
                this.showAlert('Atenção!', e.message);
            }
        },
        shareBet(code, id = 0, valor = 0, premio = 0, apostador = '') {
            if (this.platform == 'desktop') {
                window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(`Acompanhe sua aposta clicando no link abaixo!! \n\n ${this.site}/ticket/${code} \n\n Apostador: ${apostador} \n Valor apostado: R$ ${this.formatCoin(valor)} \n Possível retorno: R$ ${this.formatCoin(premio)}`)}`,'_blank');
            } else if (this.isCordova) {
                this.shareImage(this.bets.find((item) => item.id == id));
            } else {
                window.location.href = `whatsapp://send?text=${encodeURIComponent(`Acompanhe sua aposta clicando no link abaixo!! \n\n ${this.site}/ticket/${code} \n\n Apostador: ${apostador} \n Valor apostado: R$ ${this.formatCoin(valor)} \n Possível retorno: R$ ${this.formatCoin(premio)}`)}`;
            }
        },
        openBet() {
            if (this.platform == 'mobile') {
                this.$modal.show('ticket');
            }
        },
        confirmBet() {

            const self = this;
            const isLive = self.checkIsLive();
            const min_jogos = isLive ? self.configs.min_jogos_ao_vivo : self.configs.min_jogos;
            const max_jogos = isLive ? self.configs.max_jogos_ao_vivo : self.configs.max_jogos;
            const min_valor = isLive ? self.configs.min_valor_ao_vivo : self.configs.min_valor;
            const selectedEventsCount = isLive ? self.selectedEventsCountLive() : self.selectedEventsCount();
            const label = [min_jogos, min_jogos == 1 ? `${isLive ? 'papite no ao vivo' : 'palpite'}` : `${isLive ? 'papites no ao vivo' : 'palpites'}`].join(' ');

            if (selectedEventsCount < min_jogos) {
                self.showAlert(
                    'Atenção!', 
                    `Selecione pelo menos ${label}`
                );
            } else if (selectedEventsCount > max_jogos) {
                self.showAlert(
                    'Atenção!', 
                    `Selecione no máximo ${max_jogos} palpites por bilhete!`
                );
            } else if (self.bet.punter.trim() == '') {
                self.showAlert(
                    'Atenção!', 
                    'Informe o nome do apostador!'
                );
            } else if (self.bet.punter.trim().length < 3) {
                self.showAlert(
                    'Atenção!', 
                    'Seu nome deve conter no mínimo 3 caracteres!'
                );
            } else if (!(self.bet.punter).match(/^[a-zA-Z]/gi)) {
                self.showAlert(
                    'Atenção!', 
                    'Seu nome deve conter letras!'
                );
            } else if (self.bet.money.toString().trim() == '') {
                self.showAlert(
                    'Atenção!', 
                    'Informe o valor da aposta!'
                );
            } else if (self.bet.premium == 0) {
                self.showAlert(
                    'Atenção!', 
                    'O valor do prêmio está zerado!'
                );
            } else if (parseFloat(self.bet.money) < parseFloat(min_valor)) {
                self.showAlert(
                    'Atenção!', 
                    `O valor mínimo para realizar apostas é de R$ ${self.formatCoin(min_valor)}`
                );
            } else if (!self.checkBetBuilderIsValid()) {
                self.showAlert(
                    'Atenção!', 
                    `Para "<strong>Criar aposta</strong>" é obrigatório seguir as regras de seleção mínima e máxima de cada jogo!<hr/>Mínimo de palpites: <strong>${self.betBuilderMinGamesSelected}</strong><br>Máximo de palpites: <strong>${self.betBuilderMaxGamesSelected}</strong><hr/>Mínimo de seleção: <strong>${self.betBuilderMinSelected}</strong><br>Máximo de seleção: <strong>${self.betBuilderMaxSelected}</strong>`
                );
            } else {
                self.$dialogs.confirm(`
                    <div class="alert alert-secondary mb-0" role="alert">
                        <div class="alert-heading">
                            <strong>Confira se todas as informações estão corretas</strong>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col">Qtd. Palpites</div>
                            <div class="col text-truncate">${self.selectedEventsCount()}</div>
                        </div>
                        <div class="row">
                            <div class="col">Apostador</div>
                            <div class="col text-truncate">${self.bet.punter}</div>
                        </div>
                        <div class="row">
                            <div class="col">Valor</div>
                            <div class="col text-truncate">R$ ${self.formatCoin(self.bet.money)}</div>
                        </div>
                        <div class="row">
                            <div class="col">Prêmio</div>
                            <div class="col text-truncate">R$ ${self.formatCoin(self.bet.premium)}</div>
                        </div>
                        ${self.loja == 'sim' && !self.isLogged ? `
                            <a class="btn btn-secondary btn-block mt-3" onclick="onCashback()" data-toggle="collapse" href="#collapseCashback" role="button" aria-expanded="false" aria-controls="collapseCashback">
                                <i class="fas fa-usd-circle mr-2"></i>Cashback
                            </a>
                            <div class="collapse mt-3" id="collapseCashback">
                                <div class="card card-body">
                                    <p class="mb-0">Informe seu <strong>nome</strong> e <strong>celular</strong> para receber <strong>cashback</strong> das suas apostas.</p>
                                    <hr/>
                                    <p class="mb-0">Acumule pontos, e troque por produtos em nossa loja online.</p>
                                    <hr/>
                                    <div class="form-group">
                                        <label for="nameCashback" class="font-bold">Nome completo</label>
                                        <input type="text" class="form-control" id="nameCashback" onkeyup="nameCashback(this)" placeholder="Nome completo">
                                    </div>
                                    <div class="form-group">
                                        <label for="celCashback" class="font-bold">Número do celular</label>
                                        <input type="text" class="form-control" id="celCashback" onkeyup="celCashback(this)" placeholder="Número do celular">
                                    </div>
                                </div>
                            </div>
                        ` : ''}
                    </div>
                `,{
                    title: `${self.isLogged ? 'Concluir aposta?' : 'Concluir pré-aposta?'}`,
                    cancelLabel: 'Não',
                    okLabel: 'Sim',
                    size: 'sm'
                }).then(res => {
                    if (res.ok) {
                        if (isLive && self.isLogged) {
                            self.isLoading = true;
                            self.check_conclude_bet_is_live = true;
                            setTimeout(() => self.concludeBet(), self.delay * 1000);
                        } else {
                            self.concludeBet();
                        }
                    }
                });
            }
        },
        concludeBet() {

            const self = this;
            const isLive = self.checkIsLive();
            const server_url = isLive ? 'conclude-bet-live' : 'conclude-bet';
            const min_jogos = isLive ? self.configs.min_jogos_ao_vivo : self.configs.min_jogos;
            const max_jogos = isLive ? self.configs.max_jogos_ao_vivo : self.configs.max_jogos;
            const min_valor = isLive ? self.configs.min_valor_ao_vivo : self.configs.min_valor;
            const selectedEventsCount = isLive ? self.selectedEventsCountLive() : self.selectedEventsCount();
            const label = [min_jogos, min_jogos == 1 ? `${isLive ? 'papite no ao vivo' : 'palpite'}` : `${isLive ? 'papites no ao vivo' : 'palpites'}`].join(' ');
            
            if (selectedEventsCount < min_jogos) {
                self.showAlert(
                    'Atenção!', 
                    `Selecione pelo menos ${label}`
                );
            } else if (selectedEventsCount > max_jogos) {
                self.showAlert(
                    'Atenção!', 
                    `Selecione no máximo ${max_jogos} palpites por bilhete!`
                );
            } else if (self.bet.punter.trim() == '') {
                self.showAlert(
                    'Atenção!', 
                    'Informe o nome do apostador!'
                );
            } else if (self.bet.money.toString().trim() == '') {
                self.showAlert(
                    'Atenção!', 
                    'Informe o valor da aposta!'
                );
            } else if (self.bet.premium == 0) {
                self.showAlert(
                    'Atenção!', 
                    'O valor do prêmio está zerado!'
                );
            } else if (parseFloat(self.bet.money) < parseFloat(min_valor)) {
                self.showAlert(
                    'Atenção!', 
                    `O valor mínimo para realizar apostas é de R$ ${self.formatCoin(min_valor)}`
                );
            } else if (!self.checkBetBuilderIsValid()) {
                self.showAlert(
                    'Atenção!', 
                    `Para "<strong>Criar aposta</strong>" é obrigatório seguir as regras de seleção mínima e máxima de cada jogo!<hr/>Mínimo de palpites: <strong>${self.betBuilderMinGamesSelected}</strong><br>Máximo de palpites: <strong>${self.betBuilderMaxGamesSelected}</strong><hr/>Mínimo de seleção: <strong>${self.betBuilderMinSelected}</strong><br>Máximo de seleção: <strong>${self.betBuilderMaxSelected}</strong>`
                );
            } else {
                
                self.isLoading = true;

                api.post(server_url, {
                    app_version: self.app_version,
                    cashback: self.cashback,
                    money: self.bet.money,
                    premium: self.bet.premium,
                    punter: self.bet.punter,
                    accept: self.bet.accept,
                    hunches: self.hunches,
                    platform: self.platform,
                    geolocation: self.geolocation,
                    pre_bet_code: self.pre_bet_code,
                    accumulated_id: self.accumulated_id
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                                
                        self.setPunter(self.bet.punter);

                        self.clearBet();
                        self.bet.accept = false;

                        switch(response.data.logged) {
                            case true:
                                self.current_credit = self.formatCoin(response.data.credit);
                                self.current_credit_simple = self.formatCoin(response.data.credit_simple);
                                self.configs.comissao_aposta_premiada = parseInt(response.data.winning_bet_commission);

                                if (self.isCordova || self.platform == 'desktop')
                                {
                                    self.betData = response.data.bet;

                                    self.$modal.hide('ticket');
                                    self.$modal.show('conclude-bet');
                                }
                                else 
                                {
                                    self.$dialogs.confirm('Você deseja compartilhar o comprovante?', {
                                        title: 'Aposta concluída!',
                                        cancelLabel: 'Não',
                                        okLabel: 'Sim',
                                        size: 'sm'
                                    }).then(res => {
                                        if (res.ok) {
                                            self.shareBet(response.data.bet.code, 0, response.data.bet.value, response.data.bet.premium, response.data.bet.punter);
                                        }
                                    });                                     
                                }
                            break;
                            default:
                            self.$dialogs.confirm(`
                                <div class="alert alert-secondary mb-0" role="alert">
                                    <h4 class="alert-heading text-center">
                                        <strong class="text-uppercase">${response.data.code}</strong>
                                    </h4>
                                    <p>Procure uma banca mais próxima para validar sua aposta!</p>
                                    <hr>
                                    <p>Sua pré-aposta irá expirar: <strong>${self.formatDate(response.data.expires)} às ${self.formatHour(response.data.expires)}</strong>.</p>
                                    <hr>
                                    <p class="mb-0">As cotações escolhidas estão sujeitas a alterações, podendo ocorrer variações no valor do prêmio.</p>
                                </div>
                            `,{
                                title: 'Código da pré-aposta',
                                cancelLabel: 'Fechar',
                                okLabel: 'Compartilhar',
                                size: 'sm'
                            }).then(res => {
                                if (res.ok) {
                                    if (self.platform == 'desktop') {
                                        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(`Código da minha pré-aposta: ${response.data.code} \r\n\r\nClique no link abaixo para validar a pré-aposta rapidamente!\r\n\r\n${self.site}/prebet/${response.data.code}`)}`,'_blank');
                                    } else {
                                        window.location.href = `whatsapp://send?text=${encodeURIComponent(`Código da minha pré-aposta: ${response.data.code} \r\n\r\nClique no link abaixo para validar a pré-aposta rapidamente!\r\n\r\n${self.site}/prebet/${response.data.code}`)}`;
                                    }
                                }
                            });
                            break;
                        }
                        break;
                        case 'premium_changed':
                            self.$dialogs.confirm(`
                                <div class="alert alert-warning" role="alert">
                                    <h4 class="alert-heading text-center">
                                        <strong>Mudança de prêmio!</strong>
                                    </h4>
                                    <p>As cotações mudaram, e seu prêmio foi recalculado para R$ ${response.data.premium}.</p>
                                    <hr />
                                    <p>Deseja continuar?</p>
                                </div>
                            `,{
                                title: 'Atenção!',
                                cancelLabel: 'Não',
                                okLabel: 'Sim',
                                size: 'sm'
                            }).then(res => {
                                if (res.ok) {
                                    self.isLoading = true;
                                    self.bet.accept = true;
                                    if (self.checkIsLive() && self.isLogged) {
                                        self.check_conclude_bet_is_live = true;
                                        setTimeout(() => self.concludeBet(), self.delay * 1000);
                                    } else {
                                        self.concludeBet();
                                    }
                                }
                            });
                        break;
                        default:
                            self.showAlert(
                                'Atenção!', 
                                self.convertSpace(response.data.message)
                            );
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.showAlert(
                            'Falha!', 
                            self.network_erros[error.status]['message']
                        );
                    } catch(e) {
                        self.showAlert(
                            'Falha!', 
                            self.network_erros[408]['message']
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                    self.check_conclude_bet_is_live = false;
                });
            }
        },
        dialogShare() {
            if (!this.isCordova || this.platform == 'desktop') {
                this.shareBet(this.betData.code, 0, this.betData.value, this.betData.premium, this.betData.punter);
            } else {
                this.shareImage(this.betData);
            }
            this.$modal.hide('conclude-bet');
        },
        dialogPrint() {
            if (!this.isCordova || this.platform == 'desktop') {
                this.printDesktop(this.betData, this.configs);
            } else {
                this.printCommand(1, this.betData);
            }
            this.$modal.hide('conclude-bet');
        },
        printCommand(via = 1, data) {
            this.printDirectApp(via, data, this.site, this.configs);
        },
        shareImage(data) {
            this.shareDirectApp(data, this.site, this.configs);
        },
        checkIsLive() {

            var live = false;

            for (var key in this.hunches) {
                if (this.hunches[key].live) {
                    live = true;
                    break;
                }
            }

            return live;
        },
        searchPreBet(code) {

            const self = this;

            self.clearURLHash();
            self.isLoading = true;

            api.get(`prebet/${code}`, {
                headers: {
                    'X-RRR-UE': self.ue
                }
            }).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.openBet();
                        self.clearBet();
                        self.pre_bet_code = code;
                        self.cashback.cel = response.data.bet.cashback.cel;
                        self.cashback.nome = response.data.bet.cashback.nome;
                        self.bet.money = parseFloat(response.data.bet.valor);
                        self.bet.punter = String(response.data.bet.apostador);
                        self.hunches = response.data.bet.palpites;
                        self.calcPremium();
                    break;
                    case 'not_authorized':
                        self.$dialogs.confirm(`O sistema não autorizou a validação dessa aposta, tente novamente!`,{
                            title: 'Não autorizado!',
                            cancelLabel: 'Cancelar',
                            okLabel: 'Tentar novamente',
                            size: 'sm'
                        }).then(res => {
                            if (res.ok) {
                                window.location.reload();
                            }
                        });
                    break;
                    case 'nothing_found':
                        self.showAlert(
                            'Atenção!', 
                            'A pré-aposta informada não foi encontrada!'
                        );
                    break;
                    default:
                        self.showAlert(
                            'Atenção!', 
                            response.data.message
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    self.showAlert(
                        'Falha!', 
                        self.network_erros[error.status]['message']
                    );
                } catch(e) {
                    self.showAlert(
                        'Falha!', 
                        self.network_erros[408]['message']
                    );
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        async selectEvent(id, option, live, score, player, corners, cards, points, odd) {

            const self = this;
            const info = await self.getInfo(id);
            const index = self.events.findIndex((item) => item.id == id);
            const max_palpites = live ? self.configs.max_jogos_ao_vivo : self.configs.max_jogos;
            const key = self.betBuilderActive ? `${id}_${self.getOptionMarketName(option)}` : id;

            if (index != -1) {

                const event = self.events[index];

                if (typeof(self.hunches[key]) == 'undefined') {
                    
                    if (self.selectedEventsCount() < max_palpites) {

                        self.$set(self.hunches, key, {
                            id: id,
                            odd: odd,
                            info: info,
                            live: live,
                            cards: cards,
                            score: score,
                            status: 'n',
                            points: points,
                            player: player,
                            option: option,
                            corners: corners,
                            accumulated: false,
                            league: event.liga,
                            sport: event.esporte,
                            schedule: event.horario,
                            team_home: event.time_casa,
                            team_away: event.time_fora,
                            team_home_id: event.time_casa_id,
                            team_away_id: event.time_fora_id,
                            betbuilder: self.betBuilderActive,
                            market: self.findMarket(id, option, event.esporte, score, player, corners, cards, points, false, event)
                        });
                    }
                    else
                    {
                        self.showAlert(
                            'Atenção!', 
                            `Selecione no máximo ${max_palpites} palpites ${live ? 'ao vivo por bilhete!' : 'por bilhete!'}`
                        );
                    }
                } 
                else if (option == 27 || option == 155) // Para opção de placar exato
                {
                    if (self.hunches[key].score == score) 
                    {
                        self.$delete(self.hunches, key);
                    }
                    else 
                    {
                        self.hunches[key]['odd'] = odd;
                        self.hunches[key]['info'] = info;
                        self.hunches[key]['cards'] = cards;
                        self.hunches[key]['score'] = score;
                        self.hunches[key]['points'] = points;
                        self.hunches[key]['player'] = player;
                        self.hunches[key]['option'] = option;
                        self.hunches[key]['corners'] = corners;
                        self.hunches[key]['market'] = self.findMarket(id, option, event.esporte, score, player, corners, cards, points, false, event);
                    }
                }
                else if (option == 98 || option == 99 || option == 236) // Para opção de jogador
                {
                    if (self.hunches[key].option == option && self.hunches[key].player == player) 
                    {
                        self.$delete(self.hunches, key);
                    }
                    else 
                    {
                        self.hunches[key]['odd'] = odd;
                        self.hunches[key]['info'] = info;
                        self.hunches[key]['cards'] = cards;
                        self.hunches[key]['score'] = score;
                        self.hunches[key]['points'] = points;
                        self.hunches[key]['player'] = player;
                        self.hunches[key]['option'] = option;
                        self.hunches[key]['corners'] = corners;
                        self.hunches[key]['market'] = self.findMarket(id, option, event.esporte, score, player, corners, cards, points, false, event);
                    }
                }
                else if (option == 100 || option == 101 || option == 102) // Para opção de escanteios
                {
                    if (self.hunches[key].option == option && self.hunches[key].corners == corners) 
                    {
                        self.$delete(self.hunches, key);
                    }
                    else 
                    {
                        self.hunches[key]['odd'] = odd;
                        self.hunches[key]['info'] = info;
                        self.hunches[key]['cards'] = cards;
                        self.hunches[key]['score'] = score;
                        self.hunches[key]['points'] = points;
                        self.hunches[key]['player'] = player;
                        self.hunches[key]['option'] = option;
                        self.hunches[key]['corners'] = corners;
                        self.hunches[key]['market'] = self.findMarket(id, option, event.esporte, score, player, corners, cards, points, false, event);
                    }
                }
                else if (option == 123 || option == 124 || option == 125 || option == 126 || option == 127 || option == 128) // Para opção de cartões
                {
                    if (self.hunches[key].option == option && self.hunches[key].cards == cards) 
                    {
                        self.$delete(self.hunches, key);
                    }
                    else 
                    {
                        self.hunches[key]['odd'] = odd;
                        self.hunches[key]['info'] = info;
                        self.hunches[key]['cards'] = cards;
                        self.hunches[key]['score'] = score;
                        self.hunches[key]['points'] = points;
                        self.hunches[key]['player'] = player;
                        self.hunches[key]['option'] = option;
                        self.hunches[key]['corners'] = corners;
                        self.hunches[key]['market'] = self.findMarket(id, option, event.esporte, score, player, corners, cards, points, false, event);
                    }
                }
                else if (option == 215 || option == 216 || option == 218 || option == 219 || option == 220) // Para a opção de total de pontos (basquete)
                {
                    if (self.hunches[key].option == option && self.hunches[key].points == points)
                    {
                        self.$delete(self.hunches, key);
                    }
                    else
                    {
                        self.hunches[key]['odd'] = odd;
                        self.hunches[key]['info'] = info;
                        self.hunches[key]['cards'] = cards;
                        self.hunches[key]['score'] = score;
                        self.hunches[key]['points'] = points;
                        self.hunches[key]['player'] = player;
                        self.hunches[key]['option'] = option;
                        self.hunches[key]['corners'] = corners;
                        self.hunches[key]['market'] = self.findMarket(id, option, event.esporte, score, player, corners, cards, points, false, event);
                    }
                }
                else if (self.hunches[key].option == option) 
                {
                    self.$delete(self.hunches, key);
                } 
                else 
                {
                    self.hunches[key]['odd'] = odd;
                    self.hunches[key]['info'] = info;
                    self.hunches[key]['cards'] = cards;
                    self.hunches[key]['score'] = score;
                    self.hunches[key]['points'] = points;
                    self.hunches[key]['player'] = player;
                    self.hunches[key]['option'] = option;
                    self.hunches[key]['corners'] = corners;
                    self.hunches[key]['market'] = self.findMarket(id, option, event.esporte, score, player, corners, cards, points, false, event);
                }

                // Fecha o modal caso esteja aberto
                if (self.modal_opened && !self.betBuilderActive) {
                    self.$modal.hide('odds');
                }

                // Checa se CRIAR APOSTA está ativo
                if (self.betBuilderActive) {

                    // Verifica se as opções selecionadas são válidas
                    if (!self.checkBetBuilderIsValidOptions(id, option)) {
                        self.$delete(self.hunches, key);
                        self.showAlert(
                            'Atenção!', 
                            'Essa combinação não pode ser feita, escolha outra opção!'
                        ); 
                    }

                    // Verifica o máximo de seleções por jogo (CRIAR APOSTA)
                    if (self.selectedEventsBetBuilderCount(id) > self.betBuilderMaxSelected) {
                        self.$delete(self.hunches, key);
                        self.showAlert(
                            'Atenção!', 
                            `Você só pode fazer ${self.betBuilderMaxSelected} seleções por jogo.`
                        );  
                    }
                }

                self.calcPremium();
            }
            else
            {
                self.showAlert(
                    'Atenção!', 
                    'Esse evento não está mais disponível!'
                );                
            }
        },
        getOptionMarketName(optionId) {

            let optionKey = '_NOT_OPTION_ID';

            switch(optionId) {
                case 1:
                case 2:
                case 3:
                    optionKey = 'vencedor';
                break;
                case 4:
                case 5:
                case 6:
                    optionKey = 'dupla_possibilidade';
                break
                case 11:
                case 12:
                    optionKey = 'ambas'
                break;
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 182:
                case 183:
                case 184:
                case 185:
                case 186:
                case 187:
                    optionKey = 'partida_total_de_gols';
                break;
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                    optionKey = 'condicoes';
                break;
                case 25:
                case 26:
                    optionKey = 'placar_par_ou_impar';
                break;
                case 27:
                    optionKey = 'placar_exato';
                break;
                case 71:
                case 72:
                case 73:
                case 74:
                case 75:
                case 76:
                case 77:
                case 78:
                    optionKey = 'total_de_gols_exatos_na_partida'
                break;
                case 79:
                case 80:
                case 81:
                case 82:
                    optionKey = 'ambas_marcam_1_e_2_tempo';
                break;
                case 83:
                case 84:
                case 85:
                    optionKey = 'tempo_com_mais_gols';
                break;
                case 86:
                case 87:
                case 88:
                case 89:
                case 90:
                case 91:
                case 92:
                case 93:
                    optionKey = 'total_de_gols_exatos_equipe';
                break;
                case 94:
                case 95:
                case 96:
                case 97:
                    optionKey = 'equipe_gol_par_ou_impar';
                break;
                case 98:
                case 99:
                case 236:
                    optionKey = 'jogador';
                break;
                case 100:
                case 101:
                case 102:
                    optionKey = 'escanteios';
                break;
                case 123:
                case 124:
                case 125:
                case 126:
                case 127:
                case 128:
                    optionKey = 'cartoes';
                break;
                default:
                    optionKey = '_NOT_OPTION_ID';
                break;
            }

            return optionKey;
        },
        async getInfo(id) {
            if (typeof(this.dbo[id]) == 'undefined') {
                return {
                    time: 0,
                    score: '0-0',
                    period: '90'
                };
            } else {
                return {
                    time: this.dbo[id].info.tempo,
                    score: this.dbo[id].info.placar,
                    period: this.dbo[id].info.periodo
                };
            }
        },
        selectChallenge(id, option, odd) {

            const self = this;
            const index = self.challenges.findIndex((item) => item.id == id);

            if (index != -1) {

                const event = self.challenges[index];
                const challenge_option = event.odds.find((item) => item.id == option).option;

                if (typeof(self.hunches[id]) == 'undefined') {

                    if (self.selectedEventsCount() < self.configs.max_jogos) {

                        self.$set(self.hunches, id, {
                            id: id,
                            odd: odd,
                            info: {
                                time: 0,
                                score: '0-0',
                                period: '90'
                            },
                            status: 'n',
                            live: false,
                            cards: false,
                            score: false,
                            player: false,
                            points: false,
                            option: option,
                            corners: false,
                            betbuilder: false,
                            accumulated: false,
                            league: event.titulo,
                            sport: event.esporte,
                            schedule: event.horario,
                            team_home: event.descricao,
                            team_away: challenge_option,
                            market: self.findMarket(id, option, event.esporte, false, false, false, false, false, challenge_option, event)
                        });
                    }
                    else
                    {
                        self.showAlert(
                            'Atenção!', 
                            `Selecione no máximo ${self.configs.max_jogos} palpites por bilhete!`
                        );
                    }
                } 
                else if (self.hunches[id].option == option) 
                {
                    self.$delete(self.hunches, id);
                } 
                else 
                {
                    self.hunches[id]['odd'] = odd;
                    self.hunches[id]['option'] = option;
                    self.hunches[id]['team_home'] = event.descricao;
                    self.hunches[id]['team_away'] = challenge_option;
                    self.hunches[id]['market'] = self.findMarket(id, option, event.esporte, false, false, false, false, false, challenge_option, event);
                }

                self.calcPremium();
            }
            else
            {
                self.showAlert(
                    'Atenção!', 
                    'Esse evento não está mais disponível!'
                );                
            }
        },
        printTable() {

            const events = [];

            if (this.selectedPrintList.length == 0) {
                this.showAlert(
                    'Atenção!', 
                    'Selecione pelo menos um campeonato!'
                );
            } else {

                this.events.forEach((item) => {
                    
                    if (this.selectedPrintList.includes(item.liga_id)) {

                        events.push({
                            liga: item.liga,
                            esporte: item.esporte,
                            time_casa: item.time_casa,
                            time_fora: item.time_fora,
                            horario: item.horario,
                            odds: {
                                casa: item.odds.casa,
                                empate: item.odds.empate,
                                visitante: item.odds.visitante,
                                total_de_gols_acima_2_5: item.odds.extra.total_de_gols.acima_2_5,
                                ambas_equipes_marcam_sim: item.odds.extra.ambas_equipes_marcam.sim
                            }
                        })
                    }
                });

                if (events.length == 0) {
                    this.showAlert(
                        'Atenção!', 
                        'Nenhum evento disponível para a impressão!'
                    );
                } else if (this.isCordova) {

                    this.selectedAll = false;
                    this.selectedPrintList = [];

                    window.location.href = 'betsnet://print-table?data=' + window.btoa(unescape(encodeURIComponent(JSON.stringify({
                        events: events,
                        site: this.site,
                        configs: this.configs
                    }))));
                } else {

                    let html = ``;
                    let anteior_value = 0;

                    this.selectedAll = false;
                    this.selectedPrintList = [];

                    events.forEach((item) => {

                        if (anteior_value != item.liga) {
                            html += `
                                <tr>
                                    <td colspan="12">
                                        <div class="separator"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="12" align="center" class="bg">
                                        <span>${item.liga}</span>
                                    </td>
                                </tr>
                            `;
                        }

                        html += `
                            <tr>
                                <td align="left" colspan="8">${item.time_casa}</td>
                                <td align="right" colspan="4">${this.formatHour(item.horario)}</td>
                            </tr>
                            <tr>
                                <td align="left" colspan="8">${item.time_fora}</td>
                                <td align="right" colspan="4">${this.findModality(item.esporte)}</td>
                            </tr>
                            <tr>
                                <td align="center" colspan="4" class="bg no-border">CASA <div>${item.odds.casa}</div></td>
                                <td align="center" colspan="4" class="bg no-border">EMPATE <div>${item.odds.empate}</div></td>
                                <td align="center" colspan="4" class="bg no-border">FORA <div>${item.odds.visitante}</div></td>
                            </tr>
                        `;
                        anteior_value = item.liga
                    });

                    let template = `
                        <html>
                            <head>
                                <style>*{margin:0;padding:0}body,html,table{font-size:12px;font-family:"Andale Mono",AndaleMono,monospace}.print-table{margin:1em .2em}.print-table .site{font-size:24px;font-weight:700;text-transform:uppercase;text-align:center;margin-bottom:.5em}.print-table .site img{filter: brightness(0%)}.print-table table{width:100%;border:none}.print-table table td{padding:2px}.print-table table tr td .separator{margin:.5em}.print-table table tr td.border{border:1px solid #000}.print-table table tr td.bg{color:#fff;background-color:#000;text-transform:uppercase}.print-table table tr td.no-border{border:none!important}.print-table table tr td.no-border div{background-color:#fff;color:#000}</style>
                            </head>
                            <body>
                                <div class="print-table">
                                    <div class="site">
                                        <img src="${this.logo}" border="0" width="200" alt="${this.configs.nome}">
                                    </div>
                                    <table cellpadding="0" cellspacing="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td colspan="12" align="center" class="border">${this.formatDateDay(today)}</td>
                                            </tr>
                                            ${html}
                                            <tr>
                                                <td colspan="12">
                                                    <div class="separator">
                                                        <center>
                                                            <div>Mais jogos em:</div>
                                                            <div>${['www', window.location.host].join('.')}</div>
                                                        </center>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </body>
                        </html>
                    `;
                    
                    this.printData(template);
                }
            }
        },
        optionIsEnabled(option_id, live) {
            try {
                const market = live ? this.liveOptionsStructure[option_id] : this.dbPregameMarkets[option_id];
                if (live) {
                    return String(market.ativo) == '1' ? true : false; 
                } else {
                    return String(market.active) == 'true' ? true : false;
                }
            } catch(e) {
                return false;
            }
        },
        findMarket(id, option_id, sport, score, player, corners, cards, points, answer, event) {

            let market = 'Opção não disponível';
            let categories = {'90': '(90)', 'pt': '(PT)', 'st': '(ST)'};
            
            try {

                const odds = this.dbPregameMarkets[option_id];

                if (odds instanceof Object) {

                    switch(option_id) {
                        // Placar exato
                        case 27: 
                        case 155:
                            market = `${(odds.cat == 'pt' || odds.cat == 'st') ? categories[odds.cat] : ''} ${odds.label}: ${score}`;
                        break; 
                        // Jogadores
                        case 98: 
                        case 99:
                        case 236:
                            market = [odds.label, player].join(': ');
                        break; 
                        // Escanteios (90)
                        case 100: 
                        case 101:
                        case 102:
                            market = [odds.label, corners].join(odds.label.includes('Exatos') ? ': ' : ' de ');
                        break; 
                        // Escanteios (PT)
                        case 237:
                        case 238:
                        case 239:
                            market = [`(PT) ${odds.label}`, corners].join(odds.label.includes('Exatos') ? ': ' : ' de ');
                        break;
                        // Cartões
                        case 123: 
                        case 124: 
                            market = [odds.label, cards].join(' de ');
                        break; 
                        case 125:
                        case 126:
                        case 127:
                        case 128:
                            market = [odds.label.toString().replace(new RegExp('casa', 'g'), event.time_casa).replace(new RegExp('Casa', 'g'), event.time_casa).replace(new RegExp('fora', 'g'), event.time_fora).replace(new RegExp('Fora', 'g'), event.time_fora), cards].join(' de ');
                        break;
                        // Pontos basquete
                        case 215: 
                        case 216:
                        case 217:
                        case 218:
                        case 219:
                        case 220:
                            market = [odds.label, points].join(' de ');
                        break;
                        // Restante das opções
                        default:
                            if (sport == 8) {
                                market = `Resposta: ${answer}`;
                            } else if (option_id == 1) {
                                market = `Vencedor: ${event.time_casa}`;
                            } else if (option_id == 3) {
                                market = `Vencedor: ${event.time_fora}`;
                            } else if (option_id == 129) {
                                market = `(PT) Vencedor: ${event.time_casa}`;
                            } else if (option_id == 131) {
                                market = `(PT) Vencedor: ${event.time_fora}`;
                            } else if (option_id == 156) {
                                market = `(ST) Vencedor: ${event.time_casa}`;
                            } else if (option_id == 158) {
                                market = `(ST) Vencedor: ${event.time_fora}`;
                            } else if (odds.label.toString().toLowerCase().includes('casa') || odds.label.toString().toLowerCase().includes('fora')) {
                                market = `${(odds.cat == 'pt' || odds.cat == 'st') ? categories[odds.cat] : ''} ${odds.label.toString().replace(new RegExp('casa', 'g'), event.time_casa).replace(new RegExp('Casa', 'g'), event.time_casa).replace(new RegExp('fora', 'g'), event.time_fora).replace(new RegExp('Fora', 'g'), event.time_fora)}`.toString().trim();
                            } else {
                                market = `${(odds.cat == 'pt' || odds.cat == 'st') ? categories[odds.cat] : ''} ${odds.label}`.toString().trim();
                            }
                        break;
                    }

                    return market;
                } else {
                    return market;
                }
            } catch(e) {
                return market;
            }
        },
        removeEvent(id, key = 0) {
            if (id == key || key == 0) {
                if (this.hunches[id] != undefined) {
                    let hun = this.hunches[id];
                    if (hun.betbuilder) {
                        this.betBuilderRemoveEvent(hun.id);
                    } else {
                        this.$delete(this.hunches, id);
                    }
                }
            } else {
                this.$delete(this.hunches, key);
            }
            this.calcPremium();
        },
        betBuilderRemoveEvent(id) {
            for (let key in this.hunches) {
                let hun = this.hunches[key];
                if (hun.betbuilder && hun.id == id) {
                    this.$delete(this.hunches, key);
                }
            }
            this.calcPremium();
        },
        selectedEvents(id, option, score, player, corners, cards, points = false) {
            try {

                let selected = false;
                let hunches = this.hunches;

                if (this.selectedEventsCount() > 0) {

                    for (let key in hunches) {
                        let hun = hunches[key];

                        if (hun.id == id && hun.option == option && hun.score == score && hun.player == player && hun.corners == corners && hun.cards == cards && hun.points == points) {
                            selected = true;
                            break;
                        }
                    }
                }

                return selected;
            } 
            catch(e) 
            {
                return false;
            }
        },
        selectedEventsMore(id) {
            try {

                let selected = false;
                let blacklist = [1, 2, 3];
                let hunches = this.hunches;

                if (this.selectedEventsCount() > 0) {
                    
                    for (let key in hunches) {
                        let hun = hunches[key];

                        if (hun.id == id) {

                            if (!blacklist.includes(hun.option)) {
                                selected = true;
                                break;
                            }
                        }
                    }
                }

                return selected;
            }
            catch(e)
            {
                return false;
            }
        },
        selectedEventsCount() {
            return this.countTotalHunches(this.hunches);
        },
        selectedEventsCountLive() {
            let total = 0;
            for (let key in this.hunches) {
                let hun = this.hunches[key];
                if (hun.live) {
                    total++;
                }
            }
            return total;
        },
        selectedEventsBetBuilderCount(id) {
            let total = 0;
            for (let key in this.hunches) {
                let hun = this.hunches[key];
                if (hun.id == id && hun.betbuilder) {
                    total++;
                }
            }
            return total;
        },
        selectedEventsNotBetBuilderCount(id, ao_vivo) {
            let total = 0;
            
            for (let key in this.hunches) {
                let hun = this.hunches[key];
                if (hun.id == id && !hun.betbuilder) {
                    total++;
                }
            }

            if (ao_vivo && !this.betBuilderLive) {
                total = 1;
            }

            return total;
        },
        checkBetBuilderIsValid() {
            
            let total = 0;
            let items = {};
            let isValid = true;

            for (let key in this.hunches) {
                let hun = this.hunches[key];

                if (hun.betbuilder) {

                    if (items[hun.id] == undefined) {
                        items[hun.id] = {
                            selectedCount: 0
                        }
                    }

                    items[hun.id].selectedCount += 1;

                    total++;
                }
            }

            if (total) {

                let totalItems = Object.keys(items).length;

                for (let key in items) {
                    let hun = items[key];
                    if (hun.selectedCount < this.betBuilderMinSelected || hun.selectedCount > this.betBuilderMaxSelected) {
                        isValid = false;
                        break;
                    }
                }
            
                if (totalItems < this.betBuilderMinGamesSelected || totalItems > this.betBuilderMaxGamesSelected) {
                    isValid = false;
                }
            }

            return isValid;
        },
        checkBetBuilderIsValidOptions(id, option) {

            let isValid = true;
            let optionsList = { 
                plcar_impar_par_time: [11, 12, 21, 22, 82, 85, 86, 87, 88, 89, 90, 91, 92, 93],
                placar_impar_par: [11, 12, 21, 22, 27, 71, 72, 73, 74, 75, 76, 77, 78, 82, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97],
                placar: [13, 14, 15, 16, 17, 18, 71, 72, 73, 74, 75, 76, 77, 78, 86, 87, 88, 89, 90, 91, 182, 183, 184, 185, 186, 187],
                total_de_gols_exatos: [25, 26, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97],
                total_de_gols_acima_abaixo_placar: [27],
                total_de_gols_acima_abaixo: [71, 72, 73, 74, 75, 76, 77, 78, 86, 87, 88, 89, 90, 91, 92, 93],
                condicoes: [19, 20, 21, 22, 23, 24],
                dupla_possibilidade: [4, 5, 6],
                ambas_equipes_marcam: [71, 72, 73, 74, 75, 76, 77, 78, 86, 87, 88, 89, 90, 91, 92, 93],
                ambas_marcam_pt_st: [79, 80, 81, 82],
                tempo_com_mais_gols: [12, 82],
                condicoes_tempo_com_mais_gols: [83, 84, 85],
                principais_com_placar: [27]
            };

            for (let key in this.hunches) {
                let hun = this.hunches[key];
                if (hun.id == id && hun.betbuilder) {
                    // Mercado principais com condições
                    if ((hun.option == 1 || hun.option == 2 || hun.option == 3) && optionsList.condicoes.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado condições com principais
                    if (optionsList.condicoes.includes(hun.option) && (option == 1 || option == 2 || option == 3)) {
                        isValid = false;
                        break;
                    }
                    // Mercado principais com dupla possibilidade
                    if ((hun.option == 1 || hun.option == 2 || hun.option == 3) && optionsList.dupla_possibilidade.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado dupla possibilidade com principais
                    if (optionsList.dupla_possibilidade.includes(hun.option) && (option == 1 || option == 2 || option == 3)) {
                        isValid = false;
                        break;
                    }
                    // Mercado ambas com condições
                    if ((hun.option == 11 || hun.option == 12) && optionsList.condicoes.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado condições com ambas
                    if (optionsList.condicoes.includes(hun.option) && (option == 11 || option == 12)) {
                        isValid = false;
                        break;
                    }
                    // Mercado principais com placar
                    if ((hun.option == 1 || hun.option == 2 || hun.option == 3 || hun.option == 4 || hun.option == 5 || hun.option == 6) && optionsList.principais_com_placar.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado placar com principais
                    if (optionsList.principais_com_placar.includes(hun.option) && (option == 1 || option == 2 || option == 3 || option == 4 || option == 5 || option == 6)) {
                        isValid = false;
                        break;
                    }
                    // Mercado ambas pt e st com placar
                    if ((hun.option == 79 || hun.option == 80 || hun.option == 81 || hun.option == 82) && optionsList.principais_com_placar.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado placar com ambas pt e st
                    if (optionsList.principais_com_placar.includes(hun.option) && (option == 79 || option == 80 || option == 81 || option == 82)) {
                        isValid = false;
                        break;
                    }
                    // Mercado placar com condições
                    if ((hun.option == 27) && optionsList.condicoes.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado condições com placar
                    if (optionsList.condicoes.includes(hun.option) && (option == 27)) {
                        isValid = false;
                        break;
                    }
                    // Mercado ambas com ambas pt e st
                    if ((hun.option == 11 || hun.option == 12) && optionsList.ambas_marcam_pt_st.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado ambas pt e st com ambas
                    if (optionsList.ambas_marcam_pt_st.includes(hun.option) && (option == 11 || option == 12)) {
                        isValid = false;
                        break;
                    }
                    // Mercado ambas com total de gols exato/time
                    if ((hun.option == 11 || hun.option == 12) && optionsList.ambas_equipes_marcam.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado total de gols exato/time com ambas
                    if (optionsList.ambas_equipes_marcam.includes(hun.option) && (option == 11 || option == 12)) {
                        isValid = false;
                        break;
                    }
                    // Mercado dupla possibilidade com condições
                    if ((hun.option == 4 || hun.option == 5 || hun.option == 6) && optionsList.condicoes.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado condições com possibilidade
                    if (optionsList.condicoes.includes(hun.option) && (option == 4 || option == 5 || option == 6)) {
                        isValid = false;
                        break;
                    }
                    // Mercado partida total de gols acima/abaixo com total de gols exato na partida/time
                    if ((hun.option == 13 || hun.option == 14 || hun.option == 15 || hun.option == 16 || hun.option == 17 || hun.option == 18 || hun.option == 182 || hun.option == 183 || hun.option == 184 || hun.option == 185 || hun.option == 186 || hun.option == 187) && optionsList.total_de_gols_acima_abaixo.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado total de gols exato na partida/time com partida total de gols acima/abaixo
                    if (optionsList.total_de_gols_acima_abaixo.includes(hun.option) && (option == 13 || option == 14 || option == 15 || option == 16 || option == 17 || option == 18 || option == 182 || option == 183 || option == 184 || option == 185 || option == 186 || option == 187)) {
                        isValid = false;
                        break;
                    }
                    // Mercado partida total de gols acima/abaixo com placar
                    if ((hun.option == 27) && optionsList.placar.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado placar com partida total de gols acima/abaixo
                    if (optionsList.placar.includes(hun.option) && (option == 27)) {
                        isValid = false;
                        break;
                    }
                    // Mercado total de gols exatos partida com total de gols exatos time
                    if ((hun.option == 71 || hun.option == 72 || hun.option == 73 || hun.option == 74 || hun.option == 75 || hun.option == 76 || hun.option == 77 || hun.option == 78) && optionsList.total_de_gols_exatos.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado total de gols exatos time com total de gols exatos partida
                    if (optionsList.total_de_gols_exatos.includes(hun.option) && (option == 71 || option == 72 || option == 73 || option == 74 || option == 75 || option == 76 || option == 77 || option == 78)) {
                        isValid = false;
                        break;
                    }
                    // Mercado placar impar/par com placar exato e condições
                    if ((hun.option == 25 || hun.option == 26) && optionsList.placar_impar_par.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado placar exato e condições com impar/par
                    if (optionsList.placar_impar_par.includes(hun.option) && (option == 25 || option == 26)) {
                        isValid = false;
                        break;
                    }
                    // Mercado impar/par time com total de gols exatos time
                    if ((hun.option == 94 || hun.option == 95 || hun.option == 96 || hun.option == 97) && optionsList.plcar_impar_par_time.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado total de gols exatos time com impar/par time
                    if (optionsList.plcar_impar_par_time.includes(hun.option) && (option == 94 || option == 95 || option == 96 || option == 97)) {
                        isValid = false;
                        break;
                    }
                    // Mercado tempo com mais gols com ambas não
                    if ((hun.option == 85) && optionsList.tempo_com_mais_gols.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado ambas não com tempo com mais gols
                    if (optionsList.tempo_com_mais_gols.includes(hun.option) && (option == 85)) {
                        isValid = false;
                        break;
                    }
                    // Mercado tempo com mais gols com condições
                    if ((hun.option == 19 || hun.option == 20 || hun.option == 21 || hun.option == 22 || hun.option == 23 || hun.option == 24) && optionsList.condicoes_tempo_com_mais_gols.includes(option)) {
                        isValid = false;
                        break;
                    }
                    // Mercado condições com tempo com mais gols
                    if (optionsList.condicoes_tempo_com_mais_gols.includes(hun.option) && (option == 19 || option == 20 || option == 21 || option == 22 || option == 23 || option == 24)) {
                        isValid = false;
                        break;
                    }
                }
            }

            return isValid;
        },
        beforeOpen(event) {

            this.modal_opened = true;

            try {

                let cat = '90';
                let hunches = this.hunches;

                if (this.selectedEventsCount() > 0) {

                    for (let key in hunches) {
                        let hun = hunches[key];
                        let market = this.dbPregameMarkets[hun.option];
                        if (market != undefined) {
                            if (hun.id == event.params.id) {
                                if (hun.betbuilder) {
                                    cat = 'bb';
                                } else {
                                    cat = market.cat;
                                }
                                break;
                            }
                        }
                    }

                    this.cat_id = cat;
                }
            } 
            catch(e) 
            {
               this.cat_id = '90'; 
            }
        },
        beforeClose() {
            this.code = '';
            this.betData = {};
            this.template = '';
            this.cat_id = '90';
            this.cat_name = '';
            this.clearHasMarkets();
            this.modal_opened = false;
            this.betBuilderActive = false;
            this.widget_field_disabled = false;
        },
        calcPremium() {

            let total = 1;
            let self = this;
            let isLive = self.checkIsLive();
            let selected = self.selectedEventsCount();
            let money = self.bet.money == '' ? 0 : self.bet.money;

            if (selected == 0) {
                self.bet.premium = 0;
                self.total_quotation = 0;
            } 
            else 
            {
                for (let key in self.hunches) {
                    total *= self.hunches[key].odd;
                }

                let premium = parseFloat((total * money)).toFixed(2) || 0;
                let max_valor = isLive ? self.configs.max_valor_ao_vivo : self.configs.max_valor;

                if (premium > parseFloat((self.configs.multiplicador * money))) {
                    premium = parseFloat((self.configs.multiplicador * money)).toFixed(2);
                }

                if (premium > parseFloat(max_valor)) {
                    premium = parseFloat(max_valor).toFixed(2);
                }

                self.total_quotation = (total > self.configs.multiplicador) ? self.configs.multiplicador : total;
                self.bet.premium = premium;
            }
        },
        clearBet() {

            const self = this;

            self.betData = {};
            self.bet.money = '';
            self.pre_bet_code = 0;
            self.accumulated_id = 0;
            self.bet.punter = self.isLogged && self.pipe == 4 ? self.user_name : self.isLogged && self.pipe == 1 ? '' : self.bet.punter;

            if (self.isLogged && self.pipe == 1) {
                self.cashback.cel = '';
                self.cashback.nome = '';
            }

            if (self.selectedEventsCount() > 0) {

                for (let key in self.hunches) {
                    let hun = self.hunches[key];
                    if (hun.betbuilder) {
                        self.$delete(self.hunches, key);
                    } else {
                        self.$delete(self.hunches, hun.id);
                    }
                }

                self.calcPremium();
            }
        },
        getEvents() {

            const self = this;
            const sport = self.sport == 'live' ? 1 : self.sport;
            const url = self.isLogged ? `events/${self.date}/${sport}/by/${self.user_id}` : `events/${self.date}/${sport}`;

            self.search = '';
            self.live = false;
            self.sport = sport;
            self.loading = true;
            self.problem = false;
            self.blockOnScroll = true;
            self.nothing_found = false;

            if (self.events.length) self.events = [];
            if (Object.keys(self.configs).length == 0) return self.loadConfigs();

            api.get(url).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.diffTime();
                        self.showPopup();
                        self.screenAdjust();
                        self.events = self.orderEvents(response.data.events);
                    break;
                    case 'nothing_found':
                        self.problem = true;
                        self.nothing_found = true;
                        self.message = 'Nenhum evento disponível';
                    break;
                    default:
                        self.problem = true;
                        self.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.problem = true;
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.problem = true;
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        nextDay() {
            this.date = moment(today, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
        },
        orderEvents(events) {
            return events.map((item) => {
                return {
                    type: 'item',
                    id: item.id,
                    ao_vivo: item.ao_vivo,
                    esporte: item.esporte,
                    liga: item.liga,
                    liga_id: item.liga_id,
                    liga_cc: item.liga_cc || 'default',
                    mercado_id: item.mercado_id,
                    time_casa: item.time_casa,
                    time_fora: item.time_fora,
                    time_casa_id: item.time_casa_id,
                    time_fora_id: item.time_fora_id,
                    hora: item.hora,
                    horario: item.horario,
                    tempo_status: item.tempo_status,
                    odds: item.odds,
                    total_odds: item.total_odds || 0,
                    destacar: this.favorites.includes(item.liga_id) ? 1 : 0
                }
            }).filter((item) => {
                return item.horario > this.current_time;
            });
        },
        getHighlights(events) {
            return events.filter((item) => item.destacar == 1).sort(() => 0.5 - Math.random()).slice(0, 5).map((item) => {
                return {
                    id: item.id,
                    liga: item.liga,
                    liga_cc: item.liga_cc,
                    horario: item.horario,
                    time_casa: item.time_casa,
                    time_fora: item.time_fora,
                    odds: {
                        casa: item.odds.casa,
                        empate: item.odds.empate,
                        visitante: item.odds.visitante
                    }
                }
            });
        },
        showPopup() {
            try {
                if (!this.isLogged && this.configs.popup_exibir == 'sim' && this.configs.popup_items.length) {
                    if (window.sessionStorage.getItem('popup') == null) {
                        this.$modal.show('modal-popup');
                    }
                }
            } catch(e) {
                // window.console.log(e)
            }
        },
        closePopup() {
            window.sessionStorage.setItem('popup', true);
        },
        getChallenges() {

            const self = this;

            self.challenges = [];
            self.isLoading = true;

            api.get('modality/challenge').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.challenges = response.data.events;
                    break;
                    case 'nothing_found':
                        self.showAlert('Atenção!', 'Nenhum desafio disponível!');
                    break;
                    default:
                        self.showAlert('Atenção!', response.data.message);
                    break;
                }
            }).catch((error) => {
                try {
                    self.showAlert('Atenção!', self.network_erros[error.status]['message']);
                } catch(e) {
                    self.showAlert('Atenção!', self.network_erros[408]['message']);
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        searchLeague(event) {
           this.search = event.target.value;
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        diffTime() {
            setInterval(() => {
                
                api.get('time').then((response) => {

                    let started = 0;
                    let events = this.events;
                    this.current_time = response.data.tz;
                    let now = moment(response.data.tz).format('HH:mm');

                    if (this.search == '') {

                        events.forEach((item, index) => {

                            let data = moment(item.horario).format('YYYY-MM-DD');

                            if (!item.ao_vivo && data == today) {

                                let prev = moment(item.horario).format('HH:mm');
                                let diff = moment(prev, 'HH:mm').diff(moment(now, 'HH:mm'));
                                let time = Math.floor(moment.duration(diff).asHours()) + moment.utc(diff).format(":mm");
                                let divider = time.split(':');
                                let hours = divider[0] < 10 ? '0' + divider[0] : divider[0];
                                let minutes = divider[1];

                                if (prev <= now) {
                                    started++;
                                } else if (hours <= 0 && minutes <= 60) {

                                    events[index].hora = [hours, minutes].join(':');

                                    if (minutes <= 5) {
                                        events[index].tempo_status = 'blink';
                                    } else {
                                        events[index].tempo_status = 'diff';
                                    }
                                }   
                            }
                        });
                        
                        if (started) {

                            this.events = this.orderEvents(events);

                            if (this.events.length == 0) {
                                this.problem = true;
                                this.loading = false;
                                this.nothing_found = true;
                                this.message = 'Nenhum evento disponível';
                            }
                        }
                        else if (this.events.length == 0)
                        {
                            this.problem = true;
                            this.loading = false;
                            this.nothing_found = true;
                            this.message = 'Nenhum evento disponível';
                        }
                    }
                });
            }, 60000);
        },
        dateTick() {
            setInterval(() => {

                let date = moment().tz('America/Recife').format('YYYY-MM-DD');

                if (today < date) {
                    today = date;
                    this.date = date;
                    this.initial_date = date;
                    this.final_date = date;
                }
            }, 60000);
        },
        changeSport(value) {
            switch(value) {
                case 'live':
                    this.live = true;
                break;
                case 'challenge':
                    this.getChallenges();
                break;
                case 'casino':
                    this.$router.push('/cassino');
                break;
                case 'store':
                    this.$router.push('/store');
                break;
                default:
                    this.live = false;
                    this.sport = value;
                    this.getEvents();
                break;
            }
        },
        inplay(eventos) {

            const self = this;

            socket.open();

            socket.on('connect_failed', () => {

                if (!self.live) return;

                self.problem = true;
                self.loading = false;
                self.nothing_found = true;
                self.message = 'Não foi possível conectar ao servidor!';
            });

            socket.on('connect_error', () => {

                if (!self.live) return;

                self.problem = true;
                self.loading = false;
                self.nothing_found = true;
                self.message = 'Ocorreu um erro na conexão com o servidor!';
            });

            socket.on('events', (snapshot) => {

                try {
                   
                    const partidas = [];
                    const {result, total, events} = JSON.parse(snapshot);

                    if (self.live && (result == 'nothing_found' || total == 0)) {
                        self.problem = true;
                        self.loading = false;
                        self.nothing_found = true;
                        self.message = 'Nenhum evento disponível';
                    } 
                    else if (self.live)
                    {
                        events.forEach((event) => {

                            let cotacoes = self.rules(event.odds.cotacoes);
                            let item = eventos.find((obj) => obj.api_id == event.id);

                            if (item instanceof Object) {

                                partidas.push({
                                    esporte: 1,
                                    type: 'item',
                                    id: item.id,
                                    ao_vivo: true,
                                    liga: item.liga,
                                    liga_id: item.liga_id,
                                    liga_cc: item.liga_cc,
                                    time_casa: item.time_casa,
                                    time_fora: item.time_fora,
                                    time_casa_id: item.time_casa_id,
                                    time_fora_id: item.time_fora_id,
                                    hora: event.timer,
                                    horario: item.horario,
                                    tempo_status: 'default',
                                    placar: event.score,
                                    periodo: event.period,
                                    mercado_id: 'principais',
                                    transmissao_id: item.transmissao_id,
                                    odds: {
                                        casa: self.oddsControl(1, event.odds.home),
                                        empate: self.oddsControl(2, event.odds.draw),
                                        visitante: self.oddsControl(3, event.odds.away),
                                        extra: {
                                            dupla_possibilidade:{
                                                casa_ou_fora: "1.00",
                                                casa_ou_empate: "1.00",
                                                fora_ou_empate: "1.00"
                                            },
                                            ambas_equipes_marcam:{
                                                sim: "1.00",
                                                nao: "1.00"
                                            },
                                            total_de_gols:{
                                                acima_2_5: "1.00",
                                                abaixo_2_5: "1.00"
                                            }
                                        }
                                    },
                                    cotacoes: cotacoes,
                                    total_odds: self.totalOdds(cotacoes),
                                    destacar: self.favorites.includes(item.liga_id) ? 1 : 0
                                });

                                if (typeof(self.dbo[item.id]) == 'undefined') {
                                    
                                    self.$set(self.dbo, item.id, {
                                        esporte: 1,
                                        id: item.id,
                                        ao_vivo: true,
                                        liga: item.liga,
                                        liga_cc: item.liga_cc,
                                        partida: {
                                            time_casa: {
                                                id: item.time_casa_id,
                                                nome: item.time_casa
                                            },
                                            time_fora: {
                                                id: item.time_fora_id,
                                                nome: item.time_fora
                                            }
                                        },
                                        info: {
                                            tempo: event.timer,
                                            placar: event.score,
                                            periodo: event.period
                                        },
                                        transmissao_id: item.transmissao_id,
                                        horario: item.horario,
                                        odds: cotacoes,
                                        total_odds: self.totalOdds(cotacoes)
                                    });
                                }
                                else
                                {
                                    self.dbo[item.id]['info']['tempo'] = event.timer;
                                    self.dbo[item.id]['info']['placar'] = event.score;
                                    self.dbo[item.id]['info']['periodo'] = event.period;
                                    self.dbo[item.id]['odds'] = event.timer >= self.configs.ao_vivo_tempo ? [] : cotacoes;
                                    self.dbo[item.id]['total_odds'] = event.timer >= self.configs.ao_vivo_tempo ? 0 : self.totalOdds(cotacoes);
                                }
                            }
                        });

                        if (partidas.length == 0) {
                            self.problem = true;
                            self.loading = false;
                            self.nothing_found = true;
                            self.message = 'Nenhum evento disponível';
                        }
                        else
                        {
                            self.problem = false;
                            self.loading = false;
                            self.nothing_found = false;
                            self.events = partidas;
                        }
                    }
                } 
                catch(e) 
                {
                    self.view = 1;
                    self.live = false;
                }
            });
        },
        totalOdds(odds) {

            let total = 0;

            for (let i in odds) {
                let item = odds[i];

                if (Array.isArray(item.odd)) {

                    for (let k in item.odd) {
                        let res = item.odd[k];

                        if (parseFloat(res.odd) > 1) {
                            total += 1;
                        }
                    }
                } else if (parseFloat(item.odd) > 1) {
                    total += 1;
                }
            }
            
            return total;
        },
        moreOdds(id) {

            const self = this;
            const url = self.isLogged ? `odds/${id}/by/${self.user_id}` : `odds/${id}`;

            self.isLoading = true;

            api.get(url).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.modal = response.data;
                        self.betBuilder = response.data.betbuilder.ativo;
                        self.betBuilderOdds = response.data.betbuilder.odds;
                        self.betBuilderLive = response.data.betbuilder.ao_vivo;
                        self.betBuilderOptions = response.data.betbuilder.mercados;
                        self.betBuilderMinSelected = parseInt(response.data.betbuilder.min_palpites);
                        self.betBuilderMaxSelected = parseInt(response.data.betbuilder.max_palpites);
                        self.betBuilderMinGamesSelected = parseInt(response.data.betbuilder.min_jogos);
                        self.betBuilderMaxGamesSelected = parseInt(response.data.betbuilder.max_jogos);
                        self.$modal.hide('ticket');
                        self.$modal.show('odds', {
                            id: id
                        });
                    break;
                    default:
                        self.showAlert(
                            'Atenção!', 
                            response.data.message
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    self.showAlert(
                        'Falha!', 
                        self.network_erros[error.status]['message']
                    );
                } catch(e) {
                    self.showAlert(
                        'Falha!', 
                        self.network_erros[408]['message']
                    );
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        moreOddsLive(id) {

            if (typeof(this.dbo[id]) == 'undefined') {
                this.showAlert(
                    'Atenção!', 
                    'Não encontramos nenhuma opção disponível!'
                );
            }
            else
            {
                this.clearHasMarkets();
                this.modal = this.dbo[id];
                this.$modal.show('odds', {
                    id: id
                });                
            }
        },
        clearHasMarkets() {
            this.has_odds_block = false;
            this.has_odds_cards = false;
            this.has_odds_corners = false;
            this.has_odds_players = false;
            this.has_odds_handicap = false;
            this.has_odds_both_score = false;
            // this.has_odds_first_half = false;
            // this.has_odds_second_half = false;
            this.has_odds_total_goals_match = false;
            this.has_odds_double_possibility = false;
        },
        oddsBetBuilderControl(optionOdd, optionId) {
            try {

                if (this.betBuilderActive) {

                    if (this.betBuilderOdds[optionId] != undefined) {

                        let percent = parseFloat(this.betBuilderOdds[optionId].percent) || 0;

                        if (percent) {

                            if (Array.isArray(optionOdd)) {

                                let odds = [];

                                for (const item of optionOdd) {
                                    let calc = (item.odd / 100) * percent;
                                    let newOdd = parseFloat(item.odd - calc);

                                    if (newOdd > 1) {

                                        if (optionId == 27 || optionId == 155) {
                                            odds.push({
                                                score: item.score,
                                                score_home: item.score_home,
                                                score_away: item.score_away,
                                                odd: parseFloat(newOdd).toFixed(2)
                                            });
                                        } else if (optionId == 98 || optionId == 99 || optionId == 236) {
                                            odds.push({
                                                player: item.player,
                                                odd: parseFloat(newOdd).toFixed(2)
                                            });
                                        } else if (optionId == 100 || optionId == 101 || optionId == 102 || optionId == 123 || optionId == 124 || optionId == 215 || optionId == 216 || optionId == 217 || optionId == 218 || optionId == 219 || optionId == 220) {
                                            odds.push({
                                                handicap: item.handicap,
                                                odd: parseFloat(newOdd).toFixed(2)
                                            });
                                        }
                                    }
                                }

                                optionOdd = odds;
                            } else {
                                let calc = (optionOdd / 100) * percent;
                                let newOdd = parseFloat(optionOdd - calc);

                                if (newOdd <= 1) {
                                    optionOdd = '1.00';
                                } else {
                                    optionOdd = parseFloat(newOdd).toFixed(2);
                                }
                            }
                        }
                    }
                }

                return optionOdd;
            } catch(e) {
                return optionOdd;
            }
        },
        oddsRender(data, cat_id, cat_name) {

            cat_id = cat_id == 'bb' ? 90 : cat_id;

            const structure = {};
            const oddsBuilder = this.betBuilderActive ? data.odds.filter(item => this.betBuilderOptions.includes(item.id)) : data.odds;
            const odds = oddsBuilder.map(item => {
                return {
                    id: item.id,
                    odd: this.oddsBetBuilderControl(item.odd, item.id),
                    option: item.option,
                    cat_id: item.cat_id,
                    cat_name: item.cat_name,
                    order: this.dbPregameMarkets[item.id].order,
                    cat_label: (item.id == 98 || item.id == 99 || item.id == 236 ? 'Jogadores' : item.id == 100 || item.id == 101 || item.id == 102 || item.id == 237 || item.id == 238 || item.id == 239 ? 'Escanteios' : item.id == 123 || item.id == 124 || item.id == 125 || item.id == 126 || item.id == 127 || item.id == 128 ? 'Cartões' : item.cat_name)
                }
            }).sort((a, b) => {
                return a.order - b.order;
            }).filter(item => (item.cat_id == cat_id && !cat_name) || item.cat_label == cat_name);

            for (const item of odds) {

                let cat_key = '';
                let isArray = false;
                
                if (this.optionIsEnabled(item.id, data.ao_vivo)) 
                {
                    if (this.isArray(item.odd)) 
                    {
                        if (item.odd.length) 
                        {
                            const oddFilter = item.odd.filter(option => parseFloat(option.odd) > 1);

                            if (oddFilter.length) 
                            {
                                isArray = true;
                                cat_key = `${item.cat_name} - ${item.cat_id}`;

                                switch(item.id) {
                                    case 98: // Jogadores
                                    case 99:
                                    case 236:
                                        this.has_odds_players = true;
                                    break;
                                    case 100: // Escanteios
                                    case 101:
                                    case 102:
                                    case 237:
                                    case 238:
                                    case 239:
                                        this.has_odds_corners = true;
                                    break;
                                    case 123: // Cartões
                                    case 124:
                                    case 125:
                                    case 126:
                                    case 127:
                                    case 128:
                                        this.has_odds_cards = true;
                                    break;
                                    default:
                                    break;
                                }
                            }
                        }
                    } 
                    else if (parseFloat(item.odd) > 1) 
                    {
                        cat_key = `${item.cat_name} - ${item.cat_id}`;

                        // if (item.cat_id == 'pt') this.has_odds_first_half = true;
                        // if (item.cat_id == 'st') this.has_odds_second_half = true;

                        switch(item.cat_name) {
                            case 'Ambas equipes marcam':
                                this.has_odds_both_score = true;
                            break;
                            case 'Dupla possibilidade':
                                this.has_odds_double_possibility = true;
                            break;
                            case 'Partida / Total de gols':
                                this.has_odds_total_goals_match = true;
                            break;
                            case 'Handicap':
                                this.has_odds_handicap = true;
                            break;
                            default:
                            break;
                        }
                    } 
                    else 
                    {
                        cat_key = '';
                    }

                    if (cat_key) {

                        if (structure[cat_key] == undefined) {
                            structure[cat_key] = {
                                type: 'item',
                                cat_id: item.cat_id,
                                options: [],
                                cat_name: this.replaceArray(item.cat_name, ['Casa','casa','Fora','fora','Time da','Time de'], [data.partida.time_casa.nome, data.partida.time_casa.nome, data.partida.time_fora.nome, data.partida.time_fora.nome,'',''])
                            };
                        }

                        if (isArray) {
                            structure[cat_key].options.push(item);
                        } else {
                            structure[cat_key].options.push({
                                id: item.id,
                                odd: item.odd,
                                cat_id: item.cat_id,
                                cat_name: item.cat_name,
                                option: this.replaceArray(item.option, ['Casa','casa','Fora','fora'], [data.partida.time_casa.nome, data.partida.time_casa.nome, data.partida.time_fora.nome, data.partida.time_fora.nome])
                            });
                        }
                    }
                }
            }

            const obj = Object.keys(structure).map(key => structure[key]);

            if (this.hasOdds(obj)) {
                this.has_odds_block = false;
            } else {
                this.has_odds_block = true;
            }

            return obj;
        },
        oddsStructureChallenge(odds) {
            try {
                return odds.filter((item) => {
                    return parseFloat(item.odd) > 1 
                }).map((arr) => {
                    return arr;
                });
            } catch(e) {
                return [];
            }
        },
        replaceArray(replaceString, find, replace) {
            var regex;
            for (var i = 0; i < find.length; i++) {
                regex = new RegExp(find[i], "g");
                replaceString = replaceString.replace(regex, replace[i]);
            }
            return replaceString;
        },
        separar(base, maximo) {

            let resultado = [[]];
            let grupo = 0;

            for (let indice = 0; indice < base.length; indice++) {

                if (resultado[grupo] === undefined) {
                    resultado[grupo] = [];
                }

                resultado[grupo].push(base[indice]);

                if ((indice + 1) % maximo === 0) {
                    grupo = grupo + 1;
                }
            }

            return resultado;
        },
        convertDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY');
        },
        convertDateTicket(date) {
            return moment(date).tz('America/Recife').format('DD/MM - HH:mm');
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        punterOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.punter === o.punter)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return a.punter.localeCompare(b.punter)
            });
        },
        formatHour(date) {
            return moment(date).tz('America/Recife').format('HH:mm');
        },
        formatDateDay(date) {
            return moment(date).locale('pt-br').tz('America/Recife').format('dddd: D MMMM YYYY');
        },
        startSession(data) {
            this.setItem('x-token', data.token).then(() => {
                this.setItem('auth', JSON.stringify(data.user)).then(() => {
                    this.setItem('firebase:pipe', data.user.pipe).then(() => {
                        window.location.reload();
                    });
                });
            });
        },
        setItem(key, value) {
            return Promise.resolve().then(() => {
                window.localStorage.setItem(key, value);
            });
        },
        logout() {
            this.$dialogs.confirm(`<b>${this.user_name}</b> você deseja realmente sair da sua conta?`, {
                title: 'Atenção!',
                cancelLabel: 'Não',
                okLabel: 'Sim',
                size: 'sm'
            }).then(res => {
                if (res.ok) {
                    this.removeItem('x-token').then(() => {
                        this.removeItem('auth').then(() => {
                            this.removeItem('firebase:pipe').then(() => {
                                window.location.href = '/';
                            });
                        });
                    });
                }
            });
        },
        removeItem(key) {
            return Promise.resolve().then(() => {
                window.localStorage.removeItem(key)
            });
        },
        hasAccumulated() {

            if (this.selectedEventsCount()) {
                for (let key in this.hunches) {
                    if (this.hunches[key].accumulated) {
                        return true;
                    }
                }
            } 

            return false;
        },
        groupCountry(events) {

            const self = this;
            const countrys = {};

            self.favorites_leagues = {};
            self.favorites_leagues_exists = false;

            events.forEach((item) => {

                let [pais, liga] = item.liga.split(' - ');

                pais = (pais).toString().trim();
                liga = (liga).toString().trim();

                if (pais && liga) {

                    if (countrys[pais] == undefined) {
                        countrys[pais] = {
                            country: pais,
                            liga_cc: item.liga_cc || 'default',
                            total: 0
                        };
                    }

                    if (countrys[pais]) {
                        countrys[pais].total += 1;
                    }

                    // Ligas favoritas
                    if (self.favorites.includes(item.liga_id)) {

                        if (!self.favorites_leagues_exists) {
                            self.favorites_leagues_exists = true;
                        }

                        if (self.favorites_leagues[item.liga_id] == undefined) {
                            self.favorites_leagues[item.liga_id] = {
                                id: item.liga_id,
                                league_full: item.liga,
                                liga_cc: item.liga_cc || 'default',
                                league: liga,
                                country: pais,
                                total: 0
                            }
                        }

                        if (self.favorites_leagues[item.liga_id]) {
                            self.favorites_leagues[item.liga_id].total += 1;
                        }
                    }
                }
            });

            return countrys;
        },
        groupCompetitionsByCountry(events) {

            const result = events.reduce((c, v) => {
        
                const [pais, liga] = v.liga.split(' - ');
                const n_liga = v.liga.replace(`${pais} -`, '').toString().trim();

                c[pais] = c[pais] || {};

                c[pais][liga] = c[pais][liga] == undefined ? c[pais][liga] = {
                    id: v.liga_id,
                    total: 1,
                    liga: n_liga,
                    liga_full: v.liga,
                    esporte: v.esporte
                } : c[pais][liga] = {
                    id: v.liga_id,
                    liga: n_liga,
                    liga_full: v.liga,
                    total: c[pais][liga].total + 1,
                    esporte: v.esporte
                };

                return c;
            }, {});

            return result;
        },
        rules(odds) {
            return odds.map((item) => {
                return {
                    id: item.id,
                    cat_id: item.cat_id,
                    cat_name: item.cat_name,
                    option: item.option,
                    odd: this.oddsControl(item.id, item.odd)
                }
            });
        },
        oddsControl(id, odd) {

            let odds = [];
            let option = this.liveOptionsStructure[id];
            let min_odd = parseFloat(this.configs.min_odd_ao_vivo);

            if (typeof(option) !== 'undefined') {

                let type = parseInt(option.tipo);
                let active = parseInt(option.ativo);
                let limit = parseFloat(option.limite);
                let percent = parseFloat(option.porcentagem);
                let blacklist = option.blacklist ? parseFloat(option.blacklist) : 0;

                if (Array.isArray(odd)) {

                    if (active == 1) {

                        if (odd.length > 0) {

                            if (id == 27) {

                                odd.forEach((item) => {

                                    let cotation = '1.00';
                                    let cota = parseFloat(item.odd);
                                    let calc = parseFloat((cota * percent) / 100);
                                    let new_odd = type == 1 ? parseFloat(cota - calc) : parseFloat(cota + calc);

                                    if (new_odd <= 1) {
                                        cotation = '1.00';
                                    } else if (new_odd == null) {
                                        cotation = '1.00';
                                    } else if (new_odd < min_odd) {
                                        cotation = '1.00';
                                    } else if (new_odd > limit) {
                                        cotation = limit;
                                    } else {
                                        cotation = new_odd;
                                    }

                                    if (cotation > 1) {
                                        odds.push({
                                            score: item.score,
                                            score_home: item.score_home,
                                            score_away: item.score_away,
                                            odd: parseFloat(cotation).toFixed(2)
                                        });
                                    }
                                });

                                return odds;
                            }
                            else if (id == 100 || id == 101 || id == 102 || id == 123 || id == 124 || id == 125 || id == 126 || id == 127 || id == 128 || id == 215 || id == 216 || id == 217 || id == 218 || id == 219 || id == 220 || id == 237 || id == 238 || id == 239 || id == 240)
                            {
                                odd.forEach((item) => {

                                    let cotation = '1.00';
                                    let cota = parseFloat(item.odd);
                                    let handicap = parseFloat(item.handicap);
                                    let calc = parseFloat((cota * percent) / 100);
                                    let new_odd = type == 1 ? parseFloat(cota - calc) : parseFloat(cota + calc);

                                    if (new_odd <= 1) {
                                        cotation = '1.00';
                                    } else if (new_odd == null) {
                                        cotation = '1.00';
                                    } else if (new_odd < min_odd) {
                                        cotation = '1.00';
                                    } else if (new_odd > limit) {
                                        cotation = limit;
                                    } else {
                                        cotation = new_odd;
                                    }

                                    // Escanteios - Mais/Exatos
                                    if ((id == 100 || id == 102) && handicap < blacklist) {
                                        cotation = 1;
                                    }

                                    // Escanteios - Menos
                                    if ((id == 101 && blacklist > 0) && handicap > blacklist) {
                                        cotation = 1;
                                    }

                                    // Cartões - Acima
                                    if ((id == 123 || id == 125 || id == 127) && handicap < blacklist) {
                                        cotation = 1;
                                    }

                                    // Cartões - Abaixo
                                    if ((id == 124 || id == 126 || id == 128) && handicap > blacklist) {
                                        cotation = 1;
                                    }

                                    if (cotation > 1) {
                                        odds.push({
                                            handicap: handicap,
                                            odd: parseFloat(cotation).toFixed(2)
                                        });
                                    }
                                });

                                return odds;                          
                            }
                        }
                        else
                        {
                            return odds;
                        }
                    }
                    else
                    {
                        return odds;
                    }
                } 
                else 
                {
                    if (active == 1) {
                        
                        let cota = parseFloat(odd);
                        let calc = parseFloat((cota * percent) / 100);
                        let new_odd = type == 1 ? parseFloat(cota - calc) : parseFloat(cota + calc);

                        if (new_odd <= 1) {
                            return '1.00';
                        } else if (new_odd == null) {
                            return '1.00';
                        } else if (new_odd < min_odd) {
                            return '1.00';
                        } else if (new_odd > limit) {
                            return parseFloat(limit).toFixed(2);
                        } else {
                            return parseFloat(new_odd).toFixed(2);
                        }
                    }
                    else
                    {
                        return '1.00';
                    }
                }
            } 
            else 
            {
                return '1.00';
            }
        },
        imageError(event, image) {
            event.target.src = image;
        },
        imageFlag(name) {
            try {

                const state = name.split('-')[0];

                if (typeof(state) !== 'undefined') {
                    return `${this.removeAccents(state).toLowerCase().trim().replace(/[^a-z0-9\s]/g, '').replace(/\s/g, '-')}.png`;
                } else {
                    return 'league.svg';
                }
            } catch(e) {
                return 'league.svg';
            }
        },
        imageShield(team) {
            try {
                return `${this.removeAccents(team).toLowerCase().trim().replace(/[^a-z-0-9\s]/g, '').replace(/\s/g, '-')}.png`;
            } catch(e) {
                return 'shield.png';
            }
        },
        imageCheck(event) {
            try {
                var w = event.target.naturalWidth;
                var h = event.target.naturalHeight;

                if (w == 1 && h == 1) {
                    event.target.src = '/images/shield_default.png';
                }
            } catch(e) {
                event.target.src = '/images/shield_default.png';
            }
        },
        hasOdds(odds) {
            return typeof(odds) == 'undefined' ? true : Array.isArray(odds) ? odds.length : false;
        },
        isArray(arr) {
            return Array.isArray(arr);
        },
        download() {
            window.location.href = `https://downcenter.betsnow.net/${document.location.host.replace('www.','')}.apk`;
        },
        refreshPage() {
            this.sidebar = false;
            window.location.reload();
        },
        help() {

            const self = this;

            self.sidebar = false;
            self.clearURLHash();

            if (self.templateRules == '') {

                self.isLoading = true;

                axios.get('rules.html').then((response) => {
                    self.templateRules = response.data;
                    self.$dialogs.alert(response.data, {
                        title: 'Regras de aposta'
                    });
                }).catch((error) => {
                    try {
                        self.showAlert(
                            'Falha!', 
                            self.network_erros[error.status]['message']
                        );
                    } catch(e) {
                        self.showAlert(
                            'Falha!', 
                            self.network_erros[408]['message']
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
            else
            {
                self.$dialogs.alert(self.templateRules, {
                    title: 'Regras de aposta'
                });
            }
        },
        loadConfigs() {

            const self = this;

            self.isLoad = true;
            self.loading = true;
            self.isLoading = true;

            api.get('configs').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountConfigurations(response.data);
                        self.bet.punter = self.getBetPunterName();
                    break;
                    case 'jwt_expired':
                        self.problem = true;
                        self.message = 'Sua sessão expirou, faça o login novamente!';
                    break;
                    default:
                        self.problem = true;
                        self.message = 'Ocorreu um problema, recarregue a página!';
                    break;
                }
            }).catch((error) => {
                try {
                    self.problem = true;
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.problem = true;
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        mountConfigurations(data) {
            this.configs = data.configs;
            this.loja = data.configs.loja;
            this.footer = data.configs.footer;
            this.cassino = data.configs.cassino;
            this.user_id = data.configs.user_id;
            this.isLogged = data.configs.situacao;
            this.cadastro = data.configs.cadastro;
            this.sports = data.configs.menu_sports;
            this.mainMenu = data.configs.main_menu;
            this.favorites = data.configs.ligas_favoritas;
            this.betBuilder = data.configs.betbuilder.ativo;
            this.betBuilderLive = data.configs.betbuilder.ao_vivo;
            this.delay = parseInt(data.configs.ao_vivo_delay);
            this.whatsapp = data.configs.redes_sociais.whatsapp;
            this.telegram = data.configs.redes_sociais.telegram;
            this.instagram = data.configs.redes_sociais.instagram;
            this.betBuilderOdds = data.configs.betbuilder.odds;
            this.betBuilderOptions = data.configs.betbuilder.mercados;
            this.current_credit = this.formatCoin(data.configs.credito);
            this.service_link = this.getContact(data.configs.redes_sociais.whatsapp);
            this.current_credit_simple = this.formatCoin(data.configs.credito_simples);
            this.betBuilderMinSelected = parseInt(data.configs.betbuilder.min_palpites);
            this.betBuilderMaxSelected = parseInt(data.configs.betbuilder.max_palpites);
            this.betBuilderMinGamesSelected = parseInt(data.configs.betbuilder.min_jogos);
            this.betBuilderMaxGamesSelected = parseInt(data.configs.betbuilder.max_jogos);
            this.getEvents();
            this.checkURLPreBet();
        },
        getBetPunterName() {
            let punter = '';
            let localPunter = localStorage.getItem('punter') || '';

            if (this.isLogged && this.pipe == 4 && this.user_name) {
                punter = this.user_name;
            } 
            
            if (!this.isLogged && this.pipe == 0 && localPunter) {
                punter = localPunter;
            }

            return punter;
        },
        getContact(contato) {
            return contato == '' ? 'javascript:;' : `https://api.whatsapp.com/send?phone=${encodeURIComponent(`+55${contato.replace(/[^\d]/g, "")}`)}&text=${encodeURIComponent(`Olá, ${this.configs.nome}`)}`;
        },
        login() {
            this.$router.push('/login');
        },
        register() {
            if (this.cadastro == 1) {
                this.$router.push('/register');
            } else {
                this.showAlert(
                    'Atenção!', 
                    'No momento não estamos aceitando novos membros!'
                );
            }
        },
        checkAppVersion() {
            if (this.app_version != this.configs.app_version) {
                var page_loading = document.querySelector('.page-loading');
                    page_loading.style.display = 'block';
                    page_loading.innerHTML = `
                    <div class="d-flex h-100 align-items-center justify-content-center flex-column text-center">
                        <div class="display-2 text-warning">
                            <i class="fas fa-exclamation-triangle"></i>
                        </div>
                        <p class="text-white">Você está usando uma versão antiga do sistema!</p>
                        <button class="btn btn-secondary my-2 my-sm-0" onclick="window.location.reload();">Clique aqui para atualizar</button>
                    </div>
                `;
            }
        },
        checkURLPreBet() {
            if (this.isLogged && !this.isCordova) {
                if (this.hash.includes('prebet')) {
                    const code = this.hash.split('-')[1];
                    if (/^[a-zA-Z0-9]+$/g.test(code) && code.toString().length >= 6 || code.toString().length <= 8) {
                        this.searchPreBet(code);
                    } else {
                        this.clearURLHash();
                    }
                }
            } else {
                this.clearURLHash();
            }
        },
        clearURLHash() {
            try {
                this.hash = '';
                window.location.hash = '';
                history.replaceState(null, null, ' ');
            } catch(e) {
                window.sessionStorage.setItem('error-clear-url', e.message);
            }
        },
        receiveMessage(event) {
            if (event.data.action == 'prebet') {
                this.searchPreBet(event.data.code);
            }
        },
        checkIsApp() {
            this.isCordova = (window.localStorage.getItem('cordova') || window.location.search.includes('cordova')) ? true : false;
        },
        convertSpace(text) {
            try {
                return text.replace(/\n/g, "<br />");
            } catch(e) {
                return text;
            }
        },
        formatDateAndHour(date) {
            return moment(date).tz('America/Recife').format('DD/MM/Y HH:mm');
        },
        getToLink(index) {
            try {
                if (this.configs.slider[index].hasOwnProperty('link')) {
                    if (this.configs.slider[index].link.toString().trim()) {
                        return window.open(this.configs.slider[index].link, '_blank');
                    } else {
                        return false; 
                    }
                } else {
                    return false;
                }
            } catch(e) {
                return false;
            }
        },
        getService(service) {
            switch(service) {
                case 'whatsapp':
                    if (this.whatsapp) {
                        if (this.platform == 'desktop') {
                            window.open(`https://api.whatsapp.com/send?phone=55${this.whatsapp}&text=${encodeURIComponent(`Olá, ${this.configs.nome}`)}`, '_blank');
                        } else {
                            window.location.href = `whatsapp://send?phone=55${this.whatsapp}&text=${encodeURIComponent(`Olá, ${this.configs.nome}`)}`;
                        }
                    }
                break;
                case 'instagram':
                    if (this.instagram) {
                        if (this.platform == 'desktop') {
                            window.open(`https://instagram.com/${this.instagram}`, '_blank');
                        } else {
                            window.location.href = `instagram://user?username=${this.instagram}`;
                        }
                    }
                break;
                default:
                    if (this.telegram) window.open(`https://t.me/+55${this.telegram}`, '_blank');
                break;
            }
        },
        widgetField() {
            this.widget_field = !this.widget_field;
        },
        surprise() {

            if (this.events.length == 0) return;
            if (this.events.some((item) => item.esporte > 1 || item.ao_vivo == true)) return;

            let limit = 5;
            let choices = [1, 2, 3]; 
            let choices_key = ['casa', 'empate', 'visitante'];

            let events = this.events.map((item) => {
                return item
            });

            this.bet.money = this.configs.min_valor;

            if (this.selectedEventsCount() > 0) {

                for (let key in this.hunches) {
                    this.$delete(this.hunches, this.hunches[key].id);
                }

                this.calcPremium();
            }

            events.sort(() => 0.5 - Math.random()).slice(0, limit).forEach((item) => {
                let option = (Math.floor(Math.random() * choices.length) || 0);
                this.selectEvent(item.id, choices[option], item.ao_vivo, false, false, false, false, false, item['odds'][choices_key[option]]);
            });
        },
        copyClipboard(event) {
            navigator.clipboard.writeText(event.target.value).then(() => {
                this.showAlert(
                    'Parabéns!', 
                    'O <strong>link</strong> foi copiado com sucesso!'
                );
            }, () => {
                this.showAlert(
                    'Erro!', 
                    'Não foi possível copiar o <strong>link</strong>!'
                );
            });
        },
        changeMarket(mercado_id, liga_id) {
            for (const item of this.filteredListEvents) {
                if (item.liga_id == liga_id) {
                    item.mercado_id = mercado_id;
                }
            }
        },
        isNotEmpty(obj) {
            return Object.keys(obj).length > 0;
        },
        toTop() {
            const collectionCluster = document.querySelector('.collection-cluster');
            if (collectionCluster) {
                try {
                    collectionCluster.scroll({
                        top: 0, 
                        behavior: 'smooth'
                    });
                } finally {
                    this.onScrollToTop();
                }
            } 
        },
        scrollToPosition(e) {
            const el = e.currentTarget;
            el.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'start'
            });
        },
        prevSubNav() {
            const subNav = document.querySelector('.modal-subnav .nav.nav-justified');
            if (subNav) {
                subNav.scroll({
                    left: 0, 
                    behavior: 'smooth'
                });
            }
        },
        nextSubNav() {
            const subNav = document.querySelector('.modal-subnav .nav.nav-justified');
            if (subNav) {
                subNav.scroll({
                    left: subNav.scrollWidth, 
                    behavior: 'smooth'
                });
            }
        },
        scrollAreaTicket(event) {
            const scrollTop = event.target.scrollTop;
            if (scrollTop < 1) {
                this.sticky = false;
            } else {
                this.sticky = true;
            }
        },
        onScrollToTop() {
            const area = document.getElementById('events-area');
            if (area && !this.live) {
                area.scroll({
                    top: 0, 
                    behavior: 'smooth'
                });
            }
        },
        onScrollToBottom() {
            const search = this.search.toString().trim();
            const area = document.querySelector('#events-area');
            if (area && !this.blockOnScroll && !search && !this.live) {
                area.scroll({
                    top: area.scrollHeight, 
                    behavior: 'smooth'
                });
            }
        },
        detectScroll(e) {
            const offsetTop = 1;
            const scrollHeight = e.target.scrollHeight;
            const offsetHeight = e.target.offsetHeight;
            const scrollTop = Math.ceil(e.target.scrollTop);
            const contentHeight = scrollHeight - offsetHeight;

            if (scrollTop >= offsetTop || (this.countEvents > 1 && this.countEvents <= 5)) {
                this.imageSlickCarousel = false;
            } else {
                this.imageSlickCarousel = true;
            }

            if (contentHeight <= scrollTop) {
                this.scrollPosition = 'bottom';
            } else {
                this.scrollPosition = 'top';
            }
        },
        // scrollModalGetPosition(e) {
        //     window.sessionStorage.setItem('scrollPosition', e.target.scrollTop);
        //     // this.scrollModalPosition = e.target.scrollTop;
        // },
        // scrollModalToPosition(position) {
        //     const scrollPosition = window.sessionStorage.getItem('scrollPosition') || 0;
        //     const modalContainer = document.querySelector('.modal-main .collection-cluster');
        //     if (modalContainer) {
        //         window.console.log(`scrollTop: ${scrollPosition}`)
        //         modalContainer.scrollTop = position;
        //     }
        // },
        updateHunchesToLive(events) {
            if (this.selectedEventsCount()) {
                for (const item of events) {
                    if (this.hunches[item.id] != undefined) {
                        if (this.dbo[item.id] != undefined) {
                            let odd = '1.00';
                            let dbo = this.dbo[item.id];
                            let hun = this.hunches[item.id];
                            let index = dbo.odds.findIndex((obj) => obj.id == hun.option);
                            if (index != -1) {
                                if (dbo.odds[index] != undefined) {
                                    if (dbo.odds[index]['odd'] != undefined) {
                                        if (hun.option == 27 || hun.option == 100 || hun.option == 101 || hun.option == 102) {
                                            let odd_option = dbo.odds[index]['odd'];
                                            if (odd_option != undefined) {
                                                if (this.isArray(odd_option)) {
                                                    if (hun.option == 27) {
                                                        let odd_event = odd_option.find((obj) => obj.score == hun.score);
                                                        if (odd_event['odd'] != undefined) {
                                                            odd = odd_event.odd;
                                                        }
                                                    } else {
                                                        let odd_event = odd_option.find((obj) => obj.corners == hun.corners);
                                                        if (odd_event['odd'] != undefined) {
                                                            odd = odd_event.odd;
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            odd = dbo.odds[index].odd;
                                        }

                                        if (odd <= 1 || dbo.info.tempo >= this.configs.ao_vivo_tempo) {
                                            this.removeEvent(item.id);
                                        } else {
                                            hun.odd = odd;
                                        }   
                                    }
                                }
                            }
                        }
                    }
                }
                this.calcPremium();
            }
        },
        eventsLiveStructure(events, oldEvents) {
            try {
                for (const event of events) {
                    for (const oldEvent of oldEvents) {
                        if (event.id == oldEvent.id) {
                            for (const oddEvent of event.cotacoes) {
                                for (const oddOldEvent of oldEvent.cotacoes) {
                                    if (oddEvent.id == oddOldEvent.id) {
                                        if (this.isArray(oddEvent.odd)) {
                                            for (const option of oddEvent.odd) {
                                                for (const oldOption of oddOldEvent.odd) {
                                                    if (oddEvent.id == 98 || oddEvent.id == 99 || oddEvent.id == 236) 
                                                    {
                                                        if (option.player == oldOption.player) {
                                                            const refKey = `${event.id}:${oddEvent.id}:${option.player}`;
                                                            if (this.$refs[refKey] != undefined) {
                                                                this.upAndDownLiveBtn(this.$refs[refKey][0], option.odd, oldOption.odd);
                                                            }
                                                        }
                                                    } 
                                                    else if (oddEvent.id == 100 || oddEvent.id == 101 || oddEvent.id == 102 || oddEvent.id == 123 || oddEvent.id == 124 || oddEvent.id == 125 || oddEvent.id == 126 || oddEvent.id == 127 || oddEvent.id == 128) 
                                                    {
                                                        if (option.handicap == oldOption.handicap) {
                                                            const refKey = `${event.id}:${oddEvent.id}:${option.handicap}`;
                                                            if (this.$refs[refKey] != undefined) {
                                                                this.upAndDownLiveBtn(this.$refs[refKey][0], option.odd, oldOption.odd);
                                                            }
                                                        }
                                                    } 
                                                    else 
                                                    {
                                                        if (option.score == oldOption.score) {
                                                            const refKey = `${event.id}:${oddEvent.id}:${option.score}`;
                                                            if (this.$refs[refKey] != undefined) {
                                                                this.upAndDownLiveBtn(this.$refs[refKey][0], option.odd, oldOption.odd);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        else
                                        {
                                            const refKey = `${event.id}:${oddEvent.id}`;
                                            const refKeyLive = `${refKey}:live`;

                                            if (this.$refs[refKey] != undefined) {
                                                if (this.isElement(this.$refs[refKey])) {
                                                    this.upAndDownLiveBtn(this.$refs[refKey], oddEvent.odd, oddOldEvent.odd);
                                                } else {
                                                    this.upAndDownLiveBtn(this.$refs[refKey][0], oddEvent.odd, oddOldEvent.odd);
                                                }
                                            }

                                            if (this.$refs[refKeyLive] != undefined) {
                                                if (this.isElement(this.$refs[refKeyLive])) {
                                                    this.upAndDownLiveBtn(this.$refs[refKeyLive], oddEvent.odd, oddOldEvent.odd);
                                                } else {
                                                    this.upAndDownLiveBtn(this.$refs[refKeyLive][0], oddEvent.odd, oddOldEvent.odd);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } 
            catch(e)
            {
                // window.console.log(e.message);
            }
        },
        upAndDownLiveBtn(btn, actualOdd, oldOdd) {
            if (btn != undefined) {
                if (actualOdd > oldOdd) {   

                    if (btn.classList.contains('odd-up')) {
                        btn.className += ' odd-blink';
                    } else if (!btn.classList.contains('odd-up')) {
                        btn.className += ' odd-up odd-blink';
                    }

                    if (btn.classList.contains('odd-down')) btn.classList.remove('odd-down');
                } else if (actualOdd < oldOdd) {

                    if (btn.classList.contains('odd-down')) {
                        btn.className += ' odd-blink';
                    } else if (!btn.classList.contains('odd-down')) {
                        btn.className += ' odd-down odd-blink';
                    }

                    if (btn.classList.contains('odd-up')) btn.classList.remove('odd-up');
                } else {
                    if (btn.classList.contains('odd-blink')) btn.classList.remove('odd-blink');
                }
            }
        },
        isElement(obj) {
            try {
                return obj instanceof HTMLElement;
            }
            catch(e){
                return (typeof obj==="object") &&
                (obj.nodeType===1) && (typeof obj.style === "object") &&
                (typeof obj.ownerDocument ==="object");
            }
        },
        showAlert(title, message) {
            this.$dialogs.alert(message, {
                title, 
                size: 'sm'
            });
        },
        subItemsClientHeight(event) {
            try {

                let imageHeigth = 0;
                let slickInitializedHeight = document.querySelector('.slick-initialized').clientHeight;

                if (event) {
                    imageHeigth = event.target.height;
                } 
                
                if (event == null && imageHeigth == 0 && !this.isLogged) {
                    imageHeigth = document.querySelector('.slick-slider .img-fluid').offsetHeight;
                }

                if (slickInitializedHeight && imageHeigth > slickInitializedHeight) {
                    this.imageSlickCarouselHeight = slickInitializedHeight;
                } else if (imageHeigth) {
                    this.imageSlickCarouselHeight = imageHeigth;
                }

                setTimeout(() => {
                    const navBottomHeight = parseInt(this.navBottomHeight);
                    const subItemsHeight = document.querySelector('.sub-items-mobile');
                    const imageOffsetHeight = document.querySelector('.slick-slider .img-fluid');
                    if (imageOffsetHeight) this.imageSlickCarouselHeight = imageOffsetHeight.offsetHeight;
                    if (subItemsHeight) this.subItemsHeight = this.screen_innerHeight - subItemsHeight.clientHeight - navBottomHeight;
                }, 500);
            } catch(e) {
                this.subItemsHeight = 320;
            }
        },
        screenAdjust() {
            if (this.imageSlickCarousel && !this.isLogged) {
                this.subItemsClientHeight(null);
            } else {
                this.subItemsHeight = this.screen_innerHeight - this.navBottomHeight - this.subItemsHeightDefault;
            }
        },
        showModalSetPunter() {
            if (this.isLogged && this.pipe == 1) this.$modal.show('set-punter');
        },
        inputFocus(e) {
            e.target.style.opacity = 0;
            setTimeout(() => e.target.style.opacity = 1);
        },
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/Y');
        },
        checkExistsHash() {
            try {
                if (this.$route.hash == '#rules') this.regras = true;
                if (this.$route.hash == '#betting-rules') this.help();
            } catch(e) {
                this.clearURLHash();
            }
        },
    },
    filters: {
        changeLeagueName(name) {

            let pais = ''
            let liga = '';
            let newName = name;

            try {
                if (name.includes(' - ')) {

                    for (let i = 0; i < name.split(' - ').length; i++) {

                        if (i == 0) {
                            pais = String(name.split(' - ')[0]).trim();
                        } else {
                            liga += String(' ' + name.split(' - ')[i]).trim();
                        }

                        newName = `${pais}: ${liga}`;
                    }
                }
                return newName;
            } catch(e) {
                return newName;
            }
        },
        getLeague(name) {
            try {
                return String((name).split('-')[1]).trim();
            } catch(e) {
                return name;
            }
        },
        percentFormat(percent) {
            return Math.round(percent);
        },
        formatHour(date) {
            return moment(date).tz('America/Recife').format('HH:mm');
        },
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/Y');
        },
        formatDateDDMM(date) {
            return moment(date).tz('America/Recife').format('DD/MM');
        },
        formatDay(date) {
            const format = 'YYYY-MM-DD';
            const now = moment(date).tz('America/Recife').format(format);
            const today = moment().tz('America/Recife').endOf('day').format(format);
            const tomorrow = moment().tz('America/Recife').add(1, 'day').endOf('day').format(format);
            if (now == today) return 'Hoje';
            if (now == tomorrow) return 'Amanhã';
            return moment(date).tz('America/Recife').format('DD/MM');
        },
        formatDateDDMMHHMM(date) {
            return moment(date).tz('America/Recife').format('DD/MM HH:mm');
        },
        formatDateAndHour(date) {
            return moment(date).tz('America/Recife').format('DD/MM/Y HH:mm');
        },
        formatDateDay(date) {
            return moment(date).locale('pt-br').tz('America/Recife').format('dddd: D MMMM YYYY');
        },
        formatDateDayAndHour(date) {
            return moment(date).locale('pt-br').tz('America/Recife').format('llll');
        },
        formataCpfCnpj(str) {
            return str.toString().length == 11 ? str.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : str.replace(/[^\d]/g, "").replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
        },
        formataTelefone(str) {
            return str.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    computed: {
        money() {
            return this.bet.money;
        },
        punter() {
            return this.bet.punter;
        },
        aoVivoOpcoes() {
            return this.configs.ao_vivo_opcoes;
        },
        preGameMarkets() {
            return this.configs.cotacoes;
        },
        filteredListEvents() {
            return this.events.filter((item) => {
                return this.removeAccents(item.liga).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || this.removeAccents(item.time_casa).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || this.removeAccents(item.time_fora).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim())
            }).sort((a, b) => {
                
                if (a.destacar < b.destacar) return 1;
                if (a.destacar > b.destacar) return -1;

                if (this.removeAccents(a.liga) < this.removeAccents(b.liga)) return -1;
                if (this.removeAccents(a.liga) > this.removeAccents(b.liga)) return 1;
                
                if (a.horario < b.horario) return -1;
                if (a.horario > b.horario) return 1;

                return 0;
            });
        }
    },
    watch: {
        'cashback.cel'(value) {
            window.localStorage.setItem('cashbackCel', value);
        },
        'cashback.nome'(value) {
            window.localStorage.setItem('cashbackName', value);
        },
        configs() {
            this.checkAppVersion();
        },
        sport(id) {
            let position = 0;

            const sport = id == 0 ? 'live' : id;
            const el = document.getElementById(`sport-${sport}`);
            const container = document.querySelector('.nav-sport-mobile.sports');

            if (!this.imageSlickCarousel) this.imageSlickCarousel = true;

            if (el && container && (sport == 1 || sport == 'live')) {
                
                const elRight = el.offsetLeft + el.offsetWidth;
                const elLeft = el.offsetLeft;

                const elParentRight = el.parentNode.offsetLeft + el.parentNode.offsetWidth;
                const elParentLeft = el.parentNode.offsetLeft;

                if (elRight > elParentRight + el.parentNode.scrollLeft) {
                    position = elRight - elParentRight;
                } else if (elLeft < elParentLeft + el.parentNode.scrollLeft) {
                    position = elLeft - elParentLeft;
                }

                container.scrollTo({
                    left: position, 
                    behavior: 'smooth'
                });
            }
        },
        challenges(values) {
            if (values.length) this.$modal.show('challenges');
        },
        cat_id(value) {
            this.cat_name = '';
            this.betBuilderActive = (value == 'bb');
        },
        loading(value) {
            if (value) this.countEvents = 0;
            if (!value) this.isLoad = false;
        },
        blockOnScroll(value) {
            if (value) {
                setTimeout(() => {
                    this.blockOnScroll = false;
                }, 1000);
            }
        },
        imageSlickCarousel() {
            this.screenAdjust();
        },
        aoVivoOpcoes(values) {
            for (const item of values) {
                this.liveOptionsStructure[item.id] = item;
            }
        },
        check_conclude_bet_is_live(value) {
            
            let maxtime = this.delay * 1000;
            let incremental = 1000;
            let actualtime = 0;

            if (value) {
                this.progressbar_interval = setInterval(() => {
                    
                    actualtime += incremental;
                    let percentage = 100 / maxtime * actualtime;

                    this.progressbar_percent = percentage;

                    if (actualtime == maxtime) {
                        clearInterval(this.progressbar_interval);
                    }
                }, incremental);    
            } else {
                this.progressbar_percent = 0;
                clearInterval(this.progressbar_interval);
            }
        },
        progressbar_percent(value) {
            const vldIcon = document.querySelector('.vld-icon');
            if (vldIcon) {
                vldIcon.setAttribute('data-percent', Math.round(value) + '%');
            }
        },
        '$route': function(route) {
            if (route.hasOwnProperty('hash')) {
                if (route.hash.includes('prebet')) {
                    const code = route.hash.split('-')[1];
                    if (/^[a-zA-Z0-9]+$/g.test(code) && code.toString().length >= 6 || code.toString().length <= 8) {
                        this.searchPreBet(code);
                    } else {
                        this.clearURLHash();
                    }
                } else if (route.hash == '#rules') {
                    this.regras = true;
                    this.clearURLHash();
                } else if (route.hash == '#betting-rules') {
                    this.help();
                    this.clearURLHash();
                }
            }
        },
        bettors() {
            window.localStorage.setItem('bettors', JSON.stringify(this.bettors));
        },
        date(value) {
            this.getEvents();
            this.option_index = this.configs.calendario.findIndex((item) => item.date == value);
        },
        punter(value) {
            let punter = value.replace(/[^a-zà-ú0-9\s]+/gi,'');

            if (punter.length > 15) punter = punter.substr(0, 15);

            localStorage.setItem('punter', punter);

            this.bet.punter = punter;
        },
        money(value) {
            this.bet.money = this.configs.permitir_centavos == 2 ? value.toString().replace(/[^0-9]/g, '') : value.toString().replace(/[^0-9.]/g, '');
            this.calcPremium();
        },
        events(matchs, oldMatchs) {

           let totalMatchs = matchs.length;
           let totalOldMatchs = oldMatchs.length;
           
           if (!this.live && this.sport == 1 && totalMatchs > 0) {
                this.highlights = this.getHighlights(matchs);
           } else if (this.live && totalMatchs > 0 && totalOldMatchs > 0){
                this.updateHunchesToLive(matchs);
                this.eventsLiveStructure(matchs, oldMatchs);
           } 

           if (!this.isLogged && this.imageSlickCarousel && totalMatchs > 1 && totalMatchs <= 5) {
                this.imageSlickCarousel = false;
           }

           this.countEvents = totalMatchs;
           this.groupChampionships = this.groupCountry(matchs);
        },
        highlights(values) {
            this.hasHighlights = values.length ? true : false;
        },
        live(value) {

            const self = this;
            
            if (value) {

                self.search = '';
                self.sport = 'live';

                if (self.configs.ao_vivo == 2) {
                    self.problem = true;
                    self.loading = false;
                    self.nothing_found = true;
                    self.message = 'O ao vivo foi desativado!';
                }
                else
                {
                    self.loading = true;
                    self.problem = false;
                    self.nothing_found = false;

                    api.get('live').then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.inplay(response.data.events);
                            break;
                            case 'nothing_found':
                                self.problem = true;
                                self.loading = false;
                                self.nothing_found = true;
                                self.message = 'Nenhum evento disponível';
                            break;
                            default:
                                self.problem = true;
                                self.loading = false;
                                self.nothing_found = true;
                                self.message = response.data.message;
                            break;
                        }
                    }).catch((error) => {
                        try {
                            self.problem = true;
                            self.loading = false;
                            self.nothing_found = true;
                            self.message = self.network_erros[error.status]['message'];                        
                        } catch(e) {
                            self.problem = true;
                            self.loading = false;
                            self.nothing_found = true;
                            self.message = self.network_erros[408]['message'];   
                        }
                    });
                }
            } 
            else
            {
                socket.close();
                self.sport = this.sport;
            }
        },
        regras(value) {

            this.clearURLHash();
            this.sidebar = false;

            if (value) {

                const self = this;

                self.regras = false;
                self.isLoading = true;

                api.get('rules').then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.$dialogs.alert(self.convertSpace(response.data.message), {
                                title: 'Regulamento'
                            });
                        break;
                        default:
                            self.showAlert(
                                'Atenção!', 
                                response.data.message
                            );
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.showAlert(
                            'Falha!', 
                            self.network_erros[error.status]['message']
                        );
                    } catch(e) {
                        self.showAlert(
                            'Falha!', 
                            self.network_erros[408]['message']
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        prebet(value) {

            this.sidebar = false;

            if (value) {

                this.prebet = false;

                this.$dialogs.prompt('Informe o código da pré-aposta', {
                    title: 'Pré-aposta',
                    okLabel: 'Buscar',
                    size: 'sm'
                }).then(res => {
                    if (res.ok) {
                        let value = (res.value).toString().trim().toLowerCase();
                        let isValid = /^[a-zA-Z0-9]+$/g.test(value);
                        if (value == '') {
                            this.showAlert(
                                'Atenção!', 
                                'Informe o código da pré-aposta!'
                            );
                        } else if (!isValid || value.toString().length < 6 || value.toString().length > 8) {
                            this.showAlert(
                                'Atenção!', 
                                'O código fornecido é invalído!'
                            );
                        } else {
                            this.searchPreBet(value);
                        }
                    }
                });
            }
        },
        ticket(value) {

            if (value) {

                this.ticket = false;
                this.sidebar = false;

                this.$dialogs.prompt('Informe o código da aposta', {
                    title: 'Conferir bilhete',
                    okLabel: 'Buscar',
                    size: 'sm'
                }).then(res => {
                    if (res.ok) {
                        let value = (res.value).toString().trim().toLowerCase();
                        let isValid = /^[a-zA-Z0-9]+$/g.test(value);
                        if (value == '') {
                            this.showAlert(
                                'Atenção!', 
                                'Informe o código da aposta!'
                            );
                        } else if (!isValid || value.toString().length < 8 || value.toString().length > 10) {
                            this.showAlert(
                                'Atenção!', 
                                'O código fornecido é invalído!'
                            );
                        } else {
                            this.ticketByCode(value);
                        }
                    }
                });
            } 
        },
        username(value) {
            return this.username = value.toLowerCase().replace(/[^a-z0-9.]/g, '');
        },
        password(value) {
            return this.password = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        },
        sidebar(value) {
            if (!value) this.showLeagues = false;
        },
        showLeagues(value) {
            if (value) this.sidebar = true;
        },
        search(value) {
            if (this.sidebar) this.sidebar = false;
            if (value && !this.showSearchBox) this.showSearchBox = true;
        },
        showSearchBox(value) {
            if (!value) this.search = '';
        },
        preGameMarkets(values) {
            for (const item of values) {
                this.dbPregameMarkets[item.id] = item;
            }
        },
        hunches(items) {

            this.hunchesList = {};
            this.hunchesListCount = 0;
            this.betbuilderItemsList = {};
            this.betbuilderItemsListCount = 0;

            if (this.selectedEventsCount()) {

                for (let key in items) {
                    let hun = items[key];

                    if (hun.betbuilder) {

                        if (this.betbuilderItemsList[hun.id] == undefined) {
                            this.betbuilderItemsList[hun.id] = {
                                key: key,
                                id: hun.id,
                                sport: hun.sport,
                                league: hun.league,
                                schedule: hun.schedule,
                                team_home: hun.team_home,
                                team_away: hun.team_away,
                                items: []
                            }
                        }

                        this.betbuilderItemsListCount += 1;
                        this.betbuilderItemsList[hun.id].items.push(hun);
                    } else {
                        this.hunchesListCount += 1;
                        this.hunchesList[key] = hun;
                    }

                    // Remove em caso de duplicidade
                    if (this.hunchesList[key] != undefined && this.betbuilderItemsList[hun.id] != undefined) {
                        this.betBuilderRemoveEvent(hun.id);
                    }
                }
            }
        }
    },
    beforeMount() {
        try {
            const {name, pipe} = JSON.parse(window.localStorage.getItem('auth'));
            this.pipe = pipe;
            this.user_name = name;
        } catch(e) {
            this.pipe = 0;
            this.user_name = 'visitante';
        }
    },
    beforeCreate() {
        history.pushState(null, null, document.URL);
    },
    mounted() {

        const self = this;

        self.checkIsApp();
        self.recoverPunterList();

        window.onCashback = () => {

            const celInput = document.querySelector('#celCashback');
            const nameInput = document.querySelector('#nameCashback');

            if (celInput) {
                celInput.value = self.phoneMask(self.cashback.cel);
            }

            if (nameInput) {
                nameInput.value = self.cashback.nome;
            }
        }

        window.nameCashback = (e) => {
            let value = String(e.value).replace(/[^a-zà-ú\s]+/gi, '');
            if (value.toString().trim()) {
                self.cashback.nome = value.toString().trim();
            } else {
                self.cashback.nome = '';
            }
            e.value = value;
        }

        window.celCashback = (e) => {
            let value = self.phoneMask(e.value);
            if (value.toString().trim()) {
                self.cashback.cel = parseInt(value.toString().replace(/[^0-9]/g, ''));
            } else {
                self.cashback.cel = '';
            }
            e.value = value;
        }

        window.addEventListener('popstate', (e) => {

            e.preventDefault();

            if (self.modal_opened) {
                self.$modal.hide('odds');
            }

            if (self.sidebar) {
                self.sidebar = false;
            }

            self.$modal.hide('login');
            self.$modal.hide('ticket');
            self.$modal.hide('set-punter');
            self.$modal.hide('qrcode-pix');
            self.$modal.hide('modal-popup');
            self.$modal.hide('conclude-bet');
            self.$modal.hide('betting-rules');
            self.$modal.hide('modal-commissions');
            self.$modal.hide('betting-regulation');

            history.pushState(null, null, document.URL);
        });

        window.copyClipboard = () => {

            var copyText = document.getElementById('input-pix');

            if (copyText) {
                navigator.clipboard.writeText(copyText.value).then(() => {
                    self.showAlert(
                        'Parabéns!', 
                        'O <strong>QR Code</strong> foi copiado com sucesso!'
                    );
                }, () => {
                    self.showAlert(
                        'Erro!', 
                        'Não foi possível copiar o <strong>QR Code</strong>!'
                    );
                }); 
            } 
        }

        window.addEventListener('resize', () => {
            self.screen_w = window.screen.width;
            self.screen_h = window.screen.height;
            self.screen_adjust = window.innerWidth;
            self.screen_innerHeight = window.innerHeight;
            self.platform = self.isMobile();
            self.subItemsClientHeight(null);
        });

        window.addEventListener('message', this.widgetReceiveMessage);
    },
    created() {
        this.dateTick();
        this.loadConfigs();
        this.screenAdjust();
        this.checkExistsHash();
    }
}
</script>

<style scoped>
.collection-cluster {
    overflow-x: hidden;
    overflow-y: auto;
}
.home-main .collection-cluster::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: none;
    border-radius: 0;
}
.home-main .collection-cluster::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 0;
}
.home-main .image-slickCarousel {
    min-height: 100px;
    transform: scaleY(1);     
    transform-origin: top;
    transition: all 0.26s ease;
}
.home-main .image-slickCarousel.image-slickCarousel-hidden {
    min-height: 0px!important;
    height: 0px!important;
    transform: scaleY(0); 
}
.score {
    font-size: 24px;
}
img.versus {
    top: 2px;
    position: relative;
    vertical-align: bottom;
}
img.versus.bg {
    top: 2px;
    position: relative;
    vertical-align: bottom;
    background: rgb(75 79 82 / 32%);
    border-radius: 100%;
    padding: .5rem;
}
.img-shield {
    -moz-box-align: center;
    -moz-box-pack: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    align-items: center;
    background: var(--foreground);
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    justify-content: center;
    position: relative;
    width: 40px;
}
.border-left-1 {
    padding-left: 0px;
    border-left: 1px solid var(--background);
}
.sidebar {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1031;
    visibility: hidden;
    transition: visibility 0.3s;
}
.sidebar .sidebar-backdrop {
    width: 100vh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.21176470588235294);
}
.sidebar .sidebar-content {
    width: 85%;
    height: 100%;
    top: -1px;
    left: -40vh;
    position: absolute;
    transition: left 0.3s;
    -webkit-overflow-scrolling: touch;
    background-color: var(--background);
}
.sidebar .sidebar-content .sidebar-header,
.sidebar .sidebar-content .sidebar-footer {
    height: 20%;
    width: 100%;
}
.sidebar .sidebar-content .sidebar-header,
.sidebar .sidebar-content .sidebar-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.sidebar .sidebar-content .sidebar-footer .display-3 {
    font-size: 2rem;
    color: var(--sport-menu-link);
}
.sidebar .sidebar-content .sidebar-footer .btn {
    outline: none!important;
    box-shadow: none!important;
    background-color: var(--theme);
    border-color: var(--theme);
    color: var(--theme-text);
}
.sidebar .sidebar-content .sidebar-header .btn {
    background-color: var(--sidebar-btn-bg);
    border-color: var(--sidebar-btn-bg);
    color: var(--sidebar-btn-text);
}
.sidebar .sidebar-content .sidebar-header .btn:last-child {
    border-left: 1px solid var(--background);
}
.sidebar .sidebar-content input.form-control {
    color: var(--sport-menu-link);
    outline: none;
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.sidebar .sidebar-content input.form-control:focus {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.sidebar .sidebar-content .input-group-text {
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.sidebar .sidebar-content .list-group.scroll {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 40%);
}
.sidebar .sidebar-content .list-group .list-group-item:not(.league) {
    background-color: var(--background);
    border-color: var(--foreground);
}
.sidebar .sidebar-content .list-group .list-group-item.league {
    border-top: 0;
    background-color: var(--background);
    border-bottom-color: var(--foreground);
} 
.sidebar .sidebar-content .list-group .list-group-item.league:last-child{
    border-bottom-width: 0;
}
.sidebar.sidebar-show {
    visibility: visible;
}
.sidebar.sidebar-show .sidebar-content {
    left: -1px;
}
.modal-main-challenges {
    height: calc(100% - 39.2px)!important;
}
.modal-main-challenges .row.event-header {
    border-bottom: 1px solid var(--background);
}
.modal-main-challenges .row i {
    vertical-align: middle;
}
.modal-view-bet {
    width: 100%;
    height: calc(100% - 36.8px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--theme);
}
.modal-view-bet::-webkit-scrollbar {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray)!important;
    border-radius: 0;
}
.modal-view-bet::-webkit-scrollbar-thumb {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray-dark)!important;
}
.slick-slide .card .card-header {
    color: var(--sport-menu-link);
    background-color: var(--gray-dark);
}
.social-icons {
    font-size: 1.5rem;
    color: var(--footer-text);
    transition: color .5s ease-out;
    -moz-transition: color .5s ease-out;
    -webkit-transition: color .5s ease-out;
    -o-transition: color .5s ease-out;
}
.social-icons:hover {
    color: var(--theme);
}
.btn-group.grid-gap {
    grid-gap: 3px;
}
.content-mobile .btn-group.w-100.mr-2.grid-gap .btn:not(.active) .d-flex.justify-content-between div:first-child,
.content-mobile .btn-group.w-100.mr-2.grid-gap .btn:not(.active) .d-flex.justify-content-between i.fal.fa-plus,
.home-main .row .events-main .event-item .btn-group .btn:not(.active) .d-flex.justify-content-between div:first-child {
    color: var(--theme)
}
.content-mobile .btn-group.w-100.mr-2.grid-gap .btn.active .d-flex.justify-content-between div:first-child,
.content-mobile .btn-group.w-100.mr-2.grid-gap .btn.active .d-flex.justify-content-between i.fal.fa-plus,
.home-main .row .events-main .event-item .btn-group .btn.active .d-flex.justify-content-between div:first-child {
    color: var(--theme-text)
}
img.versus-symbol-mobile {
    position: relative;
    bottom: 1px;
}
.alert-sticky {
    position: sticky;
    z-index: 1;
    top: 0;
}
</style>